import useAccount from '@/composables/useAccounts';
import useAclRoles from '@/composables/useAclRoles/useAclRoles';
import useFeatures from '@/composables/useFeatures';
import usePlatform from '@/composables/usePlatform';
import useSubscription from '@/composables/useSubscription';
import {useConfigStore} from '@/store/config';
import {convertTierIdToName} from '@/types/subscriptions/tiers';
import log from '@/utils/logging';

interface UseAppcues {
  updateUserIdentity: (locale: string) => Promise<Record<string, unknown> | false>;
  initAppcues: (locale: string) => Promise<boolean>;
  isInitialized: () => boolean;
}

export default function useAppcues(): UseAppcues {
  const appCuesScriptId = 'appcues-script-loader';

  const isInitialized = (): boolean => {
    const {appcuesIsReady} = useConfigStore();
    return appcuesIsReady;
  };

  const createScript = (): HTMLScriptElement => {
    const {appCuesAccountId} = usePlatform();

    const script = document.createElement('script');
    script.src = `//fast.appcues.com/${appCuesAccountId}.js`;
    script.id = appCuesScriptId;

    return script;
  };

  const elementExists = (): boolean => {
    return !!window.Appcues && !!Object.keys(window.Appcues).length;
  };

  const getSubscriptionsValues = () => {
    const {activeSubscriptions, trialActiveSubscriptions, endingSoonSubscriptions} = useSubscription();
    return [...activeSubscriptions.value, ...trialActiveSubscriptions.value, ...endingSoonSubscriptions.value].reduce(
      (accumulator, {type, tier}) => {
        const tierName = convertTierIdToName(tier);
        accumulator[`has_${type}_${tierName}_subscription`] = true;
        return accumulator;
      },
      {
        has_my_contracts_light_subscription: false,
        has_my_contracts_start_subscription: false,
        has_my_contracts_plus_subscription: false,
        has_my_contracts_premium_subscription: false,
        has_my_contracts_max_subscription: false,
        has_my_analytics_start_subscription: false,
        has_my_analytics_plus_subscription: false,
        has_my_analytics_premium_subscription: false,
        has_my_orders_start_subscription: false,
        has_bundle_start_subscription: false,
        has_bundle_plus_subscription: false,
        has_bundle_premium_subscription: false,
        has_bundle_max_subscription: false,
      },
    );
  };

  const updateUserIdentity = async (locale: string): Promise<Record<string, unknown> | false> => {
    const {isFeatureActive} = useFeatures();

    if (!isFeatureActive('services.appcues') || !elementExists()) {
      return false;
    }

    const {account, currentShop, currentUser} = useAccount();

    const sendsTrackAndTrace = !!currentShop.value?.tracktrace?.send_track_trace_emails;
    const usesPortal = !!currentShop.value?.branding?.use_consumer_portal;
    const usesTrackAndTracePage = !!currentShop.value?.branding?.enable_track_trace;
    const usesTheReturnButton = !!currentShop.value?.branding?.allow_creating_related_returns;
    const usesTheReturnPage = !!currentShop.value?.branding?.enable_returns;

    const {getConvertedCustomerRoles} = useAclRoles();

    const convertedRoles = await getConvertedCustomerRoles();

    await window.Appcues.identify(currentUser.value.id, {
      ...convertedRoles,
      ...getSubscriptionsValues(),
      sends_track_and_trace: sendsTrackAndTrace,
      uses_portal: usesPortal,
      uses_track_and_trace_page: usesTrackAndTracePage,
      uses_the_return_button: usesTheReturnButton,
      uses_the_return_page: usesTheReturnPage,
      user_id: currentUser.value.id,
      account_created_date: account.value.created,
      user_created_date: currentUser.value.created,
      first_name: account.value.first_name,
      last_name: account.value.last_name,
      account_id: account.value.id,
      shop_id: currentShop.value.id,
      language: locale.toUpperCase(),
    });

    const user = await window.Appcues.user();

    return user;
  };

  const initAppcues = (locale: string): Promise<boolean> => {
    const {isFeatureActive} = useFeatures();
    const {appCuesAccountId} = usePlatform();

    if (!appCuesAccountId) {
      throw new Error('"appCuesAccountId" is not defined');
    }

    return new Promise((resolve) => {
      if (!isFeatureActive('services.appcues') || elementExists()) {
        resolve(false);
      }

      const script = createScript();
      document.body.appendChild(script);

      /**
       * This AppCues event listener will run async, which is fine.
       * We don't want the ui to hold loading for these statistics to be ready.
       */
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      script.addEventListener('load', async () => {
        const {setAppcuesIsReady} = useConfigStore();

        await updateUserIdentity(locale);

        setAppcuesIsReady();

        resolve(true);
      });

      script.addEventListener('error', (ev) => {
        /**
         * This error will not be caught by the catcher below, due to the async nature.
         * We do not want AppCues to delay the rest of the page loading.
         */
        log(Error(`Could not load AppCues dynamic script: ${ev}`));
        resolve(false);
      });
    });
  };

  return {
    updateUserIdentity,
    initAppcues,
    isInitialized,
  };
}
