<template>
  <button
    class="duration-200 ease-in-out rounded-xl select-none whitespace-nowrap"
    :class="buttonClasses"
    :disabled="disabled"
    :type="type">
    <slot />
  </button>
</template>

<script setup lang="ts">
import {computed} from 'vue';
import {ButtonSizes, ButtonType, ButtonVariant} from './Button.types';
import {useButtonSizes, useVariantClasses} from './composables';

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    size?: ButtonSizes;
    type?: ButtonType;
    variant?: ButtonVariant;
  }>(),
  {
    variant: ButtonVariant.Basic,
    type: ButtonType.BUTTON,
    size: ButtonSizes.MEDIUM,
  },
);

const buttonClasses = computed(() => {
  const sizeClasses = useButtonSizes(props.size);
  const variantClasses = useVariantClasses(props.variant);
  const disabledClasses = 'cursor-not-allowed bg-gray-200 text-gray-800 border border-gray-200';

  return [sizeClasses, props.disabled ? disabledClasses : variantClasses.join(' ')];
});
</script>
