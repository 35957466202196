import type {ConfigStore} from '@/store/config/config';
import type {Shop} from '@myparcel-frontend/types';
import type {ComputedRef, ToRefs} from 'vue';
import {useSdk} from '@myparcel-frontend/sdk';
import {storeToRefs} from 'pinia';
import {useConfigStore} from '@/store/config';

interface UseAccount {
  getAccount: () => Promise<ToRefs<Record<string, unknown>>>;
  shops: ComputedRef<Shop[]>;
}

export default function useAccount(): UseAccount & ToRefs<ConfigStore> {
  const configStore = useConfigStore();
  const refs = storeToRefs(configStore);

  const getAccount = async (): Promise<ToRefs<Record<string, unknown>>> => {
    const {sdk} = useSdk();

    if (Object.keys(configStore.account).length) {
      return refs;
    }

    const account = await sdk.getAccount({path: {id: configStore.acl.account_id}});
    configStore.setAccount(account[0]);
    return refs;
  };

  return {
    getAccount,
    ...refs,
    shops: refs.shops,
  };
}
