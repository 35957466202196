/* eslint-disable @typescript-eslint/no-magic-numbers */
import {convertEnumValue} from '@myparcel/constants/helpers';
import {TierName} from '@/types/subscriptions/tiers';

export const MyAnalyticsProductId = {
  Start: 4700,
  Plus: 4701,
  Premium: 4702,
  Max: 4703,
} as const;

export const MyAnalyticsTierName = {
  Start: TierName.Start,
  Plus: TierName.Plus,
  Premium: TierName.Premium,
  Max: TierName.Max,
} as const;

export const MY_ANALYTICS_IDS_TO_NAMES = {
  [MyAnalyticsProductId.Start]: MyAnalyticsTierName.Start,
  [MyAnalyticsProductId.Plus]: MyAnalyticsTierName.Plus,
  [MyAnalyticsProductId.Premium]: MyAnalyticsTierName.Premium,
  [MyAnalyticsProductId.Max]: MyAnalyticsTierName.Max,
};

export const MY_ANALYTICS_NAMES_TO_IDS = {
  [MyAnalyticsTierName.Start]: MyAnalyticsProductId.Start,
  [MyAnalyticsTierName.Plus]: MyAnalyticsProductId.Plus,
  [MyAnalyticsTierName.Premium]: MyAnalyticsProductId.Premium,
  [MyAnalyticsTierName.Max]: MyAnalyticsProductId.Max,
};

export const toMyAnalyticsTierName = (id: typeof MyAnalyticsProductId): typeof MyAnalyticsTierName => {
  return convertEnumValue(MY_ANALYTICS_IDS_TO_NAMES, id);
};

export const toMyAnalyticsProductId = (name: typeof MyAnalyticsTierName): typeof MyAnalyticsProductId => {
  return convertEnumValue(MY_ANALYTICS_NAMES_TO_IDS, name);
};
