import type {HttpHandler} from 'msw';
import type {SetupWorker} from 'msw/browser';
import type {App} from 'vue';
import {setupWorker} from 'msw/browser';

type MockServiceWorkerPlugin = {
  isProduction: boolean;
  handlers: HttpHandler[];
};

let worker: SetupWorker;

export const mockServiceWorkerPlugin = {
  install: (app: App, options: MockServiceWorkerPlugin): void => {
    const {isProduction, handlers} = options;

    if (isProduction) {
      return;
    }

    worker = setupWorker(...handlers);

    worker.start();
  },
};
