/**
 * Will sort a list by the given order and list of items.
 * If the item is not in the list, it will not be added to the new list.
 *
 * @example can be found in the unit test.
 * @param {T[]} list
 * @param {keyof T} orderBy
 * @param {string[]} order
 * @returns {T[]}
 */
export function sortBySpecficOrder<T extends Record<keyof T, unknown>>(
  list: T[],
  orderBy: keyof T,
  order: string[],
): T[] {
  const newList = [] as T[];

  for (const orderItem of order) {
    const listItem = list.find((listItem) => listItem.hasOwnProperty(orderBy) && listItem[orderBy] === orderItem);

    if (listItem) {
      newList.push(listItem);
    }
  }

  return newList;
}
