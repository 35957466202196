<template>
  <Modal
    :name="NAME.HOTJAR_FEEDBACK"
    @close="closeModal">
    <Modal.Header @close="closeModal" />
    <div id="hotjar-feedback-widget" />
  </Modal>
</template>

<script setup lang="ts">
import {onMounted} from 'vue';
import Modal, {NAME} from '@/components/ui/Modal';
import useHotjar from '@/composables/useHotjar';
import {useModal} from '@/composables/useModal';

const {closeModal} = useModal(NAME.HOTJAR_FEEDBACK);

const {triggerEvent} = useHotjar();

onMounted(() => {
  triggerEvent('start-feedback');
});
</script>

<style lang="scss">
#hotjar-feedback-widget {
  div {
    width: 100%;
  }
}
</style>
