<template>
  <RootErrorBoundary>
    <Suspense>
      <PlatformProvider>
        <I18NProvider>
          <AuthProvider>
            <FeatureToggleProvider>
              <AccountProvider>
                <RouterProvider>
                  <WebSocketProvider>
                    <Modal.Container />

                    <Notification.Container />
                    <Loader />

                    <RouterView v-slot="{Component, route}">
                      <Suspense @resolve="pageIsResolved = true">
                        <AppErrorBoundary>
                          <div v-if="route.meta.auth === false">
                            <component :is="Component" />
                          </div>

                          <div
                            v-else
                            id="main-container"
                            :class="{impersonation: isImpersonated}">
                            <ImpersonationBanner v-if="isImpersonated" />

                            <HeaderWrapper />

                            <div id="main-panels">
                              <SideMenuContainer />

                              <div
                                ref="scrollContainerRef"
                                data-test="page-ready"
                                class="flex flex-1 flex-col overflow-y-scroll">
                                <AppMessages />
                                <Transition mode="out-in">
                                  <KeepAlive :include="route.fullPath">
                                    <component
                                      :is="Component"
                                      class="grow" />
                                  </KeepAlive>
                                </Transition>
                              </div>
                            </div>
                          </div>
                        </AppErrorBoundary>
                      </Suspense>
                    </RouterView>

                    <PageTitle v-if="pageIsResolved" />
                  </WebSocketProvider>
                </RouterProvider>

                <ExternalServiceProvider />
              </AccountProvider>
            </FeatureToggleProvider>
          </AuthProvider>
        </I18NProvider>
      </PlatformProvider>
    </Suspense>
  </RootErrorBoundary>
</template>

<script setup lang="ts">
import {provide, ref} from 'vue';
import {RouterView} from 'vue-router';
import WebSocketProvider from './providers/WebSocketProvider.vue';
import AppErrorBoundary from '@/components/ui/AppErrorBoundary/AppErrorBoundary.vue';
import AppMessages from '@/components/ui/AppMessages/AppMessages.vue';
import ImpersonationBanner from '@/components/ui/ImpersonationBanner/ImpersonationBanner.vue';
import Loader from '@/components/ui/Loader';
import Modal from '@/components/ui/Modal';
import Notification from '@/components/ui/Notification';
import PageTitle from '@/components/ui/PageTitle/PageTitle.vue';
import RootErrorBoundary from '@/components/ui/RootErrorBoundary/RootErrorBoundary.vue';
import HeaderWrapper from '@/components/ui/layout/header/HeaderWrapper.vue';
import SideMenuContainer from '@/components/ui/layout/sideMenu/SideMenuContainer.vue';
import AccountProvider from '@/providers/AccountProvider.vue';
import AuthProvider from '@/providers/AuthProvider.vue';
import ExternalServiceProvider from '@/providers/ExternalServiceProvider.vue';
import FeatureToggleProvider from '@/providers/FeatureToggleProvider/FeatureToggleProvider.vue';
import I18NProvider from '@/providers/I18NProvider.vue';
import PlatformProvider from '@/providers/PlatformProvider.vue';
import RouterProvider from '@/providers/RouterProvider';
import {scrollContainerKey} from '@/utils/symbols';

const pageIsResolved = ref(false);
const isImpersonated = ref(!!window.localStorage.getItem('auth0_impersonated_account_id'));
const scrollContainerRef = ref();

/*
 * Provide the scroll container ref.
 * This is where all content is rendered.
 * This is could be used to alter the scroll position.
 */
provide(scrollContainerKey, scrollContainerRef);
</script>

<style lang="scss" scoped>
#main-wrapper {
  @apply flex-grow flex flex-col;
}

#main-container {
  @apply flex flex-col;
}

#main-panels {
  @apply flex h-[calc(100vh-70px)];
}

.impersonation {
  & #main-panels {
    @apply flex h-[calc(100vh-94px)];
  }
}
</style>
