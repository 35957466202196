<!-- eslint-disable vue/no-bare-strings-in-template -->
<template>
  <DebugError v-if="showError">
    <template #title>Configuration error</template>
    <p>
      The application is not running in the correct configuration. Please ensure the application is accessed through an
      accepted host.
    </p>
    <p>
      Current host: <code>{{ hostName }}</code>
    </p>
  </DebugError>
  <RootError v-else />
</template>

<script setup lang="ts">
import {computed} from 'vue';
import DebugError from '@/components/ui/DebugError/DebugError.vue';
import RootError from '@/components/ui/RootError/RootError.vue';
import env from '@/utils/env';
import {featureEnabledInLocalStorage} from '@/utils/features';

withDefaults(
  defineProps<{
    hostName?: string;
  }>(),
  {
    hostName: '',
  },
);

const showError = computed(() => {
  return env.devMode || featureEnabledInLocalStorage('features.errors');
});
</script>
