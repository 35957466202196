import {AnalyticsDashboardCategory, AnalyticsDashboardSlug} from '@/types/analytics-dashboard.types';

// eslint-disable-next-line complexity
export const getCategory = (slug: AnalyticsDashboardSlug): AnalyticsDashboardCategory | null => {
  switch (slug) {
    case AnalyticsDashboardSlug.Shipments:
    case AnalyticsDashboardSlug.ShipmentsWeekly:
    case AnalyticsDashboardSlug.ShipmentsT2:
    case AnalyticsDashboardSlug.ShipmentsT3:
    case AnalyticsDashboardSlug.ShipmentsT4:
      return AnalyticsDashboardCategory.Shipments;
    case AnalyticsDashboardSlug.Returns:
    case AnalyticsDashboardSlug.ReturnsWeekly:
    case AnalyticsDashboardSlug.ReturnsT2:
    case AnalyticsDashboardSlug.ReturnsT3:
    case AnalyticsDashboardSlug.ReturnsT4:
      return AnalyticsDashboardCategory.Returns;
    case AnalyticsDashboardSlug.Webshops:
    case AnalyticsDashboardSlug.WebshopsWeekly:
    case AnalyticsDashboardSlug.WebshopsT2:
    case AnalyticsDashboardSlug.WebshopsT3:
    case AnalyticsDashboardSlug.WebshopsT4:
      return AnalyticsDashboardCategory.Webshops;
    case AnalyticsDashboardSlug.TransitTime:
    case AnalyticsDashboardSlug.TransitTimeT2:
    case AnalyticsDashboardSlug.TransitTimeT3:
    case AnalyticsDashboardSlug.TransitTimeT4:
      return AnalyticsDashboardCategory.TransitTime;
    case AnalyticsDashboardSlug.Surcharges:
    case AnalyticsDashboardSlug.SurchargesT2:
    case AnalyticsDashboardSlug.SurchargesT3:
    case AnalyticsDashboardSlug.SurchargesT4:
      return AnalyticsDashboardCategory.Surcharges;
    case AnalyticsDashboardSlug.Cases:
    case AnalyticsDashboardSlug.CasesT2:
    case AnalyticsDashboardSlug.CasesT3:
    case AnalyticsDashboardSlug.CasesT4:
      return AnalyticsDashboardCategory.Cases;
    case AnalyticsDashboardSlug.FinanceT2:
    case AnalyticsDashboardSlug.FinanceT3:
    case AnalyticsDashboardSlug.FinanceT4:
      return AnalyticsDashboardCategory.Finance;
    case AnalyticsDashboardSlug.ShortcomingT2:
    case AnalyticsDashboardSlug.ShortcomingT3:
    case AnalyticsDashboardSlug.ShortcomingT4:
      return AnalyticsDashboardCategory.Shortcoming;
    default:
      return null;
  }
};
