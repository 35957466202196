import type {Features} from '@/types/features.types';

export const myparcelNlFeatures: Features = {
  accountAnalytics: true,
  accountSubscriptions: {
    active: true,
    carriers: {
      dhlEuroplus: true,
      dhlForYou: true,
      dhlParcelConnect: true,
    },
  },
  addressBook: {
    createBulkShipment: true,
    createReturnShipment: true,
    newForm: false,
    shareAddressBook: true,
  },
  events: {
    sknj: {
      endOfYear: false,
    },
  },
  errors: false,
  myDashboards: false,
  contactForm: {
    productsAndPrices: true,
  },
  contactServices: {
    faq: true,
    chat: true,
    whatsapp: true,
    phone: true,
  },
  page: {
    addresses: {
      create: true,
      edit: true,
      import: false,
      overview: true,
    },
    analytics: {
      cases: true,
      finance: true,
      index: true,
      returns: true,
      shipments: true,
      shortcoming: true,
      surcharges: true,
      transitTime: true,
      webshops: true,
    },
    auth: {
      connect: true,
    },
    cases: {
      active: true,
      detail: true,
      index: true,
    },
    collectTrip: false,
    contact: true,
    creditDebit: true,
    dashboard: false,
    faq: false,
    invoices: {
      overview: true,
      upcoming: true,
    },
    locationFinder: true,
    notFound: true,
    orders: {
      import: false,
      overview: false,
    },
    prices: {
      scale: {
        dhl: true,
        dpd: true,
        index: true,
        postnl: true,
      },
      shippingRates: true,
    },
    products: {
      overview: false,
      new: false,
    },
    redirect: true,
    salesChannels: false,
    settings: {
      account: {
        contact: false,
        index: false,
        myContracts: true,
        ordersShipping: true,
        shops: false,
        usersSecurity: {
          index: false,
          create: false,
          edit: false,
        },
        myContactInfo: false,
        myLoginInfo: false,
      },
      shop: {
        company: {
          index: false,
          billing: false,
          return: false,
          delivery: false,
          bank: false,
        },
        general: false,
        integrations: true,
        orders: true,
        portal: false,
        returns: {
          index: false,
          rules: true,
          vfb2: false,
          portal: false,
        },
        shipment: {
          carriers: true,
          dimensionsPresets: false,
          index: true,
          labels: false,
          shippingRules: {
            create: true,
            edit: true,
            overview: true,
            vfb2: false,
          },
        },
        trackTrace: false,
      },
    },
    shipments: false,
    subscriptions: {
      bundles: true,
      myAnalytics: true,
      myContracts: true,
      myOrders: true,
      overview: true,
    },
    tellAFriend: {
      edit: true,
      index: true,
      invite: true,
    },
    users: false,
    webshop: {
      orders: {
        detail: true,
        overview: true,
      },
    },
  },
  services: {
    appcues: true,
  },
  settings: {
    accountSettingsLegacy: true,
    shop: {
      integrations: {
        exact_de: {
          active: true,
          autoImport: false,
        },
        amazon: {
          autoImport: false,
        },
        bol_dot_com: {
          autoImport: true,
        },
        etsy: {
          autoImport: true,
        },
        exact: {
          active: true,
          autoImport: false,
        },
        wix: {
          autoImport: true,
        },
      },
      shipment: {
        active: true,
        carriers: true,
        labels: true,
      },
    },
  },
  microservices: {
    event: false,
  },
  subscriptions: {
    bundles: true,
    myAnalytics: {
      showTransitTimeUsp: true,
      tierFour: true,
    },
    myContracts: true,
    myContractsSettings: true,
    myOrders: true,
    overview: true,
  },
  trackAndTraceModal: false,
} as const;
