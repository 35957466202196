import type {SubscriptionPatch} from '@/providers/sdk-endpoints/Subscriptions';
import type {UseSubscriptionStore} from '@/store/subscriptionStore';
import type {
  Subscription,
  SubscriptionCapability,
  TierAndSubscription,
  TierSubscriptionCapability,
} from '@/types/subscriptions';
import type {ComputedRef, Ref} from 'vue';
import {useSdk, Status} from '@myparcel-frontend/sdk';
import {get} from '@vueuse/core';
import {storeToRefs} from 'pinia';
import {readonly, ref} from 'vue';
import useAccount from '@/composables/useAccount/useAccount';
import useAuth from '@/composables/useAuth';
import useSubscriptionCapabilities from '@/composables/useSubscriptionCapabilities';
import {useSubscriptionStore} from '@/store/subscriptionStore';
import {SubscriptionStatus, SubscriptionType} from '@/types/subscriptions';
import {dayjs} from '@myparcel-frontend/utils';
import {
  getSubscriptionProductGroupIds,
  isBundlesProductId,
  isSubscriptionActive,
  isSubscriptionEndingSoon,
  isSubscriptionPending,
  isSubscriptionStartingSoon,
  isSubscriptionTrialActive,
  isSubscriptionTypeBundles,
} from '@/utils/subscriptions';

const status = ref(Status.IDLE);

interface UseSubscription
  extends Omit<
    UseSubscriptionStore,
    'subscriptions' | 'setSubscriptions' | 'addSubscriptions' | 'addSubscription' | 'updateSubscription'
  > {
  activeMyAnalyticsSubscription: ComputedRef<Subscription>;
  activeMyContractsSubscription: ComputedRef<Subscription>;
  activeMyOrdersSubscription: ComputedRef<Subscription>;
  activeBundlesSubscription: ComputedRef<Subscription>;
  activeSubscriptions: ComputedRef<Subscription[]>;
  capabilityForSubscription: (subscription: Subscription) => SubscriptionCapability | undefined;
  createSubscription: (subscription: Subscription) => Promise<Subscription>;
  deleteSubscription: (subscription: Subscription) => Promise<void>;
  endedSubscriptions: ComputedRef<Subscription[]>;
  endingSoonBundlesSubscription: ComputedRef<Subscription>;
  endingSoonSubscriptions: ComputedRef<Subscription[]>;
  findPendingSubscriptionForType: (capability: SubscriptionCapability) => Subscription | undefined;
  getSubscriptionAndTiersForMyAnalytics: () => Promise<TierAndSubscription>;
  getSubscriptionAndTiersForMyContracts: () => Promise<TierAndSubscription>;
  getSubscriptionAndTiersForMyOrders: () => Promise<TierAndSubscription>;
  getSubscriptions: () => Promise<Ref<Subscription[]>>;
  isEligibleForTrial: (capability: SubscriptionCapability) => boolean;
  nonEndingSubscriptions: ComputedRef<Subscription[]>;
  patchSubscription: (subscriptionPatch: SubscriptionPatch) => Promise<void>;
  pendingSubscriptions: ComputedRef<Subscription[]>;
  resumeSubscription: (subscription: Subscription) => Promise<void>;
  startingSoonSubscriptions: ComputedRef<Subscription[]>;
  status: Readonly<Ref<typeof Status>>;
  subscribe: (capability: SubscriptionCapability, shopId: number) => Promise<Subscription | undefined>;
  trialActiveSubscriptions: ComputedRef<Subscription[]>;
  trialEndedSubscriptions: ComputedRef<Subscription[]>;
  trialSubscriptions: ComputedRef<Subscription[]>;
  unsubscribe: (subscription: Subscription) => Promise<void>;
  updateBillingShopId: (shopId: number) => Promise<void>;
}

export default function useSubscription(): UseSubscription {
  const subscriptionStore = useSubscriptionStore();
  const {
    activeSubscriptions,
    trialSubscriptions,
    subscriptions,
    pendingSubscriptions,
    startingSoonSubscriptions,
    endingSoonBundlesSubscription,
    endingSoonSubscriptions,
    endedSubscriptions,
    nonEndingSubscriptions,
    activeMyContractsSubscription,
    activeMyAnalyticsSubscription,
    activeMyOrdersSubscription,
    activeBundlesSubscription,
    trialActiveSubscriptions,
    trialEndedSubscriptions,
  } = storeToRefs(subscriptionStore);

  const fetchSubscriptions = async () => {
    const {sdk} = useSdk();
    let result;

    try {
      status.value = Status.PENDING;
      result = await sdk.getSubscriptions();
    } catch (error) {
      status.value = Status.REJECTED;
      throw error;
    }

    status.value = Status.RESOLVED;
    return result;
  };

  const getSubscriptions: UseSubscription['getSubscriptions'] = async () => {
    if (subscriptions.value.length) {
      return subscriptions;
    }

    const newSubscriptions = await fetchSubscriptions();
    subscriptionStore.setSubscriptions(newSubscriptions);

    return subscriptions;
  };

  const addAccountIdToSubscription = async (subscription: Subscription): Promise<Subscription> => {
    const {getAccountId} = useAccount();
    const accountId = await getAccountId();

    return {
      ...subscription,
      account_id: get(accountId),
    };
  };

  const createSubscription: UseSubscription['createSubscription'] = async (subscription) => {
    const {sdk} = useSdk();
    const {getAcl} = useAuth();

    status.value = Status.PENDING;
    let newSubscriptions;
    let response: {id: number}[];

    try {
      const subscriptionWithAccountId = await addAccountIdToSubscription(subscription);
      response = await sdk.postSubscriptions({
        body: [subscriptionWithAccountId],
      });

      newSubscriptions = await fetchSubscriptions();

      await getAcl(true);

      status.value = Status.RESOLVED;
    } catch (error) {
      status.value = Status.REJECTED;
      throw error;
    }

    subscriptionStore.setSubscriptions(newSubscriptions);

    return newSubscriptions.find((subscription) => subscription.id === response[0].id) as Subscription;
  };

  const isEligibleForTrial: UseSubscription['isEligibleForTrial'] = (capability) => {
    if (!trialSubscriptions.value.length) {
      return true;
    }

    const triedProductIds = trialSubscriptions.value.reduce((acc, subscription) => {
      const subscriptionProductGroupIds = getSubscriptionProductGroupIds(subscription.product_id);
      return [...acc, ...subscriptionProductGroupIds];
    }, [] as number[]);

    return !triedProductIds.includes(capability.product_id);
  };

  const patchSubscription: UseSubscription['patchSubscription'] = async (
    subscriptionPatch: SubscriptionPatch,
    refetch = true,
  ) => {
    const {sdk} = useSdk();
    const {getAcl} = useAuth();

    let newSubscriptions;
    status.value = Status.PENDING;

    try {
      await sdk.patchSubscriptions({body: [subscriptionPatch]});

      if (refetch) {
        newSubscriptions = await fetchSubscriptions();
      }

      await getAcl(true);

      status.value = Status.RESOLVED;
    } catch (error) {
      status.value = Status.REJECTED;
      throw error;
    }

    if (refetch) {
      subscriptionStore.setSubscriptions(newSubscriptions);
    }
  };

  /**
   * For the subscription flow please look at `docs/subscriptions/updating-subscriptions.md`
   */
  const subscribe: UseSubscription['subscribe'] = async (capability, shopId: number) => {
    await removeUpcomingSubscriptions(capability);

    if (isBundlesProductId(capability.product_id)) {
      await removeStartingSoonSubscriptions(capability.tier);
    }

    return createSubscription({
      product_id: capability.product_id,
      billing_shop_id: shopId,
      start: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  const deleteSubscription: UseSubscription['deleteSubscription'] = async (subscription: Subscription) => {
    const {sdk} = useSdk();
    const {getAcl} = useAuth();
    const {id} = subscription;

    status.value = Status.PENDING;

    try {
      await sdk.deleteSubscription({
        path: {id},
      });

      await getAcl(true);

      subscriptionStore.deleteSubscription(subscription);
    } catch (error) {
      status.value = Status.REJECTED;
      throw error;
    }
  };

  const resumeSubscription = async (subscription: Subscription) => {
    await removeUpcomingSubscriptions(subscription);
    return patchSubscription({id: subscription.id, end: null});
  };

  const capabilityForSubscription: UseSubscription['capabilityForSubscription'] = (subscription) => {
    const {subscriptionCapabilities} = useSubscriptionCapabilities();

    if (!Object.keys(subscriptionCapabilities.value).length) {
      throw new Error('No subscription capabilities loaded');
    }

    // transform the object of arrays into a single array
    const capabilities = Object.values(subscriptionCapabilities.value).flat();

    return capabilities.find((capability) => capability.product_id === subscription.product_id);
  };

  const removeUpcomingSubscriptions = async (subscription: Subscription | SubscriptionCapability) => {
    const pendingSubscription = findPendingSubscriptionForType(subscription);
    const startingSoonSubscription = findStartingSoonSubscriptionForType(subscription);

    const deleteActions: Promise<void>[] = [];

    if (pendingSubscription) {
      deleteActions.push(deleteSubscription(pendingSubscription));
    }

    if (startingSoonSubscription) {
      deleteActions.push(deleteSubscription(startingSoonSubscription));
    }

    await Promise.all(deleteActions);
  };

  const removeStartingSoonSubscriptions = async (tier: number): Promise<void> => {
    const startingSoonSubscriptions = findStartingSoonSubscriptionsForTier(tier);

    const deleteActions: Promise<void>[] = [];

    if (startingSoonSubscriptions) {
      startingSoonSubscriptions.forEach((startingSoonSubscription) => {
        deleteActions.push(deleteSubscription(startingSoonSubscription));
      });
    }

    await Promise.all(deleteActions);
  };

  /**
   * Find a pending subscription for a given capability.
   * This function will check id there currently is a pending subscription for the given capability's product type.
   *
   * @param {SubscriptionCapability} capability The capability to find a pending subscription for.
   * @returns {Subscription | undefined} The pending subscription or undefined if there is no pending subscription for the given capability's product type.
   */
  const findPendingSubscriptionForType: UseSubscription['findPendingSubscriptionForType'] = (capability) => {
    const productGroupIds = getSubscriptionProductGroupIds(capability.product_id);
    return pendingSubscriptions.value.find((subscription) => productGroupIds.includes(subscription.product_id));
  };

  /**
   * Find a starting soon subscription for a given capability.
   * This function will check id there currently is a starting soon subscription for the given capability's product type.
   *
   * @param {SubscriptionCapability} capability The capability to find a starting soon subscription for.
   * @returns {Subscription | undefined} The pending subscription or undefined if there is no pending subscription for the given capability's product type.
   */
  const findStartingSoonSubscriptionForType = (
    capability: Subscription | SubscriptionCapability,
  ): Subscription | undefined => {
    const productGroupIds = getSubscriptionProductGroupIds(capability.product_id);
    return startingSoonSubscriptions.value.find((subscription) => productGroupIds.includes(subscription.product_id));
  };

  /**
   * Find all starting soon subscriptions for a given tier.
   * This function will check id there currently is a starting soon subscription for the given tier or below this tier.
   *
   * @param {number} tier
   */
  const findStartingSoonSubscriptionsForTier = (tier: number): Subscription[] | undefined => {
    return startingSoonSubscriptions.value.filter(async (subscription: Subscription) => {
      const {getSubscriptionCapabilityByProductId} = useSubscriptionCapabilities();

      const subscriptionCapability = await getSubscriptionCapabilityByProductId(subscription.product_id);

      return subscriptionCapability.tier <= tier;
    });
  };

  /*
   * For the subscription flow please look at `docs/subscriptions/updating-subscriptions.md`
   * status Pending and StartingSoon => deleteSubscription
   * status Active => patchSubscription
   */
  const unsubscribe = async (subscription: Subscription) => {
    const {status} = subscription;

    if (status === SubscriptionStatus.Pending || status === SubscriptionStatus.StartingSoon) {
      return deleteSubscription(subscription);
    }

    if (status === SubscriptionStatus.TrialActive) {
      return patchSubscription({
        id: subscription.id,
        end: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      });
    }

    if (status === SubscriptionStatus.Active) {
      return patchSubscription({
        id: subscription.id,
        end: subscription.billing_period_end,
      });
    }

    // all other statuses: TrialEnded, Ended, EndingSoon: do nothing
  };

  const updateBillingShopId = async (shopId: number) => {
    await Promise.all(
      nonEndingSubscriptions.value.map((subscription) =>
        patchSubscription({id: subscription.id, billing_shop_id: shopId}, false),
      ),
    );

    const newSubscriptions = await fetchSubscriptions();
    subscriptionStore.setSubscriptions(newSubscriptions);
  };

  const createTiersWithSubscription = (
    capabilities: SubscriptionCapability[],
    activeSubscriptions: Subscription[],
    activeBundle: Subscription,
    activeSubscription: Subscription,
  ) => {
    const tiersWithSubscription: TierSubscriptionCapability[] = [];

    const findTierForSubscription = (subscription: Subscription): SubscriptionCapability | undefined => {
      return capabilities.find((capability: SubscriptionCapability) => capability.tier === subscription.tier);
    };

    const highestSubscription = activeSubscription ?? activeBundle;
    const highestTier = highestSubscription ? findTierForSubscription(highestSubscription) : undefined;

    /**
     * As a businessrule we want to disable lower placed tiers when they are defined in a bundle.
     * Only tiers that are higher than the highest tier in the bundle should be enabled.
     *
     * @param {SubscriptionCapability} capability
     * @returns {boolean}
     */
    const isDisabled = (capability: SubscriptionCapability): boolean => {
      if (!activeBundle) {
        return false;
      }

      return capability.tier <= activeBundle.tier;
    };

    /**
     * When MyContracts, MyAnalytics or MyOrders has less available tiers than the tier of the bundle,
     * we want to display the highest possible tier as active and included in bundle.
     *
     * @param {SubscriptionCapability} capability
     * @returns {boolean}
     */
    const isHighestCapabilityWithinBundle = (capability: SubscriptionCapability): boolean => {
      const {getHighestCapabilityForType} = useSubscriptionCapabilities();
      const highestCapability = getHighestCapabilityForType(capability.type);

      if (capability.tier !== highestCapability.tier) {
        return false;
      }

      return highestCapability?.tier <= activeBundle.tier;
    };

    for (const capability of capabilities) {
      const subscription = activeSubscriptions.find(
        (subscription: Subscription) => subscription.tier === capability.tier,
      );

      /**
       * When the highestSubscription is set, we will check if the current capability is within the bundle.
       * @returns {boolean} Whether the subscription is active or not.
       */
      const isActive = (): boolean => {
        if (subscription && highestSubscription?.tier === subscription.tier) {
          return (
            isSubscriptionActive(subscription.status) ||
            isSubscriptionEndingSoon(subscription.status) ||
            isSubscriptionPending(subscription.status) ||
            isSubscriptionStartingSoon(subscription.status) ||
            isSubscriptionTrialActive(subscription.status)
          );
        }

        return false;
      };

      tiersWithSubscription.push({
        ...capability,
        status: {
          isActive: isActive(),
          isHighestCapabilityWithinBundle: activeBundle ? isHighestCapabilityWithinBundle(capability) : false,
          isBundle: subscription ? isSubscriptionTypeBundles(subscription.type) : false,
          isDisabled: isDisabled(capability),
          isEligibleForTrial: isEligibleForTrial(capability),
          isEndingSoon: subscription ? isSubscriptionEndingSoon(subscription.status) : false,
          isStartingSoon: subscription ? isSubscriptionStartingSoon(subscription.status) : false,
        },
        subscription,
      });
    }

    return {
      active: {
        bundle: activeBundle,
        subscription: activeSubscription,
        highestSubscription,
        highestTier,
      },
      capabilities,
      tiersWithSubscription,
      subscriptions: activeSubscriptions,
    };
  };

  const getActiveBundle = () => {
    const {bundlesSubscriptions} = useSubscriptionStore();
    const hasActiveBundleSubscription = bundlesSubscriptions.some((sub) => sub.status !== SubscriptionStatus.Ended);
    return hasActiveBundleSubscription ? bundlesSubscriptions[0] : undefined;
  };

  /**
   * @todo MY-37737: Seperate this function in multiple pure functions to be more readable.
   * @returns {Promise<SubscriptionAndTiers>}
   */
  const getSubscriptionAndTiersForMyAnalytics: UseSubscription['getSubscriptionAndTiersForMyAnalytics'] = async () => {
    const {myAnalyticsSubscriptions, bundlesSubscriptions} = useSubscriptionStore();
    const {getSubscriptionTypeCapabilities} = useSubscriptionCapabilities();

    const capabilities = await getSubscriptionTypeCapabilities(SubscriptionType.MyAnalytics);

    const activeSubscriptions = [...myAnalyticsSubscriptions, ...bundlesSubscriptions].sort(
      (subA, subB) => subA.tier - subB.tier,
    );

    const activeBundle = getActiveBundle();
    const activeSubscription =
      activeBundle || myAnalyticsSubscriptions.length ? myAnalyticsSubscriptions[0] : undefined;

    return createTiersWithSubscription(capabilities.value, activeSubscriptions, activeBundle, activeSubscription);
  };

  /**
   * @todo MY-37737: Seperate this function in multiple pure functions to be more readable.
   * @returns {Promise<SubscriptionAndTiers>}
   */
  const getSubscriptionAndTiersForMyOrders: UseSubscription['getSubscriptionAndTiersForMyOrders'] = async () => {
    const {myOrdersSubscriptions, bundlesSubscriptions} = useSubscriptionStore();
    const {getSubscriptionTypeCapabilities} = useSubscriptionCapabilities();

    const capabilities = await getSubscriptionTypeCapabilities(SubscriptionType.MyOrders);

    const activeSubscriptions = [...myOrdersSubscriptions, ...bundlesSubscriptions].sort(
      (subA, subB) => subA.tier - subB.tier,
    );

    const activeBundle = getActiveBundle();
    const activeSubscription = activeBundle || myOrdersSubscriptions.length ? myOrdersSubscriptions[0] : undefined;

    return createTiersWithSubscription(capabilities.value, activeSubscriptions, activeBundle, activeSubscription);
  };

  /**
   * @todo MY-37737: Seperate this function in multiple pure functions to be more readable.
   * @returns {Promise<SubscriptionAndTiers>}
   */
  const getSubscriptionAndTiersForMyContracts: UseSubscription['getSubscriptionAndTiersForMyContracts'] = async () => {
    const {myContractsSubscriptions, bundlesSubscriptions} = useSubscriptionStore();
    const {getSubscriptionTypeCapabilities} = useSubscriptionCapabilities();

    const capabilities = await getSubscriptionTypeCapabilities(SubscriptionType.MyContracts);

    const activeSubscriptions = [...myContractsSubscriptions, ...bundlesSubscriptions].sort(
      (subA, subB) => subA.tier - subB.tier,
    );

    const activeBundle = getActiveBundle();
    const activeSubscription =
      activeBundle || myContractsSubscriptions.length ? myContractsSubscriptions[0] : undefined;

    return createTiersWithSubscription(capabilities.value, activeSubscriptions, activeBundle, activeSubscription);
  };

  return {
    activeMyAnalyticsSubscription,
    activeMyContractsSubscription,
    activeMyOrdersSubscription,
    activeBundlesSubscription,
    activeSubscriptions,
    capabilityForSubscription,
    createSubscription,
    deleteSubscription,
    endedSubscriptions,
    endingSoonBundlesSubscription,
    endingSoonSubscriptions,
    findPendingSubscriptionForType,
    getSubscriptionAndTiersForMyAnalytics,
    getSubscriptionAndTiersForMyContracts,
    getSubscriptionAndTiersForMyOrders,
    getSubscriptions,
    isEligibleForTrial,
    nonEndingSubscriptions,
    patchSubscription,
    pendingSubscriptions,
    resumeSubscription,
    startingSoonSubscriptions,
    status: readonly(status),
    subscribe,
    trialActiveSubscriptions,
    trialEndedSubscriptions,
    trialSubscriptions,
    unsubscribe,
    updateBillingShopId,
  };
}
