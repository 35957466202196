import type {
  DelegationApiKeyCredentials,
  PostDelegationRequestsApiKeyResponse,
} from '@myparcel-frontend/mypa-api-services/delegation';
import type {
  CreateBolSalesChannelRequest,
  CreateSalesChannelRequest,
  CreateShopifySalesChannelRequest,
  CreateWooCommerceSalesChannelRequest,
  DisableOrderLoadingRequest,
  EnableOrderLoadingRequest,
  PostCreateSalesChannelResponse,
  PostDisableOrderLoadingResponse,
  PostEnableOrderLoadingResponse,
  PostRenameSalesChannelResponse,
  PostRequestDelegationResponse,
  RenameSalesChannelRequest,
  RequestDelegationRequest,
  SalesChannel,
} from '@myparcel-frontend/mypa-api-services/order-etl';
import type {ValueOf} from '@myparcel-frontend/types';
import type {Ref} from 'vue';
import {useDelegationService} from '@myparcel-frontend/mypa-api-services/delegation';
import {useOrderEtlService} from '@myparcel-frontend/mypa-api-services/order-etl';
import {storeToRefs} from 'pinia';
import useAccount from '@/composables/useAccounts';
import {useSalesChannelStore} from '@/store/salesChannelStore/salesChannelStore';
import {SalesChannelsStatus} from '@/types/salesChannels';
import {SalesChannels} from '@/types/salesChannels';
import log from '@/utils/logging';

type SalesChannelComposable = {
  createApiKeyCredentials: (
    salesChannelId: SalesChannel['id'],
    credentials: DelegationApiKeyCredentials,
  ) => Promise<PostDelegationRequestsApiKeyResponse>;
  createSalesChannel: (body: CreateSalesChannelRequest) => Promise<PostCreateSalesChannelResponse>;
  disableOrderLoading: (body: DisableOrderLoadingRequest) => Promise<PostDisableOrderLoadingResponse>;
  enableOrderLoading: (body: EnableOrderLoadingRequest) => Promise<PostEnableOrderLoadingResponse>;
  getSalesChannelById: (id: string) => SalesChannel | undefined;
  fetchSalesChannelById: (id: string) => Promise<SalesChannel | undefined>;
  getSalesChannels: (shopIds: string[]) => Promise<SalesChannel[] | []>;
  hasSalesChannelById: (id: SalesChannel['id']) => boolean;
  removeSalesChannel: (id: SalesChannel['id']) => Promise<boolean>;
  renameSalesChannel: (data: RenameSalesChannelRequest) => Promise<PostRenameSalesChannelResponse>;
  requestDelegation: (body: RequestDelegationRequest) => Promise<PostRequestDelegationResponse>;
  salesChannelList: Ref<SalesChannel[]>;
  updateSalesChannelStatus: (
    salesChannelId: SalesChannel['id'],
    newStatus: ValueOf<typeof SalesChannelsStatus>,
  ) => SalesChannel | undefined;
};

export default function useSalesChannels(): SalesChannelComposable {
  const salesChannelStore = useSalesChannelStore();
  const {salesChannelList} = storeToRefs(salesChannelStore);
  const {currentShop} = useAccount();

  const getSalesChannels: SalesChannelComposable['getSalesChannels'] = async () => {
    const client = useOrderEtlService();

    const response = await client.getSalesChannels({shopIds: [currentShop.value.id.toString()]});
    const fetchedChannels = response.results;

    // Merge fetchedChannels with existing salesChannelList
    const existingIds = new Set(salesChannelList.value.map((channel: SalesChannel) => channel.id));
    const newUniqueChannels = fetchedChannels.filter((channel) => !existingIds.has(channel.id));

    salesChannelList.value.push(...newUniqueChannels);

    return salesChannelList.value;
  };

  const createSalesChannelForBol = (data: CreateBolSalesChannelRequest) => {
    const client = useOrderEtlService();

    return client.createSalesChannel({
      ...data,
    });
  };

  const createSalesChannelForWoocommerce = (data: CreateWooCommerceSalesChannelRequest) => {
    const client = useOrderEtlService();
    return client.createSalesChannel(data);
  };

  const createSalesChannelForShopify = (data: CreateShopifySalesChannelRequest) => {
    const client = useOrderEtlService();
    return client.createSalesChannel(data);
  };

  const createSalesChannel: SalesChannelComposable['createSalesChannel'] = async (
    data: CreateSalesChannelRequest,
  ): Promise<SalesChannel> => {
    let newChannel: SalesChannel;

    switch (data.ecommercePlatform) {
      case SalesChannels.Bol: {
        newChannel = await createSalesChannelForBol(data);
        break;
      }

      case SalesChannels.Woocommerce: {
        newChannel = await createSalesChannelForWoocommerce(data);
        break;
      }

      case SalesChannels.Shopify: {
        newChannel = await createSalesChannelForShopify(data);
        break;
      }

      default:
        throw new Error('EcommercePlatform not implemented');
    }

    salesChannelStore.addSalesChannel(newChannel);
    return newChannel;
  };

  const renameSalesChannel = async (data: RenameSalesChannelRequest) => {
    const client = useOrderEtlService();
    const updatedChannel = await client.renameSalesChannel(data);

    salesChannelStore.updateSalesChannel(updatedChannel);
    return updatedChannel;
  };

  const removeSalesChannel = async (id: SalesChannel['id']) => {
    try {
      const client = useOrderEtlService();
      await client.removeSalesChannel({
        salesChannelId: id,
      });

      return salesChannelStore.removeSalesChannel(id);
    } catch (error) {
      log(error);

      return false;
    }
  };

  const enableOrderLoading: SalesChannelComposable['enableOrderLoading'] = async (body: EnableOrderLoadingRequest) => {
    const client = useOrderEtlService();
    const updatedChannel = await client.enableOrderLoading(body);
    salesChannelStore.updateSalesChannel(updatedChannel);

    return updatedChannel;
  };

  const disableOrderLoading: SalesChannelComposable['disableOrderLoading'] = async (
    body: DisableOrderLoadingRequest,
  ) => {
    const client = useOrderEtlService();
    const updatedChannel = await client.disableOrderLoading(body);
    salesChannelStore.updateSalesChannel(updatedChannel);

    return updatedChannel;
  };

  const getSalesChannelById: SalesChannelComposable['getSalesChannelById'] = (id: SalesChannel['id']) => {
    return salesChannelStore.getSalesChannelById(id);
  };

  const fetchSalesChannelById: SalesChannelComposable['fetchSalesChannelById'] = async (id: SalesChannel['id']) => {
    const client = useOrderEtlService();
    const salesChannel = await client.getSalesChannelById({
      salesChannelId: id,
    });

    salesChannelList.value.push(salesChannel);
    return salesChannel;
  };

  const hasSalesChannelById: SalesChannelComposable['hasSalesChannelById'] = (id: SalesChannel['id']) => {
    return !!salesChannelStore.getSalesChannelById(id);
  };

  const requestDelegation = async (body: RequestDelegationRequest) => {
    const client = useOrderEtlService();
    const updatedChannel = await client.requestDelegation(body);
    salesChannelStore.updateSalesChannel(updatedChannel);

    return updatedChannel;
  };

  const createApiKeyCredentials: SalesChannelComposable['createApiKeyCredentials'] = async (
    salesChannelId: SalesChannel['id'],
    credentials: DelegationApiKeyCredentials,
  ) => {
    const client = useDelegationService();

    const result = await client.createDelegationRequestsApiKey({
      apiKey: credentials,
      delegateeId: {
        delegatee: 'ORDER_ETL',
        id: salesChannelId,
      },
    });

    return result;
  };

  const updateSalesChannelStatus: SalesChannelComposable['updateSalesChannelStatus'] = (
    salesChannelId: SalesChannel['id'],
    newStatus: ValueOf<typeof SalesChannelsStatus>,
  ) => {
    const salesChannel = getSalesChannelById(salesChannelId);

    if (!salesChannel) {
      return;
    }

    switch (newStatus) {
      case SalesChannelsStatus.DelegationIssued:
        return salesChannelStore.updateSalesChannelToDelegationIssued(salesChannel);
      case SalesChannelsStatus.DelegationRequested:
        return salesChannelStore.updateSalesChannelToDelegationRequested(salesChannel);
      case SalesChannelsStatus.DelegationRequired:
        return salesChannelStore.updateSalesChannelToDelegationRequired(salesChannel);
    }
  };

  return {
    createApiKeyCredentials,
    createSalesChannel,
    disableOrderLoading,
    enableOrderLoading,
    getSalesChannelById,
    fetchSalesChannelById,
    getSalesChannels,
    hasSalesChannelById,
    removeSalesChannel,
    renameSalesChannel,
    requestDelegation,
    salesChannelList,
    updateSalesChannelStatus,
  };
}
