import type {ShippingRuleType} from '@/constants/shippingRuleType';
import type {Acl} from '@/providers/acl.types';
import type {ConfigStore} from '@/store/config';
import type {ValueOf} from '@myparcel-frontend/types';
import type {Ref, ComputedRef} from 'vue';
import {storeToRefs} from 'pinia';
import {computed} from 'vue';
import useAuth from '@/composables/useAuth';
import usePlatform from '@/composables/usePlatform';
import {SubscriptionFeature} from '@/constants/subscriptionFeatures';
import {useAclStore} from '@/store/aclStore/aclStore';

interface UseAcl {
  getAcl: () => Promise<Ref<ConfigStore['acl']>>;
  hasSubscriptionFeature: (feature: string) => ComputedRef<boolean>;
  acl: Ref<Acl>;
  subscriptionConfiguration: Ref<Acl['subscription_configuration']>;
  getMaximumShippingRules: (type: ValueOf<typeof ShippingRuleType>) => number;
}

export default function useAcl(): UseAcl {
  const aclStore = useAclStore();
  const {acl} = storeToRefs(aclStore);
  const auth = useAuth();

  const getAcl = async () => {
    if (Object.keys(acl.value).length) {
      return acl;
    }

    await auth.getAcl(true);
    return acl;
  };

  const hasSubscriptionFeature = (feature: string) => {
    return computed(() => acl.value.subscription_features.includes(feature));
  };

  const subscriptionConfiguration = computed(() => {
    return acl.value.subscription_configuration;
  });

  const getMaximumShippingRules = (type: ValueOf<typeof ShippingRuleType>) => {
    const {maxFreeShippingRules} = usePlatform();

    if (
      hasSubscriptionFeature(SubscriptionFeature.UnlimitedShippingRules).value ||
      subscriptionConfiguration.value?.max_shipping_rules_by_type?.length === 0
    ) {
      return Infinity;
    }

    return subscriptionConfiguration.value?.max_shipping_rules_by_type?.[type] ?? maxFreeShippingRules;
  };

  return {
    getAcl,
    hasSubscriptionFeature,
    acl,
    subscriptionConfiguration,
    getMaximumShippingRules,
  };
}
