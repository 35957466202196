import type {Ref} from 'vue';
import {readonly, ref} from 'vue';

interface useMobileMenu {
  mobileMenuOpen: Ref<boolean>;
  toggleSidebar(): void;
  closeSidebar(): void;
}

const mobileMenuOpen = ref(false);

export default (): useMobileMenu => {
  /**
   * Provider for the mobile menu open/closed state.
   */
  const toggleSidebar = (): void => {
    mobileMenuOpen.value = !mobileMenuOpen.value;
  };

  const closeSidebar = (): void => {
    mobileMenuOpen.value = false;
  };

  return {
    mobileMenuOpen: readonly(mobileMenuOpen),
    toggleSidebar,
    closeSidebar,
  };
};
