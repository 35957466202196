import type {AnalyticsDashboardCredentials, AnalyticsDashboard} from '@/types/analytics-dashboard.types';
import {defineStore} from 'pinia';
import {computed, ref} from 'vue';
import {getCategory} from '@/utils/analyticsDashboard/getCategory';
import {sortDashboards} from '@/utils/analyticsDashboard/sortDashboards';

export const useAnalyticsDashboardStore = defineStore('analyticsDashboard', () => {
  const credentials = ref<AnalyticsDashboardCredentials>();
  const dashboards = ref<AnalyticsDashboard[]>();

  const setCredentials = (newCredentials: AnalyticsDashboardCredentials) => {
    credentials.value = newCredentials;
  };

  const setDashboards = (newDashboards: AnalyticsDashboard[]) => {
    dashboards.value = newDashboards;
  };

  const activeAnalyticsDashboards = computed(() => {
    if (!dashboards.value?.length) {
      return [];
    }

    const dashboardsWithCategory = dashboards.value.map((dashboard) => {
      const category = getCategory(dashboard.dashboard)!;

      return {
        ...dashboard,
        category,
      };
    });

    return sortDashboards(dashboardsWithCategory);
  });

  return {
    credentials,
    dashboards,
    activeAnalyticsDashboards,
    setCredentials,
    setDashboards,
  };
});
