import type {AnalyticsDashboardWithCategory} from '@/types/analytics-dashboard.types';
import {AnalyticsDashboardSlug} from '@/types/analytics-dashboard.types';
import {sortBySpecficOrder} from '@/utils/sorters/order';

export const sortDashboards = (dashboards: AnalyticsDashboardWithCategory[]): AnalyticsDashboardWithCategory[] => {
  const sortingOrder = [
    AnalyticsDashboardSlug.Shipments,
    AnalyticsDashboardSlug.ShipmentsWeekly,
    AnalyticsDashboardSlug.ShipmentsT2,
    AnalyticsDashboardSlug.ShipmentsT3,
    AnalyticsDashboardSlug.ShipmentsT4,
    AnalyticsDashboardSlug.Returns,
    AnalyticsDashboardSlug.ReturnsWeekly,
    AnalyticsDashboardSlug.ReturnsT2,
    AnalyticsDashboardSlug.ReturnsT3,
    AnalyticsDashboardSlug.ReturnsT4,
    AnalyticsDashboardSlug.TransitTime,
    AnalyticsDashboardSlug.TransitTimeT2,
    AnalyticsDashboardSlug.TransitTimeT3,
    AnalyticsDashboardSlug.TransitTimeT4,
    AnalyticsDashboardSlug.Surcharges,
    AnalyticsDashboardSlug.SurchargesT2,
    AnalyticsDashboardSlug.SurchargesT3,
    AnalyticsDashboardSlug.SurchargesT4,
    AnalyticsDashboardSlug.Cases,
    AnalyticsDashboardSlug.CasesT2,
    AnalyticsDashboardSlug.CasesT3,
    AnalyticsDashboardSlug.CasesT4,
    AnalyticsDashboardSlug.Webshops,
    AnalyticsDashboardSlug.WebshopsWeekly,
    AnalyticsDashboardSlug.WebshopsT2,
    AnalyticsDashboardSlug.WebshopsT3,
    AnalyticsDashboardSlug.WebshopsT4,
    AnalyticsDashboardSlug.FinanceT2,
    AnalyticsDashboardSlug.FinanceT3,
    AnalyticsDashboardSlug.FinanceT4,
    AnalyticsDashboardSlug.ShortcomingT2,
    AnalyticsDashboardSlug.ShortcomingT3,
    AnalyticsDashboardSlug.ShortcomingT4,
  ];

  return sortBySpecficOrder<AnalyticsDashboardWithCategory>(dashboards, 'dashboard', sortingOrder);
};
