<!-- App Error Boundary: shown when non-caught errors occur, shows a notification toast. -->
<template>
  <slot />
</template>

<script setup lang="ts">
import {onErrorCaptured} from 'vue';
import {useI18n} from 'vue-i18n';
import useNotifications, {NotificationVariant} from '@/composables/useNotifications';
import log from '@/utils/logging';

const {t} = useI18n();
const {addNotification} = useNotifications();

onErrorCaptured((error: Error) => {
  const NOTIFICATION_TIMEOUT = 6000;
  addNotification({
    variant: NotificationVariant.Error,
    title: `${t('errorOccurred')}:`,
    body: error.toString(),
    timeout: NOTIFICATION_TIMEOUT,
  });

  if (error.message) {
    log(error, {boundary: 'App'});
  }

  // do not propagate upwards to the root error boundary:
  return false;
});
</script>
