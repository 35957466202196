import type {AccountMessage} from '@myparcel-frontend/types';
import {defineStore} from 'pinia';

const EXPIRATION_TIME = 1000 * 60 * 60;

export const useAccountMessagesStore = defineStore({
  id: 'accountMessages',
  state: () => ({
    accountMessages: [] as AccountMessage[],
    expirationDate: 0,
  }),
  getters: {
    isExpired: (state) => {
      return state.expirationDate < Date.now();
    },
  },
  actions: {
    setAccountMessages(accountMessages: AccountMessage[]) {
      this.accountMessages = accountMessages;
      this.expirationDate = Date.now() + EXPIRATION_TIME;
    },
    deleteAccountMessage(message: AccountMessage) {
      this.accountMessages = this.accountMessages.filter(
        (accountMessage: AccountMessage) => accountMessage.id !== message.id,
      );
    },
  },
});
