import type {Features} from '@/types/features.types';
import {myparcelNlFeatures} from './myparcelnl';
import {sendmyparcelBeFeatures} from './sendmyparcelbe';
import {ConfigKey} from '@/providers/platformConfig.types';

type Mapping = Record<ConfigKey, Features>;

const mapping: Mapping = {
  [ConfigKey.DEFAULT]: myparcelNlFeatures,
  [ConfigKey.MYPARCEL_NL]: myparcelNlFeatures,
  [ConfigKey.MYPARCEL_BE]: sendmyparcelBeFeatures,
};

const features = (key: keyof typeof mapping): Features => {
  return mapping.hasOwnProperty(key) ? mapping[key] : mapping[ConfigKey.DEFAULT];
};

export default features;
