<template>
  <div
    v-if="isLoading"
    tabindex="-1"
    data-test="loader"
    class="fixed flex h-full justify-center left-0 max-h-[100%] top-0 w-screen z-50">
    <div class="bg-black fixed flex h-screen justify-center left-0 opacity-50 top-0 w-screen" />
    <div
      class="-translate-x-1/2 -translate-y-1/2 fixed grow left-1/2 lg:-translate-x-[calc(50%-112px)] top-1/2 transform w-[240px]"
      aria-modal="true"
      role="dialog">
      <div class="bg-white flex justify-center modal-content px-4 py-8 rounded-lg shadow">
        <Spinner />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useLoader from './useLoader';
import Spinner from '@/components/ui/Spinner/Spinner.vue';

const {isLoading} = useLoader();
</script>
