import type {ConfigKey} from '@/providers/platformConfig.types';

interface Translate {
  t(key: string, plural?: number): string;
}

export type SetupPluginOptions = {
  supportedLanguages: string[];
  defaultLanguage: string;
  fallbackLanguage: string;
  platformPrefix: string;
  warnForMissingTranslations: boolean;
};

export interface Language {
  default: Record<string, string>;
  [ConfigKey.MYPARCEL_BE]?: Record<string, string>;
}

export enum LanguageKey {
  En = 'en',
  Fr = 'fr',
  Nl = 'nl',
}

export enum AvailableLanguages {
  'en',
  'fr',
  'nl',
}

export default Translate;
