import type {SystemMessage, ValueOf} from '@myparcel-frontend/types';
import type {Ref} from 'vue';
import {useSdk, Status} from '@myparcel-frontend/sdk';
import {SystemMessageType} from '@myparcel-frontend/types';
import {ref} from 'vue';
import {useSystemMessagesStore} from '@/store/systemMessages';
import {dayjs} from '@myparcel-frontend/utils';

interface SystemMessageFilter {
  platformId?: number;
  messageType?: ValueOf<typeof SystemMessageType>;
  filter?: string;
  global?: boolean;
}

interface UseSystemMessages {
  getSystemMessages: () => Promise<void>;
  dismissMessage: (message: SystemMessage) => void;
  filteredSystemMessages: (arg: SystemMessageFilter) => SystemMessage[];
  status: Ref<Status>;
}

const status = ref(Status.IDLE);

/**
 * Helper hook to manage system messages throughout the app. Uses the store and localstorage to
 * persist messages and remember dismissed messages.
 * Does not have enhanced error handling, as it is not a critical part of the app.
 * Methods:
 * - getSystemMessages: Fetch system messages from the API. If the timer has not yet expired, the
 *  messages will be returned from the store instead.
 * - dismissMessage: Dismiss a message by adding it to the store.
 */
export default function useSystemMessages(): UseSystemMessages {
  const systemMessagesStore = useSystemMessagesStore();
  const {sdk} = useSdk();

  const getSystemMessages = async (): Promise<void> => {
    status.value = Status.PENDING;

    if (systemMessagesStore.isExpired) {
      status.value = Status.PENDING;

      try {
        const systemMessages = await sdk.getSystemMessages();
        systemMessagesStore.setSystemMessages(systemMessages);
        status.value = Status.RESOLVED;
      } catch (error) {
        status.value = Status.REJECTED;
        throw error;
      }
    }
  };

  const dismissMessage = (message: SystemMessage) => {
    if (systemMessagesStore.isDismissed(message)) return;

    systemMessagesStore.addDismissedMessage(message);
  };

  const filteredSystemMessages = ({platformId, messageType, filter, global}: SystemMessageFilter): SystemMessage[] => {
    // eslint-disable-next-line complexity
    return systemMessagesStore.systemMessages.filter((systemMessage: SystemMessage) => {
      let match = false;

      if (messageType) {
        match = systemMessage.message_type === messageType;
      }

      if (filter) {
        match = match && systemMessage.additional_filter === filter;
      }

      // if global is set, add them regardless of previous filters
      if (global && systemMessage.message_type === SystemMessageType.GLOBAL_MESSAGE) {
        match = true;
      }

      // platform ID is always a leading filter (even surpassing global type)
      if (platformId) {
        match = match && systemMessage.platform_ids.includes(platformId);
      }

      const messageEndDate = systemMessage.end_date;
      const isExpired = messageEndDate && dayjs(messageEndDate) < dayjs();
      const isDismissed = systemMessagesStore.isDismissed(systemMessage);
      const isActive = !!systemMessage.active;

      match = match && isActive && !isDismissed && !isExpired;

      return match;
    });
  };

  return {
    getSystemMessages,
    dismissMessage,
    filteredSystemMessages,
    status,
  };
}
