import type {RouteRecordName, RouteRecordRaw} from 'vue-router';
import applicationRoutes from './routes';
import useFeatures from '@/composables/useFeatures';

const getOverrides = (routes: RouteRecordRaw[]) => {
  const {isFeatureActive} = useFeatures();

  return routes.reduce<RouteRecordName[]>((acc, route) => {
    if (route?.children?.length) {
      const childrenOverrides = getOverrides(route.children);
      acc.push(...childrenOverrides);
    }

    if (route?.meta?.feature && isFeatureActive(route.meta.feature)) {
      return acc;
    }

    if (route.name) {
      acc.push(route.name);
    }

    return acc;
  }, []);
};

const routes = (): {
  routes: RouteRecordRaw[];
  overrides: RouteRecordName[];
} => {
  const platformRoutes = applicationRoutes();

  return {
    routes: platformRoutes,
    overrides: getOverrides(platformRoutes),
  };
};

export default routes;
