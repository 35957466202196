import type {Ref} from 'vue';
import {readonly, ref} from 'vue';

interface UseToggle {
  toggle: Readonly<Ref<boolean>>;
  updateToggle: (newValue: boolean) => void;
  toggleToggle: () => void;
  toggleToTrue: () => void;
  toggleToFalse: () => void;
}

export default function useToggle(): UseToggle {
  const toggle = ref(false);

  const updateToggle: UseToggle['updateToggle'] = (newValue) => {
    toggle.value = newValue;
  };

  const toggleToggle: UseToggle['toggleToggle'] = () => {
    updateToggle(!toggle.value);
  };

  const toggleToTrue: UseToggle['toggleToTrue'] = () => {
    updateToggle(true);
  };

  const toggleToFalse: UseToggle['toggleToFalse'] = () => {
    updateToggle(false);
  };

  return {
    toggle: readonly(toggle),
    updateToggle,
    toggleToggle,
    toggleToTrue,
    toggleToFalse,
  };
}
