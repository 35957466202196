<template>
  <AppMessage
    v-if="!isClosed"
    :variant="NotificationVariant.Error"
    @close="isClosed = true">
    <p class="font-bold">{{ $t('integrations.bol.expired.title') }}</p>
    <Translation keypath="integrations.bol.expired.description">
      <template #shop>
        <strong>{{ currentShop.name }}</strong>
      </template>
    </Translation>
    <ol class="list-decimal me-4 ms-4">
      <li>
        <Translation keypath="integrations.bol.expired.step1">
          <template #link>
            <RouterLink
              class="text-secondary"
              :to="{
                name: 'shop-settings-integration',
                params: {
                  shopId: currentShop.id,
                },
              }">
              {{ $t('integrations.bol.expired.link') }}
            </RouterLink>
          </template>
        </Translation>
      </li>
      <li>{{ $t('integrations.bol.expired.step2') }}</li>
      <li>{{ $t('integrations.bol.expired.step3') }}</li>
    </ol>
  </AppMessage>
</template>

<script setup lang="ts">
import {ref} from 'vue';
import {Translation} from 'vue-i18n';
import {RouterLink} from 'vue-router';
import AppMessage from '@/components/ui/AppMessages/AppMessage.vue';
import useAccount from '@/composables/useAccounts';
import {NotificationVariant} from '@/composables/useNotifications';

const {currentShop} = useAccount();

const isClosed = ref(false);
</script>
