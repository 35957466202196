import Divider from './Divider.vue';
import DropDownMenu from './DropDownMenu.vue';
import LinkItem from './LinkItem.vue';
import StaticItem from './StaticItem.vue';

DropDownMenu.LinkItem = LinkItem;
DropDownMenu.StaticItem = StaticItem;
DropDownMenu.Divider = Divider;

export default DropDownMenu;
