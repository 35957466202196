import type {GtmPlugin, TrackEventOptions} from '@gtm-support/vue-gtm';
import type {Ref} from 'vue';
import {useGtm} from '@gtm-support/vue-gtm';
import {ref} from 'vue';
import usePlatform from '@/composables/usePlatform';
import env from '@/utils/env';

const gtm = ref<GtmPlugin>();

interface UseGtm {
  gtm: Ref<GtmPlugin | undefined>;
  loadGtm: () => void;
  trackEvent: (event: TrackEventOptions) => void;
}

export default function useGoogleTagManager(): UseGtm {
  const {googleTagManagerId} = usePlatform();

  const loadGtm = () => {
    gtm.value = useGtm() as GtmPlugin;
  };

  /**
   * Push any event to Google Tag Manager.
   */
  const trackEvent = (event: TrackEventOptions) => {
    if (env.devMode) {
      // eslint-disable-next-line no-console
      console.warn(`GTM event: ${event}. was not sent to GTM because of devMode`);
      return;
    }

    if (!googleTagManagerId) {
      return;
    }

    gtm.value?.trackEvent(event);
  };

  return {
    gtm,
    loadGtm,
    trackEvent,
  };
}
