import type {MenuItem} from '@/components/ui/layout/sideMenu/menuItem/menuItem.types';
import {
  Cog24,
  Dashboard,
  EuroSign24,
  Packaging,
  Shipment,
  Shirt24,
  ShoppingCart,
  Subscription,
  Support,
} from '@myparcel-frontend/ui';
import useAccount from '@/composables/useAccounts';
import useAuth from '@/composables/useAuth';
import useFeatures from '@/composables/useFeatures';
import {useConfigStore} from '@/store/config';

export default (): MenuItem[] => {
  const {accountSettings, currentShop} = useAccount();
  const {hasPermission} = useAuth();
  const {isFeatureActive} = useFeatures();
  const {config} = useConfigStore();

  const currentShopId = currentShop.value.id;

  return [
    {
      key: (t) => t('dashboard'),
      icon: {
        icon: Dashboard,
        viewbox: '0 0 24 24',
      },
      children: [
        {
          route: 'dashboard',
          key: (t) => t('overview'),
        },
        {
          route: 'insights',
          key: (t) => t('analytics.title'),
          show: () => isFeatureActive('accountAnalytics'),
        },
      ],
    },
    {
      key: (t) => t('menu.items.order', 2),
      show: () => Boolean(accountSettings.value.order_mode),
      icon: {
        icon: ShoppingCart,
        viewbox: '0 0 24 24',
      },
      children: [
        {
          key: (t) => t('overview'),
          route: 'orders-overview',
        },
        {
          key: (t) => t('manualImport'),
          route: 'orders-import-overview',
        },
      ],
    },
    {
      key: (t) => t('shipment', 2),
      icon: {
        icon: Shipment,
        viewbox: '0 0 24 24',
      },
      children: [
        {
          route: 'shipments',
          key: (t) => t('overview'),
        },
        {
          route: 'shipments-create',
          key: (t) => t('newShipment'),
        },
        {
          route: 'shipments-import',
          key: (t) => t('multipleShipments'),
        },
        {
          route: 'addresses',
          key: (t) => t('addressBook.title'),
        },
        {
          route: 'haalservice',
          key: (t) => t('collectTrip'),
        },
        {
          route: 'location-finder',
          key: (t) => t('locationFinder.title'),
        },
      ],
    },
    {
      key: (t) => t('product', 2),
      icon: {
        icon: Shirt24,
        viewbox: '0 0 24 24',
      },
      route: 'products',
      show: () => isFeatureActive('page.products.overview'),
    },
    {
      key: (t) => t('packagings.title', 2),
      icon: {
        icon: Packaging,
        viewbox: '0 0 32 32',
      },
      children: [
        {
          key: (t) => t('packagings.order', 2),
          route: 'packagings',
        },
        {
          key: (t) => t('packagings.orderNewPackaging', 2),
          redirectRoute: config.webshopUrl,
          redirect: true,
          target: '_blank',
          show: () => hasPermission('accountSelectOrder'),
        },
      ],
    },
    {
      key: (t) => t('financial'),
      icon: {
        icon: EuroSign24,
        viewbox: '0 0 24 24',
      },
      children: [
        {
          route: 'invoices',
          key: (t) => t('invoice.title', 2),
        },
        {
          route: 'invoice-upcoming',
          key: (t) => t('invoice.upcomingInvoice'),
        },
        {
          route: 'credit-debit',
          key: (t) => t('exceptionalPayments.title'),
        },
        {
          route: 'prices',
          key: (t) => t('tariff', 2),
        },
      ],
    },
    {
      key: (t) => t('support.title'),
      icon: {
        icon: Support,
        viewbox: '0 0 32 32',
      },
      children: [
        {
          route: 'contact',
          key: (t) => t('contact.title'),
        },
        {
          route: 'cases-index',
          key: (t) => t('contact.caseSummary'),
        },
        {
          key: (t) => t('faq'),
          redirectRoute: 'https://faq.myparcel.nl/',
          redirect: true,
          target: '_blank',
        },
        {
          key: (t) => t('tellAFriend.tellAFriend'),
          route: 'tellafriend',
        },
        {
          key: (t) => t('academy'),
          redirectRoute: 'https://www.myparcel.nl/academy',
          redirect: true,
          target: '_blank',
        },
      ],
    },
    {
      key: 'divider',
    },
    {
      route: 'subscription-overview',
      key: (t) => t('subscriptions.title', 2),
      icon: {
        icon: Subscription,
        viewbox: '0 0 24 24',
      },
      show: () => isFeatureActive('page.subscriptions.overview'),
    },
    {
      key: (t) => t('shopSettings.title', 2),
      icon: {
        icon: Cog24,
        viewbox: '0 0 24 24',
      },
      children: [
        {
          route: 'shop-settings-general',
          key: (t) => t('general'),
          params: {
            shopId: currentShopId,
          },
        },
        {
          route: 'shop-settings-orders',
          key: (t) => t('shopSettings.orders'),
          params: {
            shopId: currentShopId,
          },
          show: () => isFeatureActive('page.settings.shop.orders') && accountSettings.value.fulfilment,
        },
        {
          route: 'shop-settings-shipping-rules',
          key: (t) => t('shipment', 2),
          params: {
            shopId: currentShopId,
          },
        },
        {
          route: 'shop-settings-track-trace',
          key: (t) => t('trackTrace'),
          params: {
            shopId: currentShopId,
          },
        },
        {
          route: 'shop-settings-returns-index',
          key: (t) => t('return', 2),
          params: {
            shopId: currentShopId,
          },
        },
        {
          route: 'shop-settings-portal',
          key: (t) => t('shopSettings.trackAndTracePage.title'),
          params: {
            shopId: currentShopId,
          },
          show: () => isFeatureActive('page.settings.shop.portal'),
        },
        {
          route: 'shop-settings-company-billing',
          key: (t) => t('companySettings'),
          params: {
            shopId: currentShopId,
          },
        },
        {
          route: 'shop-settings-integration',
          key: (t) => t('integration'),
          params: {
            shopId: currentShopId,
          },
        },
        {
          route: 'sales-channels',
          key: (t) => t('salesChannels.menu'),
          params: {
            shopId: currentShopId,
          },
          show: () => isFeatureActive('page.salesChannels'),
        },
      ],
    },
  ];
};
