<template>
  <!-- Impersonate landing page -->
</template>

<script setup lang="ts">
import useAuth from '@/composables/useAuth';

const {setAuth} = useAuth();

const impersonate = async (): Promise<void> => {
  const params = new URLSearchParams(window.location.search);

  if (!params.get('userId')) {
    throw new Error("Missing 'userId' parameter");
  }

  const userId = params.get('userId');
  window.localStorage.setItem('auth0_impersonated_account_id', `${userId}`);
  const auth0 = await setAuth('impersonation');
  const url = await auth0.buildAuthorizeUrl({
    scope: 'impersonation',
  });

  if (!url) {
    throw new Error('Could not build authorize url');
  }

  window.location.replace(`${url}&impersonated_user_id=${userId}`);
};

try {
  await impersonate();
} catch (error) {
  window.location.replace('/');
}
</script>
