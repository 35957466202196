<template>
  <DropDownButton data-test="shipment-menu">
    <DropDownItem>
      <ExternalLink
        href="/shipments/create"
        data-test="shipment-menu shipment-menu-item shipment">
        {{ $t('menu.items.shipments.create') }}
      </ExternalLink>
    </DropDownItem>

    <DropDownItem v-if="showPalletLink">
      <ExternalLink
        href="/shipments/pallet/create"
        data-test="shipment-menu shipment-menu-item pallet">
        {{ $t('menu.items.shipments.pallet') }}
      </ExternalLink>
    </DropDownItem>

    <DropDownItem>
      <ExternalLink
        href="/shipments/return/create"
        data-test="shipment-menu shipment-menu-item return">
        {{ $t('menu.items.shipments.return') }}
      </ExternalLink>
    </DropDownItem>

    <DropDownItem>
      <ExternalLink
        href="/shipments/import"
        data-test="shipment-menu shipment-menu-item multiple_shipments">
        {{ $t('menu.items.shipments.import') }}
      </ExternalLink>
    </DropDownItem>

    <DropDownItem v-if="showImportExportLink">
      <ExternalLink
        href="/shipments/import-export"
        data-test="shipment-menu shipment-menu-item import_export">
        {{ $t('menu.items.shipments.import-export') }}
      </ExternalLink>
    </DropDownItem>

    <DropDownItemDivider />

    <DropDownItem>
      <ExternalLink
        href="/shipments?modal=social-shipping"
        data-test="shipment-menu shipment-menu-item social js-social-shipping-link">
        {{ $t('menu.items.shipments.social') }}
      </ExternalLink>
    </DropDownItem>
  </DropDownButton>
</template>

<script setup lang="ts">
import DropDownButton from '@/components/ui/Dropdown/DropDownButton/DropDownButton.vue';
import DropDownItem from '@/components/ui/Dropdown/DropDownItem/DropDownItem.vue';
import DropDownItemDivider from '@/components/ui/Dropdown/DropDownItemDivider/DropDownItemDivider.vue';
import {ExternalLink} from '@/components/ui/typography';
import usePlatform from '@/composables/usePlatform';

const {isMyParcelNl} = usePlatform();

const showPalletLink = isMyParcelNl();
const showImportExportLink = isMyParcelNl();
</script>
