import type {Option} from '@/types/forms/fields.types';
import type {Shop} from '@/types/shop';
import type {Account} from '@myparcel-frontend/types';
import type {ComputedRef, Ref} from 'vue';
import {useSdk, Status} from '@myparcel-frontend/sdk';
import {get} from '@vueuse/core';
import {storeToRefs} from 'pinia';
import {computed, readonly, ref} from 'vue';
import {useAccountStore} from '@/store/accountStore/accountStore';
import {useConfigStore} from '@/store/config';

interface UseAccount {
  currentShop: ComputedRef<Shop>;
  getAccounts: () => Promise<Account[]>;
  getAccount: () => Promise<Account>;
  getAccountId: () => Promise<Ref<number>>;
  getShopOptions: () => Option[];
  updateAccount: (account: Partial<Account>) => Promise<Account>;
  status: Readonly<Ref<Status>>;
}

export default function useAccount(): UseAccount {
  const configStore = useConfigStore();
  const accountStore = useAccountStore();
  const {currentShop} = storeToRefs(configStore);
  const {sdk} = useSdk();

  const status = ref(Status.IDLE);

  const fetchAccountsByAccountId = async () => {
    const accounts = await sdk.getAccount({path: {id: configStore.acl.account_id}});
    accountStore.setAccounts(accounts);
  };

  const getAccounts = async () => {
    if (!accountStore.accounts.length) {
      await fetchAccountsByAccountId();
    }

    return accountStore.accounts;
  };

  const getAccount = async () => {
    if (!accountStore.account) {
      await fetchAccountsByAccountId();
    }

    return accountStore.account;
  };

  const getAccountId = async () => {
    const account = await getAccount();

    return computed(() => get(account).id);
  };

  // returns options for the shop select form field
  const getShopOptions = (): Option[] => {
    if (!configStore.account) {
      throw new Error('Account not fetched: cannot get shop options');
    }

    return configStore.account.shops.map(
      (shop) =>
        ({
          name: shop.name,
          value: shop.id.toString(),
        }) as Option,
    );
  };

  // TODO: remove the fetchAccountsByAccountId call when the putAccount returns a 200 with value instead of 204 no content.
  // https://myparcelnl.atlassian.net/browse/FE-1048
  const updateAccount = async (accountData: Partial<Account>): Promise<Account> => {
    status.value = Status.PENDING;

    try {
      await sdk.putAccount({body: [accountData]});
      await fetchAccountsByAccountId();
    } catch (error) {
      status.value = Status.REJECTED;
      throw error;
    }

    status.value = Status.RESOLVED;
    return accountStore.account;
  };

  return {
    currentShop,
    getAccounts,
    getAccount,
    getAccountId,
    getShopOptions,
    updateAccount,
    status: readonly(status),
  };
}
