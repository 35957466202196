import type {Subscription} from '@/types/subscriptions';
import type {ComputedRef, Ref} from 'vue';
import {defineStore} from 'pinia';
import {computed, ref} from 'vue';
import log from '@/utils/logging';
import {
  isSubscriptionActive,
  isSubscriptionEnded,
  isSubscriptionEndingSoon,
  isSubscriptionPending,
  isSubscriptionStartingSoon,
  isSubscriptionTrialActive,
  isSubscriptionTrialEnded,
  isSubscriptionTypeBundles,
  isSubscriptionTypeMyAnalytics,
  isSubscriptionTypeMyContracts,
  isSubscriptionTypeMyOrders,
} from '@/utils/subscriptions';

export interface UseSubscriptionStore {
  activeMyAnalyticsSubscription: ComputedRef<Subscription>;
  activeMyContractsSubscription: ComputedRef<Subscription>;
  activeMyOrdersSubscription: ComputedRef<Subscription>;
  activeBundlesSubscription: ComputedRef<Subscription>;
  activeSubscriptions: ComputedRef<Subscription[]>;
  addSubscription: (newSubscription: Subscription) => void;
  addSubscriptions: (newSubscriptions: Subscription[]) => void;
  deleteSubscription: (deletedSubscription: Subscription) => void;
  myAnalyticsSubscriptions: ComputedRef<Subscription[]>;
  myContractsSubscriptions: ComputedRef<Subscription[]>;
  myOrdersSubscriptions: ComputedRef<Subscription[]>;
  nonEndingSubscriptions: ComputedRef<Subscription[]>;
  bundlesSubscription: ComputedRef<Subscription[]>;
  pendingSubscriptions: ComputedRef<Subscription[]>;
  setSubscriptions: (newSubscriptions: Subscription[]) => void;
  subscriptions: Ref<Subscription[]>;
  trialSubscriptions: ComputedRef<Subscription[]>;
  updateSubscription: (updatedSubscription: Subscription) => void;
}

export const useSubscriptionStore = defineStore('subscription', () => {
  const subscriptions = ref<Subscription[]>([]);

  const setSubscriptions: UseSubscriptionStore['setSubscriptions'] = (newSubscriptions) => {
    subscriptions.value = newSubscriptions;
  };

  const addSubscription = (newSubscription: Subscription) => {
    subscriptions.value.push(newSubscription);
  };

  const addSubscriptions = (newSubscriptions: Subscription[]) => {
    subscriptions.value.push(...newSubscriptions);
  };

  const pendingSubscriptions = computed(() => {
    return subscriptions.value.filter((subscription) => isSubscriptionPending(subscription.status));
  });

  const startingSoonSubscriptions = computed(() => {
    return subscriptions.value.filter((subscription) => isSubscriptionStartingSoon(subscription.status));
  });

  const trialSubscriptions = computed(() => {
    return subscriptions.value.filter((subscription) => Boolean(subscription.trial_end));
  });

  const trialActiveSubscriptions = computed(() => {
    return subscriptions.value.filter((subscription) => isSubscriptionTrialActive(subscription.status));
  });

  const trialEndedSubscriptions = computed(() => {
    return subscriptions.value.filter((subscription) => isSubscriptionTrialEnded(subscription.status));
  });

  const endedSubscriptions = computed(() => {
    return subscriptions.value.filter((subscription) => isSubscriptionEnded(subscription.status));
  });

  const endingSoonBundlesSubscription = computed(() => {
    return subscriptions.value.filter(
      (subscription) => isSubscriptionEndingSoon(subscription.status) && isSubscriptionTypeBundles(subscription.type),
    )[0];
  });

  const endingSoonSubscriptions = computed(() => {
    return subscriptions.value.filter((subscription) => isSubscriptionEndingSoon(subscription.status));
  });

  const activeSubscriptions = computed(() => {
    return subscriptions.value.filter((subscription) => isSubscriptionActive(subscription.status));
  });

  const nonEndingSubscriptions = computed(() => {
    return subscriptions.value.filter(
      (subscription) => !isSubscriptionTrialEnded(subscription.status) && !isSubscriptionEnded(subscription.status),
      // todo: this needs to be removed when the api accepts pending subscriptions:
      // && subscription.status !== SubscriptionStatus.Pending,
      // && subscription.status !== SubscriptionStatus.StartingSoon,
    );
  });

  const myContractsSubscriptions = computed((): Subscription[] => {
    return subscriptions.value.filter((subscription) => {
      const {status, type} = subscription;

      return (
        isSubscriptionTypeMyContracts(type) &&
        (isSubscriptionActive(status) ||
          isSubscriptionEndingSoon(status) ||
          isSubscriptionPending(status) ||
          isSubscriptionStartingSoon(status) ||
          isSubscriptionTrialActive(status))
      );
    });
  });

  const myOrdersSubscriptions = computed((): Subscription[] => {
    return subscriptions.value.filter((subscription) => {
      const {status, type} = subscription;

      return (
        isSubscriptionTypeMyOrders(type) &&
        (isSubscriptionActive(status) ||
          isSubscriptionEndingSoon(status) ||
          isSubscriptionPending(status) ||
          isSubscriptionStartingSoon(status) ||
          isSubscriptionTrialActive(status))
      );
    });
  });

  const myAnalyticsSubscriptions = computed((): Subscription[] => {
    return subscriptions.value.filter((subscription) => {
      const {status, type} = subscription;

      return (
        isSubscriptionTypeMyAnalytics(type) &&
        (isSubscriptionActive(status) ||
          isSubscriptionEndingSoon(status) ||
          isSubscriptionPending(status) ||
          isSubscriptionStartingSoon(status) ||
          isSubscriptionTrialActive(status))
      );
    });
  });

  const bundlesSubscriptions = computed((): Subscription[] => {
    return subscriptions.value.filter(
      ({status, type}) =>
        isSubscriptionTypeBundles(type) &&
        (isSubscriptionActive(status) ||
          isSubscriptionEndingSoon(status) ||
          isSubscriptionPending(status) ||
          isSubscriptionStartingSoon(status) ||
          isSubscriptionTrialActive(status)),
    );
  });

  const activeMyContractsSubscription = computed(() => {
    return subscriptions.value.filter(
      (subscription) => isSubscriptionTypeMyContracts(subscription.type) && isSubscriptionActive(subscription.status),
    )[0];
  });

  const activeMyAnalyticsSubscription = computed(() => {
    return subscriptions.value.filter(
      (subscription) => isSubscriptionTypeMyAnalytics(subscription.type) && isSubscriptionActive(subscription.status),
    )[0];
  });

  const activeMyOrdersSubscription = computed(() => {
    return subscriptions.value.filter(
      (subscription) => isSubscriptionTypeMyOrders(subscription.type) && isSubscriptionActive(subscription.status),
    )[0];
  });

  const activeBundlesSubscription = computed(() => {
    return subscriptions.value.filter(
      (subscription) => isSubscriptionTypeBundles(subscription.type) && isSubscriptionActive(subscription.status),
    )[0];
  });

  const updateSubscription = (updatedSubscription: Subscription) => {
    const index = subscriptions.value.findIndex((subscription) => subscription.id === updatedSubscription.id);

    if (index === -1) {
      log(Error('Cannot update subscription: not found'), {
        subscription: updatedSubscription,
        subscriptions: subscriptions.value,
      });
      return;
    }

    subscriptions.value[index] = updatedSubscription;
  };

  const deleteSubscription = (deletedSubscription: Subscription) => {
    const index = subscriptions.value.findIndex((subscription) => subscription.id === deletedSubscription.id);

    if (index === -1) {
      log(Error('Cannot delete subscription: not found'), {
        subscription: deletedSubscription,
        subscriptions: subscriptions.value,
      });
      return;
    }

    subscriptions.value.splice(index, 1);
  };

  return {
    activeMyAnalyticsSubscription,
    activeMyContractsSubscription,
    activeMyOrdersSubscription,
    activeBundlesSubscription,
    activeSubscriptions,
    addSubscription,
    addSubscriptions,
    deleteSubscription,
    endedSubscriptions,
    endingSoonBundlesSubscription,
    endingSoonSubscriptions,
    myAnalyticsSubscriptions,
    myContractsSubscriptions,
    myOrdersSubscriptions,
    nonEndingSubscriptions,
    bundlesSubscriptions,
    pendingSubscriptions,
    setSubscriptions,
    startingSoonSubscriptions,
    subscriptions,
    trialActiveSubscriptions,
    trialEndedSubscriptions,
    trialSubscriptions,
    updateSubscription,
  };
});
