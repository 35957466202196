<template>
  <AppMessage
    v-for="message in filteredSystemMessages(messageFilters)"
    :key="message.id"
    :variant="message.notification_type"
    @close="dismissMessage(message)">
    <div v-html="DOMPurify.sanitize(message.content)" />
  </AppMessage>
</template>

<script setup lang="ts">
import DOMPurify from 'dompurify';
import {ref, watch} from 'vue';
import {useRoute} from 'vue-router';
import AppMessage from '@/components/ui/AppMessages/AppMessage.vue';
import usePlatform from '@/composables/usePlatform';
import useSystemMessages from '@/composables/useSystemMessages';

const route = useRoute();

const {platform_id} = usePlatform();

const {getSystemMessages, dismissMessage, filteredSystemMessages} = useSystemMessages();

const messageFilters = ref({});

watch(
  route,
  async () => {
    const additionalSystemMessageType = route.meta?.systemMessageType;
    messageFilters.value = {
      platformId: platform_id,
      global: true,
      ...(additionalSystemMessageType ? {messageType: additionalSystemMessageType} : {}),
    };
    await getSystemMessages();
  },
  {immediate: true},
);
</script>
