import type {CarrierId, CarrierName} from '@myparcel/constants';
import type {IntBoolean} from '@myparcel/sdk';

export interface CarrierOption {
  api_key: string;
  carrier: {
    id: CarrierId;
    name: CarrierName;
  };
  carrier_id: CarrierId;
  enabled: IntBoolean;
  id: number;
  optional: IntBoolean;
  options?: CarrierOptionsOptions;
  password: string;
  primary: IntBoolean;
  type: ContractType;
  username: string;
  subscription_id?: number;
  label?: string;
}

export interface UpdateCarrierOption {
  carrier_id: CarrierId;
  username: string;
  password: string;
  options?: CarrierOptionsOptions;
}

export enum ContractType {
  MAIN = 'main',
  CUSTOM = 'custom',
}

export interface ShopCarrierOptions {
  [shopId: number]: CarrierOption[];
}

type CarrierOptionsOptions =
  | PostNlOptions
  | DhlOptions
  | DpdOptions
  | BpostOptions
  | UpsOptions
  | CheapCargoOptions
  | [];

interface PostNlOptions {
  barcodeOptions?: {
    gpRange: string;
    gpType: string;
  };
  customerCode?: string;
  customerCollectionLocation?: string;
  customerNumber?: string;
  serviceLevels?: number;
}

interface DhlOptions {
  accountNumbers?: string[];
}

export interface DpdOptions {
  sendingDepot?: string;
}

interface BpostOptions {
  passphrase?: string;
  trackingChannel?: {
    password: string;
  };
}

interface UpsOptions {
  customerNumber?: string;
}

interface CheapCargoOptions {
  package_types?: number[];
}
