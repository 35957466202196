import './assets/css/index.css';
import type {SalesChannelsStatus} from './types/salesChannels';
import type {Environment} from '@/utils/env';
import type {ValueOf} from '@myparcel-frontend/types';
import {vClickOutside, vId} from '@myparcel-frontend/plugins';
import {createApp} from 'vue';
import App from './App.vue';
import store from './store';
import {handlers} from '@/mocks/handlers';
import {mockServiceWorkerPlugin} from '@/plugins/mockServiceWorkerPlugin';
import environment, {envCheck, setEnv} from '@/utils/env';
import {featureToggles} from '@/utils/features';

declare global {
  interface Window {
    globalConfig: Environment;
    AppcuesSettings: {
      enableURLDetection: boolean;
    };
    Appcues: {
      identify: (id: string, params?: unknown) => Promise<undefined>;
      loadLaunchpad: (component: string, params?: unknown) => void;
      user: () => Promise<Record<string, unknown>>;
    };
    newrelic: {
      noticeError: (error: Error, $customAttributes?: Record<string, unknown>) => void;
    };
    isSidebarCollapsed: boolean;
    salesChannelStatusChanged: (salesChannelId: string, status: ValueOf<typeof SalesChannelsStatus>) => void;
  }
}

// Set app global configuration by using env vars or
// injected globalConfig.
setEnv(window?.globalConfig);

window.AppcuesSettings = {
  enableURLDetection: true,
};

window.isSidebarCollapsed = Boolean(JSON.parse(localStorage.getItem('sideMenuCollapsed') as string));

if (!envCheck()) {
  // eslint-disable-next-line no-console
  console.error('Cannot start applicaton: Auth0 env vars not set.');
  // eslint-disable-next-line no-console
  console.error('Please set appropriate VITE_AUTH0_ env vars.');
}

const app = createApp(App)
  .use(mockServiceWorkerPlugin, {
    isProduction: !environment.useMockingApiClients,
    handlers,
  })
  .use(featureToggles)
  .use(store)
  .use(vId)
  .use(vClickOutside);

app.mount('#app');
