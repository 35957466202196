<template>
  <div>
    <Button
      class="flex items-center w-full"
      size="none"
      variant="none"
      @click="toggle"
      @mousedown.prevent>
      <span
        data-test="header--selected-shop"
        class="overflow-hidden text-ellipsis whitespace-nowrap">
        <slot name="button" />
      </span>
      <IconBase class="min-w-[16px] ml-1 w-4">
        <component :is="menuIsOpen ? ChevronUp : ChevronDown" />
      </IconBase>
    </Button>
    <div
      ref="menuRef"
      class="dropdown--wrapper flex justify-center z-20"
      :class="{
        down: direction === DropDownDirection.DOWN,
        up: direction === DropDownDirection.UP,
      }"
      tabindex="-1"
      @blur="close">
      <div
        v-show="menuIsOpen"
        data-test="shop-switch-menu"
        class="absolute bg-white border drop-shadow-lg dropdown--menu mb-4 py-2 rounded-regular"
        :aria-hidden="!menuIsOpen">
        <ul role="list">
          <slot :close="close" />
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {IconBase, ChevronDown, ChevronUp} from '@myparcel-frontend/ui';
import {ref} from 'vue';
import {provide} from 'vue';
import {DropDownDirection} from './DropDownMenu.types';
import Button from '@/components/ui/Button';

withDefaults(
  defineProps<{
    direction?: DropDownDirection;
  }>(),
  {
    direction: DropDownDirection.DOWN,
  },
);

defineSlots<{
  button: void;
  default(props: {close: () => void}): void;
}>();

const menuRef = ref<HTMLElement>();
const menuIsOpen = ref(false);

const close = (): void => toggle(undefined, false);

const toggle = (event?: MouseEvent, to?: boolean): void => {
  menuIsOpen.value = to === undefined ? !menuIsOpen.value : to;

  if (menuIsOpen.value && menuRef.value) {
    menuRef.value.focus();
  }
};

provide('closeDropDownMenu', close);
</script>

<style lang="scss" scoped>
.dropdown--wrapper {
  .dropdown--menu::before {
    position: absolute;
    content: '';
    left: 50%;
    border: 0.5rem solid;
    border-color: var(--color-gray-100) var(--color-gray-100) transparent transparent;
    transform-origin: 50% 50%;
  }

  &.down {
    .dropdown--menu {
      @apply mt-5;
      &::before {
        top: -0.5rem;
        bottom: unset;
        transform: translateX(-50%) rotate(-45deg);
      }
    }
  }

  &.up {
    .dropdown--menu::before {
      top: unset;
      bottom: -0.5rem;
      transform: translateX(-50%) rotate(-225deg);
    }
  }
}
</style>
