<template>
  <div
    class="relative"
    @mouseleave="close">
    <div class="bg-secondary border-none flex h-[38px] items-stretch relative rounded-3xl xl:h-auto xl:w-auto">
      <ExternalLink
        class="border border-solid border-transparent font-normal h-full hidden hover:bg-secondary-200 hover:border-secondary-200 overflow-visible pl-3 pr-2 py-[7px] rounded-l-3xl select-none text-white whitespace-nowrap xl:block"
        href="/shipments/create">
        {{ $t('newShipment') }}
      </ExternalLink>

      <Button
        type="button"
        class="appearance-none border border-solid border-transparent cursor-pointer flex gap-4 hover:bg-secondary-200 hover:border-secondary-200 items-center justify-center pl-2 pr-3 py-[7px] rounded-3xl text-white xl:rounded-none xl:rounded-r-3xl"
        @click="toggle ? close() : open()"
        @mouseenter="close">
        <IconBase class="hidden text-white xl:block">
          <ChevronDown />
        </IconBase>

        <slot name="button-text" />
        <IconBase class="block bottom-[1px] left-[2px] relative text-white xl:hidden">
          <component :is="icon" />
        </IconBase>
      </Button>

      <div
        v-show="toggle"
        data-test="dropdown-content"
        class="absolute left-auto right-0 top-full">
        <div
          class="bg-clip-padding bg-white border border-solid flex flex-col font-normal my-2 px-0 py-2 rounded-lg shadow-xl text-base w-full">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {Component} from 'vue';
import {IconBase, ChevronDown, Plus} from '@myparcel-frontend/ui';
import Button from '@/components/ui/Button';
import {ExternalLink} from '@/components/ui/typography';
import useToggle from '@/composables/useToggle';

withDefaults(defineProps<{icon?: Component}>(), {
  icon: () => Plus,
});

defineSlots<{
  'button-text': void;
  default: void;
}>();

const {toggle, updateToggle} = useToggle();

const close = () => {
  updateToggle(false);
};

const open = () => {
  updateToggle(true);
};
</script>
