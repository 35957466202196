/**
 * Transient Data Cache.
 * To be used when exchanging data from apps on the same domain.
 * See @/providers/AuthProvider.vue or @/utils/cache.spec.ts for an example of
 * how to use this.
 */

const hour = 60 * 60 * 1000;

/**
 * Gets the data from localstorag or fetches it from the API.
 * If the timestamp is expired, it fetches the data from the API.
 *
 * @param key - Identifier in localstorage.
 * @param fallback - Function to fetch the data from API.
 * @returns Data from localstorage or fetched data from API.
 */
const fetchFromCache = async <T extends Record<string, unknown>>(
  key: string,
  fallback: () => Promise<T>,
): Promise<T> => {
  const item = JSON.parse(localStorage.getItem(key) ?? '{}')[0];

  if (item && item.timestamp > Date.now()) {
    return item.data;
  }

  return fallback();
};

/**
 * Removes the data from localstorage.
 *
 * @param key - Identifier in localstorage.
 */
const removeFromCache = (key: string): void => {
  return localStorage.removeItem(key);
};

/**
 * Saves the data to localstorage. Adds validness timestamp to the data.
 *
 * @param key - Identifier in localstorage.
 * @param data - Data to be stored in localstorage.
 */
const setInCache = (key: string, data: Record<string, unknown>): void => {
  localStorage.setItem(
    key,
    JSON.stringify([
      {
        data,
        timestamp: Date.now() + hour,
      },
    ]),
  );
};

export {fetchFromCache, removeFromCache, setInCache};
