import type {SubscriptionProductIds} from '@/types/subscriptions';
import {BundlesProductId, MyAnalyticsProductId, MyContractsProductId, MyOrdersProductId} from '@/types/subscriptions';

export const productEnumToArray = <T>(enumObject: T): number[] => {
  return Object.values(enumObject).filter((value) => typeof value === 'number');
};

export const isMyAnalyticsProductId = (productId: SubscriptionProductIds): boolean => {
  return productEnumToArray(MyAnalyticsProductId).includes(productId);
};

export const isMyContractsProductId = (productId: SubscriptionProductIds): boolean => {
  return productEnumToArray(MyContractsProductId).includes(productId);
};

export const isMyOrdersProductId = (productId: SubscriptionProductIds): boolean => {
  return productEnumToArray(MyOrdersProductId).includes(productId);
};

export const isBundlesProductId = (productId: SubscriptionProductIds): boolean => {
  return productEnumToArray(BundlesProductId).includes(productId);
};

export const getSubscriptionProductGroupIds = (productId: SubscriptionProductIds): number[] => {
  switch (true) {
    case isMyAnalyticsProductId(productId):
      return productEnumToArray(MyAnalyticsProductId);
    case isMyContractsProductId(productId):
      return productEnumToArray(MyContractsProductId);
    case isMyOrdersProductId(productId):
      return productEnumToArray(MyOrdersProductId);
    case isBundlesProductId(productId):
      return productEnumToArray(BundlesProductId);
    default:
      return [];
  }
};
