<template>
  <div
    id="header"
    data-test="header"
    class="bg-primary flex h-[70px] items-center px-4 sticky text-white w-full z-20">
    <IconBase
      :size="IconSize.MediumLarge"
      class="block cursor-pointer lg:hidden min-w-[32px] ml-n3 mr-2 pr-2"
      role="button"
      :aria-label="$t('navigation.open')"
      @click="toggleSidebar">
      <Hamburger />
    </IconBase>

    <a href="/">
      <BaseImage
        :src="logoSmallPath"
        alt="Myparcel"
        class="block h-6 md:hidden min-w-[24px]" />

      <BaseImage
        :src="logoPath"
        alt="Myparcel"
        class="h-6 hidden md:block min-w-[147px]" />
    </a>

    <DropDownMenu
      class="ml-5"
      direction="down">
      <template #button>
        {{ currentShop.name }}
      </template>

      <DropDownMenu.LinkItem
        v-for="shop in sortedActiveShops"
        :key="shop.id"
        @click="changeCurrentShop(shop.id)">
        {{ shop.name }}
      </DropDownMenu.LinkItem>
    </DropDownMenu>

    <div class="grow" />

    <HotjarFeedback v-if="showHotjarFeedback" />

    <AppCuesLaunchpad v-if="configStore.appcuesIsReady" />

    <NewShipmentDropDown />
  </div>
</template>

<script setup lang="ts">
import {IconBase, Hamburger, IconSize, BaseImage} from '@myparcel-frontend/ui';
import {computed} from 'vue';
import HotjarFeedback from './HotjarFeedback.vue';
import AppCuesLaunchpad from '@/components/ui/AppCuesLaunchpad/AppCuesLaunchpad.vue';
import DropDownMenu from '@/components/ui/DropDownMenu';
import NewShipmentDropDown from '@/components/ui/NewShipmentDropDown/NewShipmentDropDown.vue';
import useAccount from '@/composables/useAccounts';
import useMobileMenu from '@/composables/useMobileMenu';
import usePlatform from '@/composables/usePlatform';
import useUiSettings from '@/composables/useUiSettings';
import {useConfigStore} from '@/store/config';

const {logoPath, logoSmallPath, isMyParcelNl} = usePlatform();
const {toggleSidebar} = useMobileMenu();
const {uiSettings, putUiSettings} = useUiSettings();
const {currentShop, activeShops} = useAccount();
const configStore = useConfigStore();

const sortedActiveShops = computed(() => {
  return activeShops.value.sort((shopA, shopB) => {
    return shopA.name.localeCompare(shopB.name);
  });
});

const changeCurrentShop = async (shopId: number): Promise<void> => {
  if (shopId === currentShop.value.id) {
    return;
  }

  await putUiSettings({
    body: [
      {
        ...uiSettings.value,
        preferred_shop_id: shopId,
      },
    ],
  });
};

const showHotjarFeedback = isMyParcelNl();
</script>
