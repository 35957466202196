export enum Target {
  SELF = '_self',
  BLANK = '_blank',
  PARENT = '_parent',
  TOP = '_top',
}

export enum ExternalLinkVariant {
  DEFAULT = 'default',
  FOOTER_NAVIGATION = 'footer navigation',
  FOOTER_LEGAL = 'footer legal',
  HEADER_MENU = 'header menu',
  HEADER_MENU_ITEM = 'header menu item',
  CLEAN = 'clean',
  OUTLINE = 'outline',
}
