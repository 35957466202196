import {parsers} from '@myparcel-frontend/utils';

interface Environment {
  [key: string]: unknown;
  auth0Domain: string;
  auth0ClientId: Record<string, string>;
  auth0Audience: string;
  auth0Scope: string;
  apiUrl: string;
  assetsUrls: Record<string, string>;
  hereMapsApiKey: string;
  hotjar: Record<string, string>;
  devMode: boolean;
  useMockingApiClients: boolean;
  frontofficeUrl: Record<string, string>;
}

const environment: Environment = {
  auth0Domain: '',
  auth0ClientId: {},
  auth0Audience: '',
  auth0Scope: '',
  apiUrl: '',
  assetsUrls: {},
  devMode: false,
  hotjar: {},
  hereMapsApiKey: '',
  useMockingApiClients: false,
  frontofficeUrl: {},
};

/**
 * Helper to detect if required environment variables are set.
 *
 * @returns {boolean}
 */
const envCheck = (): boolean =>
  !!environment.auth0Domain?.length &&
  !!Object.keys(environment.auth0ClientId).length &&
  !!environment.auth0Audience?.length &&
  !!environment.auth0Scope?.length &&
  !!environment.apiUrl?.length;

/**
 * Set environment variables from global/window config or environment variables.
 * Prioritizes the environment variables (for local development),
 * falls back to the global config (for production).
 * If no environment variables are set, revert to default values, application will not start.
 *
 * - @param config (optional) Object containing key/value pairs.
 */
// eslint-disable-next-line complexity
const setEnv = (config?: Environment): void => {
  environment.auth0Domain = import.meta.env.VITE_AUTH0_DOMAIN || config?.auth0Domain || environment.auth0Domain;

  const auth0ClientId = import.meta.env.VITE_AUTH0_CLIENT_ID;

  if (auth0ClientId) {
    environment.auth0ClientId = {
      default: auth0ClientId,
    };
  } else {
    environment.auth0ClientId = config?.auth0ClientId ?? environment.auth0ClientId;
  }

  environment.auth0Audience = import.meta.env.VITE_AUTH0_AUDIENCE ?? config?.auth0Audience ?? environment.auth0Audience;
  environment.auth0Scope = import.meta.env.VITE_AUTH0_SCOPE ?? config?.auth0Scope ?? environment.auth0Scope;

  environment.apiUrl = import.meta.env.VITE_API_URL ?? config?.apiUrl ?? environment.apiUrl;

  if (import.meta.env.VITE_FRONTOFFICE_URL) {
    environment.frontofficeUrl = {default: import.meta.env.VITE_FRONTOFFICE_URL};
  } else {
    environment.frontofficeUrl = config?.frontofficeUrl ?? environment.frontofficeUrl;
  }

  if (import.meta.env.VITE_ASSETS_URL) {
    environment.assetsUrls = {default: import.meta.env.VITE_ASSETS_URL};
  } else {
    environment.assetsUrls = config?.assetsUrls ?? environment.assetsUrls;
  }

  environment.hereMapsApiKey =
    import.meta.env.VITE_HEREMAPS_API_KEY ?? config?.hereMapsApiKey ?? environment.hereMapsApiKey;

  const hotjarId = import.meta.env.VITE_HOTJAR_ID;

  if (hotjarId) {
    environment.hotjar = {
      default: hotjarId,
    };
  } else {
    environment.hotjar = config?.hotjar ?? environment.hotjar;
  }

  environment.useMockingApiClients =
    parsers.stringToBoolean(import.meta.env.VITE_USE_MOCKING_API_CLIENTS) ??
    config?.useMockingApiClients ??
    environment.useMockingApiClients;

  environment.devMode =
    parsers.stringToBoolean(import.meta.env.VITE_DEV_MODE) ?? config?.devMode ?? environment.devMode;
};

export {environment as default, envCheck, setEnv, type Environment};
