import environment from '@/utils/env';

/**
 * Default logger to New Relic.
 *
 * @param error - The JS error to log.
 * @param $customAttributes - Additional parameters to show in New Relic.
 */
const log = (error: Error, $customAttributes?: Record<string, unknown>): void => {
  if (!environment.devMode && window.newrelic) {
    window.newrelic.noticeError(error, $customAttributes);
  } else {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export default log;
