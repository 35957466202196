<template>
  <div
    id="impersonation-banner"
    data-test="impersonation-banner"
    class="bg-red-600 flex gap-4 h-6 justify-center p-0.5 text-sm text-white w-full">
    <span>{{ $t('admin.loggedinAs') }}</span>
    <span
      id="copy-username"
      class="cursor-pointer hover:underline"
      @click="copyUsername">
      {{ account.username }}
    </span>
    <span
      v-if="name"
      id="copy-name"
      class="cursor-pointer hover:underline"
      @click="copyName">
      ({{ name }})
    </span>
  </div>
</template>

<script setup lang="ts">
import {ref} from 'vue';
import useAccount from '@/composables/useAccounts';

const {account} = useAccount();
const name = ref(`${account.value.first_name} ${account.value.last_name}`.trim());

const copyUsername = (): Promise<void> => navigator.clipboard.writeText(account.value.username);
const copyName = (): Promise<void> => navigator.clipboard.writeText(name.value);
</script>
