import type {AnalyticsDashboardsCredentialsState} from './useAnalyticsDashboards.types';
import type {AnalyticsDashboard} from '@/types/analytics-dashboard.types';
import {useSdk, Status} from '@myparcel-frontend/sdk';
import {storeToRefs} from 'pinia';
import useAccount from '@/composables/useAccounts';
import useGoogleTagManager from '@/composables/useGoogleTagManager';
import {useAnalyticsDashboardStore} from '@/store/analyticsDashboardStore';

export default function useAnalyticsDashboards() {
  let status = Status.IDLE;
  const analyticsDashboardStore = useAnalyticsDashboardStore();
  const {credentials, dashboards, activeAnalyticsDashboards} = storeToRefs(analyticsDashboardStore);

  const getAnalyticsDashboardCredentials = async (): Promise<AnalyticsDashboardsCredentialsState> => {
    if (credentials.value && dashboards.value) {
      return {
        status: Status.RESOLVED,
        credentials: credentials.value,
        dashboards: activeAnalyticsDashboards.value,
      };
    }

    const {sdk} = useSdk();
    status = Status.PENDING;

    try {
      const newDashboards = await sdk.getAnalyticsDashboards();

      analyticsDashboardStore.setCredentials(newDashboards.cumulio_token);
      analyticsDashboardStore.setDashboards(newDashboards.dashboards);

      status = Status.RESOLVED;
    } catch (err) {
      status = Status.REJECTED;
      throw err;
    }

    return {
      status,
      credentials: credentials.value,
      dashboards: activeAnalyticsDashboards.value,
    };
  };

  /* c8 ignore start */
  const sendAnalyticsDashboardLoadingTime = (label: AnalyticsDashboard['dashboard'], timeDiff: number) => {
    const {trackEvent} = useGoogleTagManager();
    const account = useAccount();
    const userId = account.currentUser.value.id || null;

    // TODO set in constant
    const event = {
      event: 'cumulio_dashboard_loaded',
      'cumulio-load-time': timeDiff,
      label,
      user_id: userId,
    };

    trackEvent(event);
  };
  /* c8 ignore stop */

  return {
    credentials,
    dashboards,
    activeAnalyticsDashboards,
    getAnalyticsDashboardCredentials,
    sendAnalyticsDashboardLoadingTime,
  };
}
