<!--
  This container is to be used in the root of the app, to host all
  kinds of modals of the app.
-->
<template>
  <div id="global-modals-container" />
</template>

<style lang="scss">
// Unscoped selector, to only select the first modal child wrapper to apply
// a background color, to prevent cascading background colors with multiple
// modals.

#global-modals-container > .modal-wrapper:first-child {
  @apply bg-neutral-900/50;
}
</style>
