import type {Overrides} from '@/types/router';
import type {ComponentInternalInstance} from 'vue';
import type {useI18n} from 'vue-i18n';
import type {RouteLocationNormalized, RouteLocationRaw, Router} from 'vue-router';
import {createRouter, createWebHistory} from 'vue-router';
import useAcl from '@/composables/useAcl';
import useAuth from '@/composables/useAuth';
import useHotjar from '@/composables/useHotjar';
import useMobileMenu from '@/composables/useMobileMenu';
import usePageTitle from '@/composables/usePageTitle';
import usePlatform from '@/composables/usePlatform';
import platformRoutes from '@/configs/router';
import {setGoogleTagManager} from '@/providers/GoogleTagManagerProvider';
import {cookieBannerIsSet, setCookieBanner} from '@/utils/cookieBanner/setCookieBanner';

const {closeSidebar} = useMobileMenu();

/**
 * Two authentication items are checked before the route change is approved:
 * - The user has ACL permission to access the route (meta.permissions)
 * - The user satisfies the canGo predicates (meta.canGo)
 * Otherwise, a redirect to dashboard is done.
 */
const beforeEach = async ({
  to,
  overrides,
  instance,
  i18n,
  router,
}: {
  to: RouteLocationNormalized;
  overrides: Overrides;
  instance: ComponentInternalInstance;
  i18n: typeof useI18n;
  router: Router;
}): Promise<RouteLocationRaw | boolean> => {
  // this only now pertains to auth0 callback route:
  if (to.meta?.auth === false) {
    return true;
  }

  if (overrides.includes(to.name as string)) {
    // Set return url in localstorage so we can redirect back to the page after landing in meteor.
    localStorage.setItem('vueReturnUrl', to.fullPath);
    window.location.replace('/');
    return false;
  }

  const {hasRoutePermission, getAuth} = useAuth();

  const auth0 = getAuth();
  const isAuthenticated = await auth0.isAuthenticated();

  // user is not authenticated at all:
  if (!isAuthenticated) {
    localStorage.setItem('redirect_to', to.fullPath);
    const url = await auth0.buildAuthorizeUrl();
    window.location.replace(url);
    return {name: 'redirect'};
  }

  const {services} = usePlatform();

  // from here on we have a bearer token so we can access the API:
  setGoogleTagManager(instance, router);

  if (!cookieBannerIsSet()) {
    setCookieBanner(services.cookieBanner);
  }

  // user is authenticated, check authorization for page:
  const isAuthorized = await hasRoutePermission(to);

  if (!isAuthorized) {
    return {name: 'dashboard'};
  }

  let canGo = true;

  if (to.meta?.canGo?.length) {
    canGo = to.meta.canGo.reduce((prev: boolean, next: () => boolean) => {
      return prev && next();
    }, true);
  }

  if (!canGo) {
    return {name: 'dashboard'};
  }

  const {hasSubscriptionFeature} = useAcl();

  // user is authorized, check if they have access to the subscription feature:
  let hasAccessToSubscriptionFeatures = true;

  if (to.meta?.subscriptionFeatures) {
    hasAccessToSubscriptionFeatures = to.meta.subscriptionFeatures.reduce((prev: boolean, next: string) => {
      return prev && hasSubscriptionFeature(next).value;
    }, true);
  }

  // not authorized or does not pass canGo predicates:
  if (!hasAccessToSubscriptionFeatures) {
    return {name: 'dashboard'};
  }

  // user is authorized, continue normally.
  return true;
};

const afterEach = (): void => {
  const {resetPageTitle} = usePageTitle();
  const {triggerHotjarIdentify} = useHotjar();

  resetPageTitle();
  triggerHotjarIdentify();
  closeSidebar();
};

const setupRouter = (instance: ComponentInternalInstance, i18n: typeof useI18n): Router => {
  const {routes, overrides} = platformRoutes();

  const router = createRouter({
    history: createWebHistory(),

    // Disable ts error since we extend the route type with our own properties.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    routes,
  });

  // Disable ts error since we extend the route type with our own properties.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  router.beforeEach((to) => beforeEach({to, overrides, instance, i18n, router}));
  router.afterEach(afterEach);

  return router;
};

export {setupRouter as default, beforeEach, afterEach};
