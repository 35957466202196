export interface ButtonProps {
  variant: string;
  size: string;
  disabled: boolean;
  type: string;
  classes: string;
}

export interface ButtonSetupProps {
  buttonClasses: string;
}

export interface ButtonStoriesProps {
  default: string;
  variant: string;
  size: string;
}

export enum ButtonSizes {
  BADGE = 'badge',
  MEDIUM = 'medium',
  NONE = 'none',
}

export enum ButtonVariant {
  Basic = 'basic',
  Danger = 'danger',
  DangerOutline = 'danger-outline',
  DangerBasic = 'danger-basic',
  Primary = 'primary',
  PrimaryOutline = 'primary-outline',
  Secondary = 'secondary',
  Modal = 'modal',
  None = 'none',
  BasicError = 'basic-error',
  SecondaryLight = 'secondary-light',
  TextPrimary = 'text-primary',
}

export enum ButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit',
}
