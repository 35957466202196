<!-- The Container component is only to be used once in the root of the application. -->
<template>
  <div id="notification__container">
    <TransitionGroup name="list">
      <Toast
        v-for="notification in toasterNotifications"
        :id="notification.id"
        :key="notification.id"
        :size="NotificationSize.Half"
        :timeout="notification.timeout"
        :variant="notification.variant"
        :can-close="notification.canClose"
        :with-shadow="notification.withShadow"
        class="flex-none"
        @remove="removeNotification">
        <template
          v-if="notification.title"
          #title>
          {{ notification.title }}
        </template>
        {{ notification.body }}
      </Toast>
    </TransitionGroup>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue';
import Toast from './Toast.vue';
import useNotifications, {NotificationLocation, NotificationSize} from '@/composables/useNotifications';

const {notifications, removeNotification} = useNotifications();

const toasterNotifications = computed(() => {
  return notifications.value.filter((notification) => notification.location === NotificationLocation.Toaster);
});
</script>

<style lang="scss" scoped>
.list-enter-active {
  transition: all 0.5s ease;
}
.list-leave-active {
  transition: all 0.2s ease;
}
.list-enter-from {
  opacity: 0;
  transform: translateX(30px);
}
.list-leave-to {
  opacity: 0;
}
</style>

<style lang="scss">
#notification__container {
  @apply fixed h-screen z-60 bottom-0 right-0 overflow-y-auto overflow-x-hidden pb-5 px-8;
  @apply flex flex-col-reverse max-w-full pointer-events-none;
  @apply w-full;
  @apply md:w-auto;

  .notification__container__notification:last-child {
    @apply mt-20;
  }
}
</style>
