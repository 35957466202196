<template>
  <div
    v-if="loaded"
    id="appcues-launchpad"
    class="mr-5 mt-[7px]" />
</template>

<script setup lang="ts">
import {ref} from 'vue';
import {useI18n} from 'vue-i18n';

const {t} = useI18n();

const loaded = ref(false);

const appcuesScript = document.getElementById('appcues-script-loader') as HTMLScriptElement;

if (appcuesScript) {
  const notificationHeader = t('notification', 2);

  window.Appcues.loadLaunchpad('#appcues-launchpad', {
    header: `<div class="font-weight-bold">${notificationHeader}</div>`,
    position: 'center',
    icon: '/staticv2/images/megaphone.svg',
  });

  loaded.value = true;
}
</script>

<style lang="scss">
.appcues-widget {
  &-icon {
    &:not([data-appcues-count='0']):before {
      @apply absolute h-full w-full;

      content: '';
      right: -2px;
      background-image: url('/staticv2/images/megaphone-loud.svg');
      background-repeat: none;
    }

    &[data-appcues-count='0'] {
      .appcues & {
        &:after {
          @apply hidden;
        }
      }
    }

    &:after {
      .appcues & {
        @apply bg-orange-500;

        bottom: unset;
        right: unset;
        top: -0.6em;
        left: -0.9em;
      }
    }
  }

  &-list {
    li time {
      @apply hidden;
    }

    .appcues-unread {
      @apply font-normal;
    }

    .appcues-nothing-new {
      @apply hidden;
    }
  }

  &-dropdown {
    border-radius: 10px;
  }
}
</style>
