import type {SystemMessage} from '@myparcel-frontend/types';
import {useStorage} from '@vueuse/core';
import {defineStore} from 'pinia';

const EXPIRATION_TIME = 3000 * 30;

export interface DismissedMessage {
  id: number;
  modified: Date;
}

export type DismissedMessages = Record<string, Date>;

export const useSystemMessagesStore = defineStore({
  id: 'systemMessages',
  state: () => ({
    systemMessages: useStorage('systemMessages', [] as SystemMessage[]),
    expirationDate: useStorage('systemMessagesExpirationDate', 0),
    dismissedMessages: useStorage('turnedOffNotifications', {} as DismissedMessages),
  }),
  getters: {
    isExpired: (state) => {
      return state.expirationDate < Date.now();
    },
    isDismissed: (state) => (message: SystemMessage) => {
      const {id, modified} = message;
      const dismissedMessage = state.dismissedMessages[id.toString()];
      return dismissedMessage && dismissedMessage === modified;
    },
  },
  actions: {
    setSystemMessages(systemMessages: SystemMessage[]) {
      this.systemMessages = systemMessages;
      this.expirationDate = Date.now() + EXPIRATION_TIME;
    },
    addDismissedMessage(message: SystemMessage) {
      const {id, modified} = message;
      this.dismissedMessages[id.toString()] = modified;
    },
  },
});
