import type {SubscriptionProductIds} from '@/types/subscriptions';
import {SubscriptionType} from '@/types/subscriptions';
import {
  isBundlesProductId,
  isMyAnalyticsProductId,
  isMyContractsProductId,
  isMyOrdersProductId,
} from '@/utils/subscriptions/productId';

export const getSubscriptionType = (productId: SubscriptionProductIds): SubscriptionType => {
  switch (true) {
    case isMyAnalyticsProductId(productId):
      return SubscriptionType.MyAnalytics;
    case isMyContractsProductId(productId):
      return SubscriptionType.MyContracts;
    case isMyOrdersProductId(productId):
      return SubscriptionType.MyOrders;
    case isBundlesProductId(productId):
      return SubscriptionType.Bundles;
    default:
      return null;
  }
};

export const isSubscriptionTypeMyAnalytics = (type: SubscriptionType): boolean => type === SubscriptionType.MyAnalytics;

export const isSubscriptionTypeMyOrders = (type: SubscriptionType): boolean => type === SubscriptionType.MyOrders;

export const isSubscriptionTypeMyContracts = (type: SubscriptionType): boolean => type === SubscriptionType.MyContracts;

export const isSubscriptionTypeBundles = (type: SubscriptionType): boolean => type === SubscriptionType.Bundles;
