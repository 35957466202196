import type {MenuItem} from '@/components/ui/layout/sideMenu/menuItem/menuItem.types';
import type {Ref} from 'vue';
import type {RouteLocationNormalizedLoaded} from 'vue-router';
import type {RouteRecordRaw} from 'vue-router';
import {ref} from 'vue';
import useAuth from '@/composables/useAuth';
import menu from '@/configs/menu';
import getRoutes from '@/configs/router/routes';
import {useConfigStore} from '@/store/config';

const allRoutes = getRoutes();
const menuRef = ref([]) as Ref<MenuItem[]>;

/**
 * Helper hook to access and manipulate the side menu.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useMenu() {
  const configStore = useConfigStore();

  const itemIsVisible = (item: MenuItem): boolean => {
    if (item.hasOwnProperty('show')) {
      if (typeof item.show === 'function' && !item.show()) {
        return false;
      }

      if (!item.show) {
        return false;
      }
    }

    return Boolean(
      (item.route && routeHasPermissions(item.route)) ||
        item.redirect ||
        item.key === 'divider' ||
        item.children?.some(itemIsVisible),
    );
  };

  /**
   * Find a route in the routesArray by its name
   */
  function findByRouteName(route: string): RouteRecordRaw | null {
    function search(item: RouteRecordRaw): RouteRecordRaw | null {
      if (item.name === route) {
        return item;
      }

      if (item.children) {
        for (const child of item.children) {
          const found = search(child);

          if (found) {
            // also adds the parents permissions to the child
            return {
              ...found,
              meta: {permissions: [...(item.meta?.permissions || []), ...(found.meta?.permissions || [])]},
            } as RouteRecordRaw;
          }
        }
      }

      return null;
    }

    for (const element of allRoutes) {
      const foundItem = search(element);

      if (foundItem) {
        return foundItem;
      }
    }

    return null;
  }

  /**
   * Returns false if the route has permissions and the user does not have them
   */
  function routeHasPermissions(routeName: string): boolean {
    const {hasPermissions} = useAuth();
    const route = findByRouteName(routeName);

    if (!route) return false;

    if (route.meta && route.meta.permissions) {
      return hasPermissions(route.meta.permissions);
    }

    return true;
  }

  const getMenu = (): Ref<MenuItem[]> => {
    menuRef.value = menu(configStore.config.platform_key).reduce((acc: MenuItem[], item: MenuItem) => {
      const visibleChildren = item.children?.filter(itemIsVisible) ?? [];

      if (itemIsVisible(item)) {
        // If there is exactly one child the parent should be the link instead of a dropdown with one item
        // If there is more than one child the parent should be a dropdown
        // If the parent has no children it should stay a link/divider etc.
        if (visibleChildren.length === 1) {
          acc.push({...visibleChildren[0], icon: item.icon, key: item.key});
        } else if (visibleChildren.length) {
          acc.push({...item, children: visibleChildren});
        } else {
          delete item.children;
          acc.push(item);
        }
      }

      return acc;
    }, []);

    return menuRef;
  };

  /**
   * Matches a menu item based on the MenuItem and the menu configuration.
   * Subpages not defined in the active configuration may be matched by their parent MenuItem instead.
   *
   * @param route - The route to check.
   * @param item - MenuItem from the active configuration.
   */
  const isActive = (route: RouteLocationNormalizedLoaded, item: MenuItem): boolean => {
    const childActive = (item: MenuItem) => {
      return item.children?.some((child: MenuItem) => isActive(route, child));
    };

    // match based on the configuration defined in @/configs/menu:
    const configMatch = item.route === route.name || childActive(item);

    return Boolean(configMatch);
  };

  return {menu: menuRef, itemIsVisible, getMenu, isActive};
}
