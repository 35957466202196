/* eslint-disable @typescript-eslint/no-magic-numbers */
import {convertEnumValue} from '@myparcel/constants/helpers';
import {TierName} from '@/types/subscriptions/tiers';

export const MyContractsProductId = {
  Main: 4600,
  Free: 4601,
  Light: 4610,
  Start: 4611,
  Plus: 4612,
  Premium: 4613,
  Max: 4614,
} as const;

export const MyContractsTierName = {
  Light: TierName.Light,
  Start: TierName.Start,
  Plus: TierName.Plus,
  Premium: TierName.Premium,
  Max: TierName.Max,
} as const;

export const MY_CONTRACTS_IDS_TO_NAMES = {
  [MyContractsProductId.Light]: MyContractsTierName.Light,
  [MyContractsProductId.Start]: MyContractsTierName.Start,
  [MyContractsProductId.Plus]: MyContractsTierName.Plus,
  [MyContractsProductId.Premium]: MyContractsTierName.Premium,
  [MyContractsProductId.Max]: MyContractsTierName.Max,
};

export const MY_CONTRACTS_NAMES_TO_IDS = {
  [MyContractsTierName.Light]: MyContractsProductId.Light,
  [MyContractsTierName.Start]: MyContractsProductId.Start,
  [MyContractsTierName.Plus]: MyContractsProductId.Plus,
  [MyContractsTierName.Premium]: MyContractsProductId.Premium,
  [MyContractsTierName.Max]: MyContractsProductId.Max,
};

export const toMyContractsTierName = (id: typeof MyContractsProductId): typeof MyContractsTierName => {
  return convertEnumValue(MY_CONTRACTS_IDS_TO_NAMES, id);
};

export const toSubscriptionProductId = (name: typeof MyContractsTierName): typeof MyContractsProductId => {
  return convertEnumValue(MY_CONTRACTS_NAMES_TO_IDS, name);
};
