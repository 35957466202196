import type {FeatureKey, FeatureToggle, Features} from '@/types/features.types';
import usePlatform from '@/composables/usePlatform';
import getAppFeatures from '@/configs/features';
import {accountFeatures} from '@/configs/features/account';
import {useConfigStore} from '@/store/config';
import {useFeaturesStore} from '@/store/featuresStore';
import {dayjs} from '@myparcel-frontend/utils';
import env from '@/utils/env';
import {flattenObject} from '@/utils/formatters';

interface UseFeatures {
  features: Features;
  loadFeatureToggles: () => void;
  isFeatureActive: (feature: FeatureKey) => boolean;
}

export default function useFeatures(): UseFeatures {
  const {features, updateFeature} = useFeaturesStore();

  const isTimeBasedToggleActive = ([startTime, endTime]: [string, string]) => {
    const now = dayjs();
    const start = dayjs(startTime);
    const end = dayjs(endTime);

    // if no end is set the feature is active from the start time
    if (!endTime) {
      return now.isAfter(start);
    }

    // if no start is set the feature is active until the end time
    if (!startTime) {
      return now.isBefore(end);
    }

    // if both start and end are set the feature is active between the start and end time
    return now.isBetween(start, end, null, '[]');
  };

  /**
   * Load the application feature toggles.
   * Return the application feature toggles.
   */
  const loadApplicationFeatures = (): Features => {
    const {platform_key} = usePlatform();
    const appFeatures = flattenObject<Features>(getAppFeatures(platform_key));

    const newFeatures = {} as Features;
    const entries = Object.entries(appFeatures) as [keyof Features, FeatureToggle][];
    entries.forEach(([feature, value]) => {
      if (!Array.isArray(value)) {
        newFeatures[feature] = value;
        return;
      }

      newFeatures[feature] = isTimeBasedToggleActive(value);
    });

    return newFeatures;
  };

  /**
   * Get the account.
   * Load the account feature toggles.
   * Check if the feature is active for the account.
   * Return the account feature toggles.
   */
  const loadAccountFeatures = (): Features => {
    const configStore = useConfigStore();

    const newFeatures = {} as Features;
    const entries = Object.entries(accountFeatures) as [keyof Features, number[]][];

    entries.forEach(([feature, ids]) => {
      newFeatures[feature] = ids.includes(configStore.account.id);
    });

    return newFeatures;
  };

  /**
   * Load the features from local storage.
   * Get all keys from local storage that start with 'features.'
   * Then loop through the keys and set the feature to the value.
   * If the value is true, the feature is active.
   * If the value is false, the feature is inactive.
   * If the value is not set, the feature is inactive.
   */
  const loadLocalStorageFeatures = (): Features => {
    const newFeatures = {} as Features;

    const storageKeys: string[] = [];

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key) {
        storageKeys.push(key);
      }
    }

    storageKeys
      .filter((key) => key.startsWith('features.'))
      .forEach((key) => {
        let feature = key.replace('features.', '');

        /**
         * Remove the vue. prefix when it is set.
         * This is used so we can have the same feature toggle in the vue app and the meteor app.
         */
        if (feature.startsWith('vue.')) {
          feature = feature.replace('vue.', '');
        }

        const value = localStorage.getItem(key) === 'true';
        newFeatures[feature] = value;
      });

    return newFeatures;
  };

  /**
   * Check if a feature is active.
   * remove the features. prefix when it is set.
   * @example
   * isFeatureActive('features.accountFeature') // true
   * isFeatureActive('accountFeature') // true
   */
  const isFeatureActive: UseFeatures['isFeatureActive'] = (feature) => {
    const featureKey = feature.startsWith('features.') ? feature.replace('features.', '') : feature;
    return features[featureKey];
  };

  /**
   * Load the feature toggles.
   * First load the application features.
   * Then load the account features.
   * Then load the local storage features.
   * Then update the feature store.
   */
  const loadFeatureToggles: UseFeatures['loadFeatureToggles'] = () => {
    const applicationFeatures = loadApplicationFeatures();
    const accountFeatures = loadAccountFeatures();
    const localStorageFeatures = loadLocalStorageFeatures();

    const newFeatures = {
      ...applicationFeatures,
      ...accountFeatures,
      ...localStorageFeatures,
    };

    if (env.devMode) {
      newFeatures.errors = true;
    }

    const entries = Object.entries(newFeatures) as [keyof Features, boolean][];
    entries.forEach(([feature, value]) => {
      updateFeature(feature, value);
    });
  };

  return {
    features,
    loadFeatureToggles,
    isFeatureActive,
  };
}
