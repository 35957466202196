/* c8 ignore start */
<template>
  <template v-if="routerError">
    <RouterWarning>
      {{ routerError }}
    </RouterWarning>
  </template>
  <template v-else>
    <slot />
  </template>
</template>
/* c8 ignore end */

<script setup lang="ts">
import {getCurrentInstance, ref} from 'vue';
import {useI18n} from 'vue-i18n';
import RouterWarning from '../components/RouterWarning.vue';
import setupRouter from '@/router';

const routerError = ref();
const i18n = useI18n({useScope: 'global'});

const setRouter = (): void => {
  const instance = getCurrentInstance();

  if (!instance?.appContext.app.config.globalProperties.$router) {
    instance.appContext.app.use(setupRouter(instance, i18n));
  }
};

// @TODO - is it still possible to fail the setup?
/* c8 ignore start */
try {
  setRouter();
} catch (error: unknown) {
  routerError.value = error;
}
/* c8 ignore end */
</script>
