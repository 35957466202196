<template>
  <div>
    <AppMessage
      v-for="message in accountMessages"
      :key="message.id"
      :variant="NotificationVariant.Error"
      :class="{'opacity-25': deletingMessage === message.id}"
      @close="doDeleteAccountMessage(message)">
      <div v-html="specificMessage(message)" />
    </AppMessage>

    <BolExpiringSoonNotification
      v-if="isActiveShopBolCredentialsExpiringSoon"
      :credentials="activeShopBolCredentials[0]" />
    <BolExpiredNotification
      v-if="isActiveShopBolCredentialsExpired"
      :credentials="activeShopBolCredentials[0]" />
  </div>
</template>

<script setup lang="ts">
import type {AccountMessage} from '@myparcel-frontend/types';
import {AccountMessageSpecifics} from '@myparcel-frontend/types';
import DOMPurify from 'dompurify';
import {ref, watch} from 'vue';
import {useI18n} from 'vue-i18n';
import {useRoute} from 'vue-router';
import AppMessage from '@/components/ui/AppMessages/AppMessage.vue';
import BolExpiredNotification from '@/components/ui/BolExpiredNotification/BolExpiredNotification.vue';
import BolExpiringSoonNotification from '@/components/ui/BolExpiringSoonNotification/BolExpiringSoonNotification.vue';
import useAccountMessages from '@/composables/useAccountMessages';
import useBolCredentials from '@/composables/useBolCredentials/useBolCredentials';
import {NotificationVariant} from '@/composables/useNotifications';

const {t} = useI18n();
const {getAccountMessages, deleteAccountMessage, accountMessages} = useAccountMessages();
const {activeShopBolCredentials, isActiveShopBolCredentialsExpiringSoon, isActiveShopBolCredentialsExpired} =
  useBolCredentials();
const route = useRoute();

const deletingMessage = ref<number>();

const doDeleteAccountMessage = async (message: AccountMessage): Promise<void> => {
  if (deletingMessage.value) {
    return;
  }

  deletingMessage.value = message.id;
  await deleteAccountMessage(message);
  deletingMessage.value = undefined;
};

const specificMessage = (message: AccountMessage): string => {
  const {code} = message;

  const specificMessage = Object.entries(AccountMessageSpecifics).find(([_, value]) => value === code);

  if (specificMessage) {
    return t(`accountMessages.${specificMessage[0]}`);
  }

  return DOMPurify.sanitize(message.message);
};

watch(route, () => getAccountMessages(), {immediate: true});
</script>
