import { ref as a } from "vue";
const e = a(!1);
function r() {
  return {
    pageTitleStatus: e,
    setPageTitle: (t) => {
      document.title = t, e.value = !0;
    },
    resetPageTitle: () => {
      e.value = !1;
    }
  };
}
export {
  r as usePageTitle
};
