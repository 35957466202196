import type {FeatureKey} from '@/types/features.types';
import type {App} from 'vue';
import useFeatures from '@/composables/useFeatures';

if (import.meta.env.DEV) {
  if (!localStorage.getItem('errors')) {
    localStorage.setItem('errors', 'true');
  }
}

export const featureToggles = {
  install: (app: App): void => {
    app.config.globalProperties.$featureEnabled = (feature: FeatureKey) => {
      const {isFeatureActive} = useFeatures();
      return isFeatureActive(feature);
    };
  },
};
