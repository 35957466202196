const c = {
  install: (n) => {
    n.directive("click-outside", {
      mounted: (t, e) => {
        t.clickOutsideEvent = (i) => {
          e.value.fn instanceof Function && (t === i.target || t.contains(i.target) || e.value.fn(i, t));
        }, e.value.listen && (document.addEventListener("click", t.clickOutsideEvent, !0), t.setAttribute("data-listening", "true"));
      },
      beforeUpdate: (t, e) => {
        const { listening: i } = t.dataset, s = i === "true";
        !s && e.value.listen ? (document.addEventListener("click", t.clickOutsideEvent, !0), t.setAttribute("data-listening", "true")) : s && !e.value.listen && (document.removeEventListener("click", t.clickOutsideEvent, !0), t.setAttribute("data-listening", "false"));
      },
      unmounted: (t) => {
        document.removeEventListener("click", t.clickOutsideEvent, !0), t.setAttribute("data-listening", "false");
      }
    });
  }
}, u = {
  install: (n) => {
    n.directive("id", (t, e) => {
      t.setAttribute("data-id", e.value);
    });
  }
};
export {
  c as vClickOutside,
  u as vId
};
