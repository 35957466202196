export enum NAME {
  CARRIER_COMPARE = 'carrier_compare',
  DEFAULT_PICKUP_ADDRESS_WARNING = 'default_pickup_address_warning',
  DELETE_SHIPPING_RULE = 'delete_shipping_rule',
  DELETE_RETURN_RULE = 'delete_return_rule',
  GENERATE_API_KEY_WARNING = 'generate_api_key_warning',
  HOTJAR_FEEDBACK = 'hotjar_feedback',
  INVOICE_CREATE = 'invoice_create_proforma',
  PACKAGING_OVERVIEW_DETAILS = 'packaging_overview_details',
  RESUME_SUBSCRIPTION_CONFIRMATION = 'resume_subscription_confirmation',
  RETURN_RULES_DEACTIVATE = 'return_rules_deactivate',
  SHIPPING_RULES_DEACTIVATE = 'shipping_rules_deactivate',
  SUBSCRIPTION_CONFIRMATION = 'subscription_confirmation',
  SUPPORT_CASE_CLOSE = 'support_case_close',
  UNSUBSCRIBE_CONFIRMATION = 'unsubscribe_confirmation',
  TRACK_AND_TRACE = 'track_and_trace',
  ORDER_OVERVIEW_HIDE_WARNING = 'order_overview_hide_warning',
  ORDER_OVERVIEW_ORDER_LINES = 'order_overview_order_lines',
  ORDER_OVERVIEW_ORDER_NOTES = 'order_overview_order_notes',
  DEACTIVATE_WEBSHOP_WARNING = 'deactivate_webshop_warning',
  ADD_NEW_WEBSHOP = 'add_new_webshop',
  TWO_FACTOR_AUTHENTICATION_INSTALLATION = 'two_factor_authentication_installation',
  DELETE_USER_CONFIRMATION = 'delete_user_confirmation',
  WOOCOMMERCE_CREDENTIALS = 'woocommerce_credentials',
  REMOVE_SALES_CHANNEL = 'remove_sales_channel',
  EMAIL_PASSWORD_CHECK = 'email_password_check',
  PASSWORD_CHECK = 'password_check',
  EXTENDED_SEARCH = 'extended_search',
  ASSIGN_USER_TO_ORDER = 'assign_user_to_order',
  UNASSIGN_USER_FROM_ORDER = 'unassign_user_from_order',
}
