var C = Object.defineProperty;
var b = (s, t, r) => t in s ? C(s, t, { enumerable: !0, configurable: !0, writable: !0, value: r }) : s[t] = r;
var e = (s, t, r) => b(s, typeof t != "symbol" ? t + "" : t, r);
function _(s, t) {
  return (s == null ? void 0 : s[t]) !== void 0;
}
var T = Object.defineProperty, A = (s, t, r) => t in s ? T(s, t, { enumerable: !0, configurable: !0, writable: !0, value: r }) : s[t] = r, i = (s, t, r) => (A(s, typeof t != "symbol" ? t + "" : t, r), r);
class u extends Error {
  constructor(t) {
    super(t), i(this, "name", "user"), Object.setPrototypeOf(this, u.prototype), this.name = "UserException";
  }
}
class y extends Error {
  constructor(t) {
    super(t.message), i(this, "data"), Object.setPrototypeOf(this, y.prototype), this.name = "ApiException", this.data = t;
  }
}
const k = (s, t) => {
  if (t) {
    const r = Object.entries(t).map(([a, o]) => `${a}=${o}`);
    r.length && (s += `?${r.join("&")}`);
  }
  return s;
}, I = "https://api.myparcel.nl", O = ["POST", "PUT"];
class G {
  constructor(t) {
    i(this, "baseUrl"), i(this, "headers"), i(this, "parameters"), i(this, "options"), i(this, "_requiredHeaders", []), this.baseUrl = ((t == null ? void 0 : t.baseUrl) ?? I).replace(/\/+$/, ""), this.headers = (t == null ? void 0 : t.headers) ?? {}, this.parameters = (t == null ? void 0 : t.parameters) ?? {}, this.options = (t == null ? void 0 : t.options) ?? {};
  }
  get requiredHeaders() {
    return this._requiredHeaders;
  }
  set requiredHeaders(t) {
    this._requiredHeaders = t;
  }
  /**
   * Prepare and execute the final request and handle the response.
   */
  async doRequest(t, r) {
    const a = this.normalizeOptions(t, { ...r, ...this.options });
    this.validateHeaders(t, a);
    const o = await this.request(t, a);
    if (_(o, "errors"))
      throw new y(o);
    return this.getResponseBody(t, o);
  }
  getResponseBody(t, r) {
    if (!_(r, "data"))
      return r;
    const a = t.getResponseProperty();
    if (!a)
      return r.data;
    const { page: o, size: c, results: d } = r.data;
    return o === void 0 && c === void 0 && d === void 0 ? r.data[a] : {
      [a]: r.data[a],
      ...o !== void 0 && { page: o },
      ...c !== void 0 && { size: c },
      ...d !== void 0 && { results: d }
    };
  }
  /**
   * Gets default and custom headers.
   *
   * @protected
   */
  getHeaders() {
    return {
      Accept: "application/json",
      ...this.headers
    };
  }
  /**
   * Uses the base url, endpoint and options to create the final request url.
   *
   * @protected
   */
  createUrl(t, r) {
    let a = t.getPath();
    if (a.length && !a.startsWith("/") && (a = `/${a}`), r != null && r.path && (a = this.substitutePath(a, r.path)), a.includes("/:"))
      throw new u(`One or more path variables are missing in ${a}`);
    return r != null && r.parameters && (a = k(a, r.parameters)), this.baseUrl + a;
  }
  /**
   * Replace path variables in an url path. Deletes optional parameters if
   * they're not passed.
   *
   * @protected
   */
  substitutePath(t, r) {
    if (r && Object.entries(r).forEach(([a, o]) => {
      t = t.replace(`:${a}`, String(o));
    }), t.includes(":")) {
      const a = t.match(/:\w+?\?/g);
      a == null || a.forEach((o) => {
        t = t.replace(`/${o}`, "");
      });
    }
    return t;
  }
  /**
   * Validates headers passed in options.
   *
   * @protected
   */
  validateHeaders(t, r) {
    const a = Object.entries(r.headers).reduce(
      (c, [d, g]) => ({
        ...c,
        [d.toLowerCase()]: g
      }),
      {}
    ), o = this.requiredHeaders.filter((c) => !(c.toLowerCase() in a));
    if (o.length)
      throw new u(`Required headers are missing: ${o.join(", ")}`);
  }
  /**
   * Executes transformations on options before request.
   *
   * @protected
   */
  normalizeOptions(t, r) {
    const a = {
      ...r,
      parameters: {
        ...this.parameters,
        ...r.parameters,
        ...t.getParameters()
      },
      headers: {
        ...O.includes(t.method) ? { "Content-Type": "application/json" } : {},
        ...this.getHeaders(),
        ...r.headers,
        ...t.getHeaders()
      }
    };
    if (_(r, "body")) {
      const o = t.getProperty();
      if (r.body instanceof FormData)
        return a.body = r.body, delete a.headers["Content-Type"], a;
      o === void 0 ? a.body = {
        data: r.body
      } : a.body = {
        data: { [o]: r.body }
      };
    }
    return a;
  }
}
const R = (s) => {
  try {
    return JSON.parse(s), !0;
  } catch {
    return !1;
  }
};
class w extends G {
  constructor(t) {
    super(t), i(this, "request", async (r, a) => {
      var o, c;
      const d = r.getTimeout() ?? a.timeout, g = new AbortController(), D = setTimeout(() => g.abort(), d), P = {
        method: r.method,
        headers: a.headers,
        ...d && { signal: g.signal }
      };
      _(a, "body") && (a.body instanceof FormData ? P.body = a.body : P.body = JSON.stringify(a.body));
      const h = await fetch(this.createUrl(r, a), P);
      if (clearTimeout(D), h.body) {
        if ((o = h.headers.get("Content-Disposition")) != null && o.includes("attachment"))
          return h.blob();
        const x = await h.text();
        return (c = h.headers.get("Content-Type")) != null && c.includes("application/json") && R(x) ? JSON.parse(x) : x;
      }
    });
  }
}
class v {
  constructor(t) {
    i(this, "method", "GET"), i(this, "property"), i(this, "responseProperty"), i(this, "timeout"), i(this, "headers"), i(this, "parameters"), this.headers = (t == null ? void 0 : t.headers) ?? {}, this.parameters = (t == null ? void 0 : t.parameters) ?? {};
  }
  getHeaders() {
    return this.headers;
  }
  getParameters() {
    return this.parameters;
  }
  getPath() {
    return this.path;
  }
  getProperty() {
    return this.property;
  }
  getResponseProperty() {
    return this.responseProperty ?? this.property;
  }
  getTimeout() {
    return this.timeout;
  }
}
class n extends v {
}
class p extends v {
}
function E(s, t) {
  if (!t.length)
    throw new u("At least one endpoint must be passed.");
  return t.reduce(
    (r, a) => ({
      ...r,
      [a.name]: async (o) => s.doRequest(a, o ?? {})
    }),
    {}
  );
}
const U = (s, t) => (s.requiredHeaders = ["Authorization"], E(s, t)), B = (s, t) => E(s, t);
class H extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getAccountActiveBrackets");
    e(this, "path", "accounts/:account_id/active_brackets");
    e(this, "property", "active_brackets");
  }
}
class L extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getAccountBrackets");
    e(this, "path", "account_brackets");
    e(this, "property", "account_brackets");
  }
}
class F extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PUT");
    e(this, "name", "putAccountBrackets");
    e(this, "path", "account_brackets");
    e(this, "property", "account_brackets");
  }
}
class z extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postAccountCarrierOptions");
    e(this, "path", "accounts/:shop_id/carrier_options");
    e(this, "property", "carrier_options");
  }
}
class j extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "DELETE");
    e(this, "name", "deleteAccountCarrierOption");
    e(this, "path", "accounts/:account_id/carrier_options/:carrier_options_id");
    e(this, "property", "carrier_options");
  }
}
class q extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "DELETE");
    e(this, "name", "deleteAccountMessage");
    e(this, "path", "account_messages/:id");
    e(this, "property", "messages");
  }
}
class M extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getAccountMessages");
    e(this, "path", "account_messages");
    e(this, "property", "messages");
  }
}
class W extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "DELETE");
    e(this, "name", "deleteAccountProductPrices");
    e(this, "path", "account_product_prices/:price_id");
  }
}
class $ extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getAccountProductPrices");
    e(this, "path", "account_product_prices");
    e(this, "property", "account_products");
  }
}
class K extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postAccountProductPrices");
    e(this, "path", "account_product_prices");
    e(this, "property", "account_product_prices");
    e(this, "responseProperty", "prices");
  }
}
class N extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getAccountSupportCases");
    e(this, "path", "accounts/:id/support_cases");
    e(this, "property", "support_cases");
  }
}
class J extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getAccount");
    e(this, "path", "accounts/:id");
    e(this, "property", "accounts");
    e(this, "timeout", 2e4);
  }
}
class V extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getAccounts");
    e(this, "path", "accounts");
    e(this, "property", "accounts");
  }
}
class Q extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PUT");
    e(this, "name", "putAccount");
    e(this, "path", "accounts");
    e(this, "property", "accounts");
  }
}
class X extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postAccountsAnonymize");
    e(this, "path", "accounts/anonymize");
    e(this, "property", "anonymize_accounts");
  }
}
class Y extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "GET");
    e(this, "name", "getAcl");
    e(this, "path", "acl");
  }
}
class Z extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getAclRolesCustomer");
    e(this, "path", "acl/roles/customer");
    e(this, "property", "roles");
  }
}
class ee extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "DELETE");
    e(this, "name", "deleteAddress");
    e(this, "path", "addresses/:id");
    e(this, "property", "addresses");
  }
}
class te extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "DELETE");
    e(this, "name", "deleteAddresses");
    e(this, "path", "addresses/:ids");
    e(this, "property", "addresses");
  }
}
class se extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getAddress");
    e(this, "path", "addresses/:id");
    e(this, "property", "addresses");
  }
}
class ne extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getAddresses");
    e(this, "path", "addresses");
    e(this, "property", "addresses");
  }
}
class re extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postAddress");
    e(this, "path", "addresses");
    e(this, "property", "addresses");
    e(this, "responseProperty", "ids");
  }
}
class ae extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PUT");
    e(this, "name", "putAddress");
    e(this, "path", "addresses");
    e(this, "property", "addresses");
  }
}
class oe extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getAnalyticsDashboards");
    e(this, "path", "auth/cumulio/token");
  }
  /* c8 ignore next 6 */
  getHeaders() {
    return {
      ...super.getHeaders(),
      "x-dmp-feature-flags": "luzmo-slugs"
    };
  }
}
class ie extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getApiKeys");
    e(this, "path", "keys");
    e(this, "property", "api_keys");
  }
}
class pe extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postApiKeys");
    e(this, "path", "keys");
    e(this, "property", "api_keys");
  }
}
class ce extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getBaseProductPrices");
    e(this, "path", "base_product_prices");
    e(this, "property", "base_products");
  }
}
class de extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getBracketRanges");
    e(this, "path", "bracket_ranges/:platform_id");
  }
}
class le extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getBrackets");
    e(this, "path", "brackets/:platform_id");
  }
}
class me extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postBrandingBannerImage");
    e(this, "path", "shops/:shop_id/images/branding_banner");
    e(this, "property", "branding_banner");
    e(this, "responseProperty", "ids");
  }
}
class he extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "DELETE");
    e(this, "name", "deleteBrandingBannerImage");
    e(this, "path", "shops/:shop_id/images/branding_banner");
    e(this, "property", "branding_banner");
  }
}
class ue extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getCarrierConfigurations");
    e(this, "path", "shops/:shop_id/carrier_configurations");
    e(this, "property", "carrier_configurations");
  }
}
class ge extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postCarrierConfiguration");
    e(this, "path", "shops/:shop_id/carriers/:carrier_id/carrier_configuration");
    e(this, "property", "carrier_configurations");
  }
}
class _e extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "DELETE");
    e(this, "name", "deleteCarrierOption");
    e(this, "path", "accounts/:account_id/carrier_options/:contract_id");
    e(this, "property", "carrier_options");
  }
}
class Pe extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getCarrierOptions");
    e(this, "path", "carrier_management/accounts/:account_id/carrier_options");
    e(this, "property", "carrier_options");
  }
}
class xe extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postCarrierOptions");
    e(this, "path", "accounts/:account_id/carrier_options");
    e(this, "property", "carrier_options");
    e(this, "responseProperty", "ids");
  }
}
class ye extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PUT");
    e(this, "name", "putCarrierOptions");
    e(this, "path", "carrier_management/accounts/:account_id/carrier_options");
    e(this, "property", "carrier_options");
  }
}
class Se extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getConsumerPortalFields");
    e(this, "path", "consumer_portal/fields/:shop_id");
    e(this, "property", "fields");
  }
}
class we extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PUT");
    e(this, "name", "putConsumerPortalFields");
    e(this, "path", "consumer_portal/fields");
    e(this, "property", "fields");
  }
}
class ve extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getDimensionsPresets");
    e(this, "path", "dimensions_presets");
    e(this, "property", "dimensions_presets");
  }
}
class Ee extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PATCH");
    e(this, "name", "patchDimensionsPresets");
    e(this, "path", "dimensions_presets");
    e(this, "property", "dimensions_presets");
  }
}
class fe extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postDimensionsPresets");
    e(this, "path", "dimensions_presets");
    e(this, "property", "dimensions_presets");
  }
}
class De extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PUT");
    e(this, "name", "putDimensionsPresets");
    e(this, "path", "dimensions_presets");
    e(this, "property", "dimensions_presets");
  }
}
class Ce extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "DELETE");
    e(this, "name", "deleteDimensionsPresets");
    e(this, "path", "dimensions_presets/:id");
    e(this, "property", "dimensions_presets");
  }
}
class be extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getDropOffPoints");
    e(this, "path", "carriers/:carrier_id/drop_off_points");
    e(this, "property", "drop_off_points");
  }
}
class Te extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getEmails");
    e(this, "path", "emails");
    e(this, "property", "email_templates");
  }
}
class Ae extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PUT");
    e(this, "name", "putEmail");
    e(this, "path", "emails");
    e(this, "property", "email_templates");
  }
}
class ke extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "DELETE");
    e(this, "name", "deleteExternalIntegrationsProviderCredentialsAmazon");
    e(this, "path", "external_integration_provider_credentials/amazon/:id");
    e(this, "property", "credentials");
  }
}
class Ie extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getExternalIntegrationsProviderCredentialsAmazon");
    e(this, "path", "external_integration_provider_credentials/amazon");
    e(this, "property", "credentials");
  }
}
class Oe extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postExternalIntegrationsProviderCredentialsAmazon");
    e(this, "path", "external_integration_provider_credentials/amazon/selling_partner");
    e(this, "property", "credentials");
    e(this, "responseProperty", "redirect_url");
  }
}
class Ge extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PUT");
    e(this, "name", "putExternalIntegrationsProviderCredentialsAmazon");
    e(this, "path", "external_integration_provider_credentials/amazon");
    e(this, "property", "credentials");
    e(this, "responseProperty", "ids");
  }
}
class Re extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getExternalIntegrationsProviderCredentialsBol");
    e(this, "path", "external_integration_provider_credentials/bol_dot_com/v3");
    e(this, "property", "credentials");
  }
}
class Ue extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postExternalIntegrationsProviderCredentialsBol");
    e(this, "path", "external_integration_provider_credentials/bol_dot_com/v3");
    e(this, "property", "credentials");
    e(this, "responseProperty", "redirect_url");
  }
}
class Be extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "DELETE");
    e(this, "name", "deleteExternalIntegrationsProviderCredentialsBol");
    e(this, "path", "external_integration_provider_credentials/bol_dot_com/:id");
    e(this, "property", "credentials");
  }
}
class He extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PUT");
    e(this, "name", "putExternalIntegrationsProviderCredentialsBol");
    e(this, "path", "external_integration_provider_credentials/bol_dot_com/v3");
    e(this, "property", "credentials");
    e(this, "responseProperty", "ids");
  }
}
class Le extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getExternalIntegrationsProviderCredentialsEtsy");
    e(this, "path", "external_integration_provider_credentials/etsy");
    e(this, "property", "credentials");
  }
}
class Fe extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postExternalIntegrationsProviderCredentialsEtsy");
    e(this, "path", "external_integration_provider_credentials/etsy");
    e(this, "property", "credentials");
    e(this, "responseProperty", "redirect_url");
  }
}
class ze extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "DELETE");
    e(this, "name", "deleteExternalIntegrationsProviderCredentialsEtsy");
    e(this, "path", "external_integration_provider_credentials/etsy/:id");
    e(this, "property", "credentials");
  }
}
class je extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PUT");
    e(this, "name", "putExternalIntegrationsProviderCredentialsEtsy");
    e(this, "path", "external_integration_provider_credentials/etsy");
    e(this, "property", "credentials");
    e(this, "responseProperty", "ids");
  }
}
class qe extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getExternalIntegrationsProviderCredentialsExact");
    e(this, "path", "external_integration_provider_credentials/exact");
    e(this, "property", "credentials");
  }
}
class Me extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postExternalIntegrationsProviderCredentialsExact");
    e(this, "path", "external_integration_provider_credentials/exact");
    e(this, "property", "credentials");
    e(this, "responseProperty", "redirect_url");
  }
}
class We extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "DELETE");
    e(this, "name", "deleteExternalIntegrationsProviderCredentialsExact");
    e(this, "path", "external_integration_provider_credentials/exact/:id");
    e(this, "property", "credentials");
  }
}
class $e extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PUT");
    e(this, "name", "putExternalIntegrationsProviderCredentialsExact");
    e(this, "path", "external_integration_provider_credentials/exact");
    e(this, "property", "credentials");
    e(this, "responseProperty", "ids");
  }
}
class Ke extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getExternalIntegrationsProviderCredentialsExactDe");
    e(this, "path", "external_integration_provider_credentials/exact_de");
    e(this, "property", "credentials");
  }
}
class Ne extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postExternalIntegrationsProviderCredentialsExactDe");
    e(this, "path", "external_integration_provider_credentials/exact_de");
    e(this, "property", "credentials");
    e(this, "responseProperty", "redirect_url");
  }
}
class Je extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "DELETE");
    e(this, "name", "deleteExternalIntegrationsProviderCredentialsExactDe");
    e(this, "path", "external_integration_provider_credentials/exact_de/:id");
    e(this, "property", "credentials");
  }
}
class Ve extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PUT");
    e(this, "name", "putExternalIntegrationsProviderCredentialsExactDe");
    e(this, "path", "external_integration_provider_credentials/exact_de");
    e(this, "property", "credentials");
    e(this, "responseProperty", "ids");
  }
}
class Qe extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postExternalIntegration");
    e(this, "path", "auth/external_integration/:provider");
  }
}
class Xe extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getExternalIntegrationsProviderCredentialsWix");
    e(this, "path", "external_integration_provider_credentials/wix");
    e(this, "property", "credentials");
  }
}
class Ye extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postExternalIntegrationsProviderCredentialsWix");
    e(this, "path", "external_integration_provider_credentials/wix");
    e(this, "property", "credentials");
    e(this, "responseProperty", "redirect_url");
  }
}
class Ze extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "DELETE");
    e(this, "name", "deleteExternalIntegrationsProviderCredentialsWix");
    e(this, "path", "external_integration_provider_credentials/wix/:id");
    e(this, "property", "credentials");
  }
}
class et extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PUT");
    e(this, "name", "putExternalIntegrationsProviderCredentialsWix");
    e(this, "path", "external_integration_provider_credentials/wix");
    e(this, "property", "credentials");
    e(this, "responseProperty", "ids");
  }
}
class tt extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postFulfilmentDisable");
    e(this, "path", "fulfilment/settings/disable");
    e(this, "property", "fulfilment");
  }
}
class st extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postFulfilmentDisableAutosplit");
    e(this, "path", "fulfilment/settings/autosplit/disable");
    e(this, "property", "fulfilment");
  }
}
class nt extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postFulfilmentDisablePicqer");
    e(this, "path", "fulfilment/settings/picqer/disable");
    e(this, "property", "fulfilment");
  }
}
class rt extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postFulfilmentEnable");
    e(this, "path", "fulfilment/settings/enable");
    e(this, "property", "fulfilment");
  }
}
class at extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postFulfilmentEnableAutosplit");
    e(this, "path", "fulfilment/settings/autosplit/enable");
    e(this, "property", "fulfilment");
  }
}
class ot extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postFulfilmentEnablePicqer");
    e(this, "path", "fulfilment/settings/picqer/enable");
    e(this, "property", "fulfilment");
  }
}
class it extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postFulfilmentMode");
    e(this, "path", "fulfilment/settings/fulfilment-mode");
    e(this, "property", "fulfilment");
  }
}
const pt = [
  new rt(),
  new tt(),
  new nt(),
  new ot(),
  new it(),
  new at(),
  new st()
];
class ct extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getImplications");
    e(this, "path", "shops/:shop_id/shipping_rules/implications");
    e(this, "property", "implications");
  }
}
class dt extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postInterimInvoices");
    e(this, "path", "interim_invoices");
    e(this, "property", "interim_invoice_requests");
    e(this, "responseProperty", "ids");
  }
}
class lt extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getInterimInvoices");
    e(this, "path", "pro_forma/:shop_ids");
    e(this, "property", "proforma_invoices");
  }
  /* c8 ignore next 6 */
  getHeaders() {
    return {
      ...super.getHeaders(),
      Accept: "application/json;charset=utf-8"
    };
  }
}
class mt extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getInvitations");
    e(this, "path", "invitations");
    e(this, "property", "invitations");
  }
}
class ht extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postInvitation");
    e(this, "path", "invitations");
    e(this, "property", "invitations");
    e(this, "responseProperty", "ids");
  }
}
class ut extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PUT");
    e(this, "name", "putInvitation");
    e(this, "path", "invitations");
    e(this, "property", "invitations");
  }
}
class gt extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getInvoices");
    e(this, "path", "invoices");
    e(this, "property", "invoices");
  }
  /* c8 ignore next 6 */
  getHeaders() {
    return {
      ...super.getHeaders(),
      Accept: "application/vnd.invoice_normal+json"
    };
  }
}
class _t extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getInvoiceDetails");
    e(this, "path", "pro_forma/:shop_id");
    e(this, "timeout", 2e5);
  }
  /* c8 ignore next 7 */
  getHeaders() {
    return {
      ...super.getHeaders(),
      Accept: "application/vnd.invoice+pdf",
      "Content-Type": "application/vnd.invoice+pdf"
    };
  }
}
class Pt extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getInvoiceDetailLegacyAttachment");
    e(this, "path", "invoices/:invoice_id");
    e(this, "property", "pdfs");
  }
  /* c8 ignore next 6 */
  getHeaders() {
    return {
      ...super.getHeaders(),
      Accept: "application/json.invoice_detailed_pdf_link+json"
    };
  }
}
class xt extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getInvoiceCsvAttachment");
    e(this, "path", "invoices/:invoice_id");
    e(this, "response", "pdfs");
    e(this, "timeout", 2e5);
  }
  /* c8 ignore next 6 */
  getHeaders() {
    return {
      ...super.getHeaders(),
      Accept: "application/vnd.invoice_detailed+csv;version=2.0"
    };
  }
}
class yt extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getInvoiceDetailAttachment");
    e(this, "path", "invoices/:invoice_id");
    e(this, "responseProperty", "pdfs");
  }
  /* c8 ignore next 7 */
  getHeaders() {
    return {
      ...super.getHeaders(),
      Accept: "application/vnd.invoice_pdf_link+json"
    };
  }
}
class St extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getInvoiceShortAttachment");
    e(this, "path", "invoices/:invoice_id");
    e(this, "responseProperty", "pdfs");
  }
  /* c8 ignore next 6 */
  getHeaders() {
    return {
      ...super.getHeaders(),
      Accept: "application/json.invoice_normal_pdf_link+json"
    };
  }
}
class wt extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getLocations");
    e(this, "path", "locations");
    e(this, "property", "locations");
  }
}
class vt extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getPackagings");
    e(this, "path", "woocommerce/order");
    e(this, "property", "orders");
  }
}
class Et extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postPackaging");
    e(this, "path", "woocommerce/order");
    e(this, "property", "orders");
    e(this, "responseProperty", "ids");
  }
}
class ft extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "postPaymentInstructions");
    e(this, "path", "billing/payment_instructions");
    e(this, "property", "payment_instruction_requests");
    e(this, "responseProperty", "payment_instructions");
    e(this, "method", "POST");
  }
}
class Dt extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "checkPostalCode");
    e(this, "path", "postal_code/check");
    e(this, "property", "postal_code");
  }
}
class Ct extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getPriceScaleBrackets");
    e(this, "path", "accounts/:id/active_brackets");
    e(this, "property", "active_brackets");
    e(this, "timeout", 2e5);
  }
}
class bt extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postShipmentCarrierPrice");
    e(this, "path", "shipments/carrier_price");
    e(this, "property", "shipments");
    e(this, "responseProperty", "carrier_price");
  }
}
class Tt extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getShipment");
    e(this, "path", "shipments/:id");
    e(this, "property", "shipments");
  }
}
class At extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getShipments");
    e(this, "path", "shipments");
    e(this, "property", "shipments");
  }
}
class kt extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postShipments");
    e(this, "path", "shipments");
    e(this, "property", "shipments");
    e(this, "responseProperty", "ids");
  }
  /* c8 ignore start */
  getHeaders() {
    return {
      ...super.getHeaders(),
      "Content-Type": "application/vnd.shipment+json;charset=utf-8;version=1.1"
    };
  }
  /* c8 ignore stop */
}
class It extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getShippingRules");
    e(this, "path", "shipping_rules");
    e(this, "property", "shipping_rules");
  }
}
class Ot extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PATCH");
    e(this, "name", "patchShippingRule");
    e(this, "path", "shipping_rules");
    e(this, "property", "shipping_rules");
  }
}
class Gt extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postShippingRule");
    e(this, "path", "shipping_rules");
    e(this, "property", "shipping_rules");
  }
}
class Rt extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PUT");
    e(this, "name", "putShippingRule");
    e(this, "path", "shipping_rules");
    e(this, "property", "shipping_rules");
  }
}
class Ut extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "DELETE");
    e(this, "name", "deleteShippingRule");
    e(this, "path", "shipping_rules/:id");
    e(this, "property", "shipping_rules");
  }
}
class Bt extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getShopAccess");
    e(this, "path", "shop_access");
    e(this, "property", "shop_access");
  }
}
class Ht extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postShopAccess");
    e(this, "path", "shop_access");
    e(this, "property", "shop_access");
  }
}
class Lt extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getShopCarrierOptions");
    e(this, "path", "carrier_management/shops/:shop_id/carrier_options");
    e(this, "property", "carrier_options");
  }
}
class Ft extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postShopCarrierOptions");
    e(this, "path", "shops/:shop_id/carrier_options");
    e(this, "property", "carrier_options");
  }
}
class zt extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PUT");
    e(this, "name", "putShopCarrierOptions");
    e(this, "path", "carrier_management/shops/:shop_id/carrier_options");
    e(this, "property", "carrier_options");
  }
}
class jt extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "DELETE");
    e(this, "name", "deleteShopCarrierOption");
    e(this, "path", "shops/:shop_id/carrier_options/:id");
    e(this, "property", "carrier_options");
  }
}
class qt extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getShopStatistics");
    e(this, "path", "shops/:shop_id/statistics");
    e(this, "property", "id");
    e(this, "responseProperty", "shop_statistics");
  }
}
class Mt extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getShop");
    e(this, "path", "shops/:id");
    e(this, "property", "shops");
  }
}
class Wt extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postShop");
    e(this, "path", "shops/duplicate");
    e(this, "property", "shops");
    e(this, "responseProperty", "ids");
  }
}
class $t extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PUT");
    e(this, "name", "putShop");
    e(this, "path", "shops");
    e(this, "property", "shops");
  }
}
class Kt extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getSpecialInvoices");
    e(this, "path", "special_invoice_items");
    e(this, "property", "invoice_items");
  }
}
class Nt extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getStatisticsAffiliateCommission");
    e(this, "path", "statistics_affiliate_commission");
    e(this, "property", "statistics_affiliate_commission");
  }
}
class Jt extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getSubscriptionsCapabilities");
    e(this, "path", "subscriptions/capabilities");
    e(this, "property", "capabilities");
  }
}
class Vt extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "DELETE");
    e(this, "name", "deleteSubscription");
    e(this, "path", "subscriptions/:id");
    e(this, "property", "subscriptions");
  }
}
class Qt extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getSubscriptions");
    e(this, "path", "subscriptions");
    e(this, "property", "subscriptions");
  }
}
class Xt extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PATCH");
    e(this, "name", "patchSubscriptions");
    e(this, "path", "subscriptions");
    e(this, "property", "subscriptions");
    e(this, "responseProperty", "ids");
  }
}
class Yt extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postSubscriptions");
    e(this, "path", "subscriptions");
    e(this, "property", "subscriptions");
    e(this, "responseProperty", "ids");
  }
}
class Zt extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getSupportCase");
    e(this, "path", "support_cases/:id");
    e(this, "property", "support_cases");
  }
}
class es extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PUT");
    e(this, "name", "putSupportCase");
    e(this, "path", "support_cases");
    e(this, "property", "support_cases");
  }
}
class ts extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getSupportCaseAttachment");
    e(this, "path", "support_cases/:case_id/attachments/:attachment_id");
  }
}
class ss extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postSupportCaseComment");
    e(this, "path", "support_cases/:id/comments");
    e(this, "property", "comments");
  }
}
class ns extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getSupportCaseReturnReasons");
    e(this, "path", "return_shipment/:shipment_id/return_reasons");
    e(this, "property", "return_reasons");
  }
}
class rs extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getSystemCountryCodes");
    e(this, "path", "system_country_codes");
    e(this, "property", "countries");
  }
}
class as extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "DELETE");
    e(this, "name", "deleteSystemMessages");
    e(this, "path", "system_messages/:id");
    e(this, "property", "messages");
  }
}
class os extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PUT");
    e(this, "name", "putSystemMessages");
    e(this, "path", "system_messages");
    e(this, "property", "messages");
  }
}
class is extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postSystemMessages");
    e(this, "path", "system_messages");
    e(this, "property", "messages");
  }
}
class ps extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postTicket");
    e(this, "path", "tickets");
    e(this, "property", "tickets");
  }
}
class cs extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postTicketAttachment");
    e(this, "path", "tickets/:case_identifier/attachment");
    e(this, "property", "file");
  }
}
class ds extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getTrackAndTrace");
    e(this, "path", "tracktraces/:shipment_id");
    e(this, "property", "tracktraces");
  }
}
class ls extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getUiSettings");
    e(this, "path", "ui_settings/:account_ids");
    e(this, "property", "ui_settings");
  }
}
class ms extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postUiSettings");
    e(this, "path", "ui_settings");
    e(this, "property", "ui_settings");
  }
}
class hs extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PUT");
    e(this, "name", "putUiSettings");
    e(this, "path", "ui_settings");
    e(this, "property", "ui_settings");
  }
}
class us extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "DELETE");
    e(this, "name", "deleteUser");
    e(this, "path", "users/:id");
    e(this, "property", "users");
  }
}
class gs extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getUser");
    e(this, "path", "users/:id");
    e(this, "property", "users");
  }
}
class _s extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getUsers");
    e(this, "path", "users");
    e(this, "property", "users");
  }
}
class Ps extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postUsers");
    e(this, "path", "users");
    e(this, "property", "users");
  }
}
class xs extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PUT");
    e(this, "name", "putUsers");
    e(this, "path", "users");
    e(this, "property", "users");
  }
}
class ys extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postSendVerificationEmail");
    e(this, "path", "send_verification_email");
    e(this, "property", "send_verification_email");
  }
}
class Ss extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "DELETE");
    e(this, "name", "deleteWebhookSubscriptions");
    e(this, "path", "webhook_subscriptions/:ids");
    e(this, "property", "webhook_subscriptions");
  }
}
class ws extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getWebhookSubscriptions");
    e(this, "path", "webhook_subscriptions");
    e(this, "property", "webhook_subscriptions");
  }
}
class vs extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postWebhookSubscriptions");
    e(this, "path", "webhook_subscriptions");
    e(this, "property", "webhook_subscriptions");
    e(this, "responseProperty", "ids");
  }
}
class Es extends p {
  constructor() {
    super(...arguments);
    e(this, "name", "getBranding");
    e(this, "path", "consumer_portal/branding");
    e(this, "property", "branding");
  }
}
class fs extends p {
  constructor() {
    super(...arguments);
    e(this, "name", "getFields");
    e(this, "path", "consumer_portal/fields");
    e(this, "property", "fields");
  }
}
class Ds extends p {
  constructor() {
    super(...arguments);
    e(this, "name", "checkPostalCode");
    e(this, "path", "postal_code/check");
    e(this, "property", "postal_code");
  }
}
class Cs extends p {
  constructor() {
    super(...arguments);
    e(this, "name", "getSocialShippingLinks");
    e(this, "path", "consumer_portal/social_shipping_links/:uuid");
    e(this, "property", "social_shipping_links");
  }
}
const bs = [new H()], Ts = [new L(), new F()], As = [new z(), new j()], ks = [new q(), new M()], Is = [
  new W(),
  new $(),
  new K()
], Os = [new N()], Gs = [new J(), new V(), new Q()], Rs = [new X()], Us = [new Y()], Bs = [new Z()], Hs = [
  new ee(),
  new te(),
  new se(),
  new ne(),
  new re(),
  new ae()
], Ls = [new oe()], Fs = [new ie(), new pe()], zs = [new ce()], js = [new de()], qs = [new le()], Ms = [new me(), new he()], Ws = [new ue(), new ge()], $s = [
  new _e(),
  new Pe(),
  new xe(),
  new ye()
], Ks = [new Se(), new we()], Ns = [
  new Ce(),
  new ve(),
  new Ee(),
  new fe(),
  new De()
], Js = [new be()], Vs = [new Te(), new Ae()], Qs = [
  new Qe(),
  new ke(),
  new Ie(),
  new Oe(),
  new Ge(),
  new Be(),
  new Re(),
  new Ue(),
  new He(),
  new ze(),
  new Le(),
  new Fe(),
  new je(),
  new We(),
  new qe(),
  new $e(),
  new Me(),
  new Je(),
  new Ke(),
  new Ne(),
  new Ve(),
  new Ze(),
  new Xe(),
  new Ye(),
  new et()
], Xs = [new ct()], Ys = [new lt(), new dt()], Zs = [new mt(), new ut(), new ht()], en = [
  new gt(),
  new _t(),
  new yt(),
  new xt(),
  new Pt(),
  new St()
], tn = [new wt()], sn = [new vt(), new Et()], nn = [new ft()], rn = [new Dt()], an = [new Ct()];
class on extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getReturnReasons");
    e(this, "path", "return_reasons");
    e(this, "property", "return_reasons");
  }
}
const pn = [new on()], cn = [new bt()];
class dn extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "PATCH");
    e(this, "name", "patchShipments");
    e(this, "path", "shipments");
    e(this, "property", "shipments");
  }
  /* c8 ignore start */
  getHeaders() {
    return {
      ...super.getHeaders(),
      "Content-Type": "application/vnd.shipment+json;charset=utf-8;version=1.1"
    };
  }
  /* c8 ignore stop */
}
const ln = [new Tt(), new At(), new dn(), new kt()], mn = [
  new Ut(),
  new It(),
  new Ot(),
  new Gt(),
  new Rt()
], hn = [new Bt(), new Ht()], un = [
  new Lt(),
  new Ft(),
  new zt(),
  new jt()
], gn = [new qt()], S = [new Mt(), new Wt(), new $t()], _n = [new Kt()], Pn = [new Nt()], xn = [new Jt()], yn = [
  new Vt(),
  new Qt(),
  new Xt(),
  new Yt()
], Sn = [new Zt(), new es()], wn = [new ts()], vn = [new ss()], En = [new ns()], fn = [new rs()];
class Dn extends n {
  constructor() {
    super(...arguments);
    e(this, "method", "GET");
    e(this, "name", "getSystemMessage");
    e(this, "path", "system_messages/:id");
    e(this, "property", "messages");
  }
}
class Cn extends n {
  constructor() {
    super(...arguments);
    e(this, "name", "getSystemMessages");
    e(this, "path", "system_messages");
    e(this, "property", "messages");
  }
}
const bn = [
  new Dn(),
  new Cn(),
  new as(),
  new os(),
  new is()
], Tn = [new ps()], An = [new cs()], kn = [new ds()], In = [new ls(), new ms(), new hs()], On = [new gs(), new _s(), new us(), new Ps(), new xs()], Gn = [new ys()], Rn = [
  new Ss(),
  new ws(),
  new vs()
], Un = [
  ...Gs,
  ...Rn,
  ...$s,
  ...yn,
  ...xn,
  ...fn,
  ...ln,
  ...ks,
  ...tn,
  ...Fs,
  ...kn,
  ...bs,
  ...Ts,
  ...As,
  ...Is,
  ...Rs,
  ...Os,
  ...Us,
  ...Hs,
  ...Ls,
  ...zs,
  ...js,
  ...qs,
  ...Ms,
  ...Ws,
  ...Ks,
  ...Js,
  ...Ns,
  ...Vs,
  ...Qs,
  ...Xs,
  ...Ys,
  ...Zs,
  ...en,
  ...sn,
  ...nn,
  ...rn,
  ...cn,
  ...mn,
  ...S,
  ...hn,
  ...un,
  ...gn,
  ..._n,
  ...wn,
  ...vn,
  ...En,
  ...Sn,
  ...bn,
  ...An,
  ...Tn,
  ...In,
  ...On,
  ...Gn,
  ...an,
  ...Pn,
  ...Bs,
  ...pt,
  ...S,
  ...pn
], Bn = Un;
let l;
function Hn() {
  return {
    // Do we always want to have issues with sdk scope?
    sdk: l,
    initSdk: (t, r) => {
      if (l)
        return l;
      const a = localStorage.getItem("x-dmp-feature-flags"), o = {
        baseUrl: r,
        headers: {
          Authorization: `Bearer ${t}`,
          ...a ? { "x-dmp-feature-flags": a } : {},
          /* c8 ignore next - This cannot be tested because it will be handled by the browser. */
          referer: () => window.location.origin
        },
        options: {
          timeout: 8e3
        }
      };
      return l = U(new w(o), Bn), l;
    },
    resetSdk: () => {
      l = void 0;
    }
  };
}
class Ln extends p {
  constructor() {
    super(...arguments);
    e(this, "name", "getPaymentStatus");
    e(this, "path", "/billing/payment_status/:hash");
    e(this, "property", "payment_status");
  }
}
const Fn = [
  new Ln()
], zn = [
  new Es()
], jn = [new fs()], qn = [new Ds()];
class Mn extends p {
  constructor() {
    super(...arguments);
    e(this, "name", "getReturnReasonSettings");
    e(this, "path", "consumer_portal/return_reason_settings");
    e(this, "property", "return_reason_settings");
  }
}
const Wn = [
  new Mn()
];
class $n extends p {
  constructor() {
    super(...arguments);
    e(this, "name", "getReturnShipment");
    e(this, "path", "return_shipment/:hash");
    e(this, "property", "return_shipments");
  }
}
class Kn extends p {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postReturnShipmentLabel");
    e(this, "path", "return_shipment/email_label");
    e(this, "property", "return_shipments");
  }
}
class Nn extends p {
  constructor() {
    super(...arguments);
    e(this, "name", "getReturnShipmentReturnReason");
    e(this, "path", "return_shipment/:hash/return_reasons");
    e(this, "property", "return_reasons");
  }
}
class Jn extends p {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postReturnShipmentReturnComment");
    e(this, "path", "return_shipment/:hash/return_comment");
    e(this, "property", "return_comments");
  }
}
class Vn extends p {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postReturnShipmentReturnReasons");
    e(this, "path", "return_shipment/:hash/return_reasons");
    e(this, "property", "return_reasons");
  }
}
const Qn = [
  new $n(),
  new Kn(),
  new Vn(),
  new Jn(),
  new Nn()
];
class Xn extends p {
  constructor() {
    super(...arguments);
    e(this, "method", "POST");
    e(this, "name", "postReturnShipments");
    e(this, "path", "consumer_portal/return_shipments");
    e(this, "property", "return_shipments");
  }
}
const Yn = [
  new Xn()
], Zn = [
  new Cs()
];
class er extends p {
  constructor() {
    super(...arguments);
    e(this, "name", "getTrackTraces");
    e(this, "path", "tracktraces");
    e(this, "property", "tracktraces");
  }
}
const tr = [
  new er()
], sr = [
  ...zn,
  ...jn,
  ...tr,
  ...qn,
  ...Yn,
  ...Qn,
  ...Fn,
  ...Wn,
  ...Zn
];
let m;
function nr() {
  return {
    sdk: m,
    initSdk: (t) => {
      if (m)
        return m;
      const r = localStorage.getItem("x-dmp-feature-flags"), a = {
        baseUrl: t,
        headers: {
          "x-dmp-feature-flags": localStorage.getItem("x-dmp-feature-flags") || "",
          ...r ? { "x-dmp-feature-flags": r } : {},
          /* c8 ignore next - This cannot be tested because it will be handled by the browser. */
          referer: () => window.location.origin
        },
        options: {
          timeout: 8e3
        }
      };
      return m = B(new w(a), sr), m;
    },
    resetSdk: () => {
      m = void 0;
    }
  };
}
const f = "test-url", rr = "test-token", or = () => {
  const { initSdk: s } = Hn();
  return s(rr, f);
}, ir = () => {
  const { initSdk: s } = nr();
  return s(f);
}, pr = {
  IDLE: "idle",
  PENDING: "pending",
  RESOLVED: "resolved",
  REJECTED: "rejected",
  Idle: "idle",
  Pending: "pending",
  Resolved: "resolved",
  Rejected: "rejected"
};
export {
  j as DeleteAccountCarrierOption,
  q as DeleteAccountMessage,
  W as DeleteAccountProductPrices,
  ee as DeleteAddress,
  te as DeleteAddresses,
  he as DeleteBrandingBannerImage,
  _e as DeleteCarrierOptions,
  Ce as DeleteDimensionsPresets,
  ke as DeleteExternalIntegrationsProviderCredentialsAmazon,
  Be as DeleteExternalIntegrationsProviderCredentialsBol,
  ze as DeleteExternalIntegrationsProviderCredentialsEtsy,
  We as DeleteExternalIntegrationsProviderCredentialsExact,
  Je as DeleteExternalIntegrationsProviderCredentialsExactDe,
  Ze as DeleteExternalIntegrationsProviderCredentialsWix,
  Ut as DeleteShippingRule,
  jt as DeleteShopCarrierOption,
  Vt as DeleteSubscription,
  as as DeleteSystemMessages,
  us as DeleteUser,
  Ss as DeleteWebhookSubscriptions,
  pt as FulfilmentDomain,
  J as GetAccount,
  H as GetAccountActiveBrackets,
  L as GetAccountBrackets,
  M as GetAccountMessages,
  $ as GetAccountProductPrices,
  N as GetAccountSupportCases,
  V as GetAccounts,
  Y as GetAcl,
  Z as GetAclRolesCustomer,
  se as GetAddress,
  ne as GetAddresses,
  oe as GetAnalyticsDashboards,
  ie as GetApiKeys,
  ce as GetBaseProductPrices,
  de as GetBracketRanges,
  le as GetBrackets,
  Es as GetBranding,
  ue as GetCarrierConfigurations,
  Pe as GetCarrierOptions,
  Se as GetConsumerPortalFields,
  ve as GetDimensionsPresets,
  be as GetDropOffPoints,
  Te as GetEmails,
  Ie as GetExternalIntegrationsProviderCredentialsAmazon,
  Re as GetExternalIntegrationsProviderCredentialsBol,
  Le as GetExternalIntegrationsProviderCredentialsEtsy,
  qe as GetExternalIntegrationsProviderCredentialsExact,
  Ke as GetExternalIntegrationsProviderCredentialsExactDe,
  Xe as GetExternalIntegrationsProviderCredentialsWix,
  fs as GetFields,
  ct as GetImplications,
  lt as GetInterimInvoices,
  mt as GetInvitations,
  xt as GetInvoiceCsvAttachment,
  yt as GetInvoiceDetailAttachment,
  Pt as GetInvoiceDetailLegacyAttachment,
  _t as GetInvoiceDetails,
  St as GetInvoiceShortAttachment,
  gt as GetInvoices,
  wt as GetLocations,
  vt as GetPackagings,
  Dt as GetPostalCode,
  Ds as GetPostalCodePublic,
  Ct as GetPriceScaleBrackets,
  Tt as GetShipment,
  At as GetShipments,
  It as GetShippingRules,
  Mt as GetShop,
  Bt as GetShopAccess,
  Lt as GetShopCarrierOptions,
  qt as GetShopStatistics,
  Cs as GetSocialShippingLinks,
  Kt as GetSpecialInvoices,
  Nt as GetStatisticsAffiliateCommission,
  Qt as GetSubscriptions,
  Jt as GetSubscriptionsCapabilities,
  Zt as GetSupportCase,
  ts as GetSupportCaseAttachment,
  ns as GetSupportCaseReturnReasons,
  rs as GetSystemCountryCodes,
  ds as GetTrackAndTrace,
  ls as GetUiSettings,
  gs as GetUser,
  _s as GetUsers,
  ws as GetWebhookSubscriptions,
  Ee as PatchDimensionsPresets,
  Ot as PatchShippingRule,
  Xt as PatchSubscriptions,
  z as PostAccountCarrierOptions,
  K as PostAccountProductPrices,
  X as PostAccountsAnonymize,
  re as PostAddress,
  pe as PostApiKeys,
  me as PostBrandingBannerImage,
  ge as PostCarrierConfiguration,
  xe as PostCarrierOptions,
  fe as PostDimensionsPresets,
  Qe as PostExternalIntegration,
  Oe as PostExternalIntegrationsProviderCredentialsAmazon,
  Ue as PostExternalIntegrationsProviderCredentialsBol,
  Fe as PostExternalIntegrationsProviderCredentialsEtsy,
  Me as PostExternalIntegrationsProviderCredentialsExact,
  Ne as PostExternalIntegrationsProviderCredentialsExactDe,
  Ye as PostExternalIntegrationsProviderCredentialsWix,
  tt as PostFulfilmentDisable,
  st as PostFulfilmentDisableAutosplit,
  nt as PostFulfilmentDisablePicqer,
  rt as PostFulfilmentEnable,
  at as PostFulfilmentEnableAutosplit,
  ot as PostFulfilmentEnablePicqer,
  it as PostFulfilmentMode,
  dt as PostInterimInvoices,
  ht as PostInvitation,
  Et as PostPackaging,
  ft as PostPaymentInstructions,
  ys as PostSendVerificationEmail,
  bt as PostShipmentCarrierPrice,
  kt as PostShipments,
  Gt as PostShippingRule,
  Wt as PostShop,
  Ht as PostShopAccess,
  Ft as PostShopCarrierOptions,
  Yt as PostSubscriptions,
  ss as PostSupportCaseComment,
  is as PostSystemMessages,
  ps as PostTicket,
  cs as PostTicketAttachment,
  ms as PostUiSettings,
  Ps as PostUsers,
  vs as PostWebhookSubscriptions,
  Q as PutAccount,
  F as PutAccountBrackets,
  ae as PutAddress,
  ye as PutCarrierOptions,
  we as PutConsumerPortalFields,
  De as PutDimensionsPresets,
  Ae as PutEmail,
  Ge as PutExternalIntegrationsProviderCredentialsAmazon,
  He as PutExternalIntegrationsProviderCredentialsBol,
  je as PutExternalIntegrationsProviderCredentialsEtsy,
  $e as PutExternalIntegrationsProviderCredentialsExact,
  Ve as PutExternalIntegrationsProviderCredentialsExactDe,
  et as PutExternalIntegrationsProviderCredentialsWix,
  ut as PutInvitation,
  Rt as PutShippingRule,
  $t as PutShop,
  zt as PutShopCarrierOptions,
  es as PutSupportCase,
  os as PutSystemMessages,
  hs as PutUiSettings,
  xs as PutUsers,
  pr as Status,
  rr as TestToken,
  f as TestUrl,
  Un as domains,
  Bn as privateSdkDomains,
  ir as testPublicSdk,
  or as testSdk,
  nr as usePublicSdk,
  Hn as useSdk
};
