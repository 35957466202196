<template>
  <div
    id="language-toggle"
    class="flex flex-col items-center">
    <div
      ref="menuRef"
      class="flex justify-center language-toggle-wrapper w-full z-[5]"
      tabindex="-1"
      @blur="onBlur">
      <div
        v-show="menuIsVisible || isSidebarCollapsed"
        :class="{
          'absolute bg-white border bottom-16 drop-shadow-lg language-toggle--menu mb-4 py-4 rounded-lg':
            !isSidebarCollapsed,
        }"
        :aria-label="$t('language.toggleLanguageMenu')"
        :aria-hidden="!menuIsVisible">
        <ul>
          <li
            v-for="(language, index) in languages"
            :key="index"
            class="hover:bg-gray-200 language-toggle--menu--item">
            <a
              href="#"
              class="flex gap-3 pl-5 pr-4 py-2.5"
              :aria-label="language.name"
              @mouseup="changeLanguage(language.code)"
              @mousedown="preventBlur">
              <FlagIcon
                :country="language.countryCode"
                :class="{
                  'mt-0.5': isSidebarCollapsed,
                  'mt-1': !isSidebarCollapsed,
                }" />
              <span class="flex-1 text">{{ language.name }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <button
      v-if="!isSidebarCollapsed"
      type="button"
      class="border duration-150 flex gap-2 hover:bg-gray-200 items-center language-toggle--button pr-3 px-3.5 py-1.5 rounded-full transition-all"
      :aria-label="$t('language.toggleLanguageButton')"
      @mouseup="toggleMenuVisibility"
      @mousedown="preventBlur">
      <FlagIcon
        :key="translate('meta.flag')"
        :country="translate('meta.flag')" />
      {{ $t('meta.shortName') }}
    </button>
  </div>
</template>

<script setup lang="ts">
import {IconBase, FlagIcon} from '@myparcel-frontend/ui';
import {ref} from 'vue';
import {useI18n} from 'vue-i18n';
import useUiSettings from '@/composables/useUiSettings';
import {getLanguages} from '@/utils/getLanguages';

const {config} = useUiSettings();
const {t: translate, availableLocales, locale} = useI18n({useScope: 'global'});

const menuRef = ref(null);
const menuIsVisible = ref(false);

defineProps<{isSidebarCollapsed?: boolean}>();

const toggleMenuVisibility = (event?: MouseEvent, to?: boolean): void => {
  menuIsVisible.value = to === undefined ? !menuIsVisible.value : to;

  if (menuIsVisible.value) {
    menuRef.value.focus();
  }
};

const changeLanguage = (languageCode: string): void => {
  locale.value = languageCode;

  // put languageCode in localStorage with key 'language', to persist the language setting
  localStorage.setItem('language', languageCode);
  toggleMenuVisibility();
};

const onBlur = (): void => toggleMenuVisibility(undefined, false);
const preventBlur = (event: MouseEvent): void => event.preventDefault();

const languages = getLanguages(
  translate,
  availableLocales,
  config.value.supported_languages,
  config.value.default_language,
);
</script>

<style lang="scss">
.language-toggle--menu {
  &:before {
    transform: translateX(-50%) rotate(-225deg);
    top: unset;
    bottom: -0.5rem;
    display: block;
    position: absolute;
    content: '';
    left: 50%;
    border: 0.5rem solid;
    border-color: var(--color-gray-100) var(--color-gray-100) transparent transparent;
    transform-origin: 50% 50%;
  }
}
</style>
