<template>
  <Button
    class="cursor-pointer hidden lg:block m-0"
    :aria-expanded="!isSidebarCollapsed"
    :aria-controls="isSidebarCollapsed ? 'aria-side-menu-collapsed' : 'aria-side-menu'"
    data-test="collapsible-side-menu-toggle"
    @click="isSidebarCollapsed ? expandSidebar() : collapseSidebar()">
    <IconBase :size="IconSize.Medium">
      <template v-if="isSidebarCollapsed">
        <title>{{ $t('navigation.open') }}</title>
        <SideMenuShow />
      </template>
      <template v-else>
        <title>{{ $t('navigation.close') }}</title>
        <SideMenuHide />
      </template>
    </IconBase>
  </Button>
</template>

<script setup lang="ts">
import {IconBase, SideMenuHide, SideMenuShow, IconSize} from '@myparcel-frontend/ui';
import Button from '@/components/ui/Button';
import useSidebarToggle from '@/composables/useSidebarToggle';

const {collapseSidebar, isSidebarCollapsed, expandSidebar} = useSidebarToggle();
</script>
