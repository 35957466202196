import type {Features} from '@/types/features.types';
import {defineStore} from 'pinia';
import {reactive, readonly} from 'vue';

export const useFeaturesStore = defineStore('features', () => {
  const features = reactive({} as Features);

  const updateFeature = (feature: keyof Features, value: boolean) => {
    features[feature] = value;
  };

  return {
    features: readonly(features),
    updateFeature,
  };
});
