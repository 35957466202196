import Body from './Body.vue';
import Container from './Container.vue';
import Footer from './Footer.vue';
import Header from './Header.vue';
import Modal from './Modal.vue';

Modal.Header = Header;
Modal.Footer = Footer;
Modal.Body = Body;
Modal.Container = Container;

export default Modal;

export * from './Modal.types';
