import type {RouteComponent, RouteRecordRaw} from 'vue-router';
import {SystemMessageType} from '@myparcel-frontend/types';
import useAccount from '@/composables/useAccounts';
import useAnalyticsDashboards from '@/composables/useAnalyticsDashboards';

export default (): RouteRecordRaw[] => {
  const hasOrderModeEnabled = () => {
    const {hasOrderMode} = useAccount();
    return hasOrderMode;
  };

  return [
    {
      path: '/auth/connect/:provider/:version?',
      name: 'connect-external-provider',
      component: (): RouteComponent => import('@/views/Connect/Connect.vue'),
      meta: {
        feature: 'page.auth.connect',
      },
    },
    {
      // @TODO - can we do this with an alias?
      path: '/integratie/:provider/:version?',
      name: 'integration-provider',
      redirect: {
        name: 'connect-external-provider',
      },
      meta: {
        feature: 'page.auth.connect',
      },
    },
    {
      path: '/',
      name: 'dashboard',
      component: (): RouteComponent => import('@/views/HelloWorld.vue'),
      meta: {
        feature: 'page.dashboard',
        permissions: ['checkUsername'],
        title: (t) => t('dashboard', 2),
        systemMessageType: SystemMessageType.DASHBOARD,
      },
      props: {
        message: 'lorum ipsum',
      },
    },
    {
      path: '/analytics',
      meta: {
        feature: 'page.analytics.index',
        permissions: ['getCumulioToken'],
        title: (t) => t('analytics.title'),
      },
      children: [
        {
          name: 'insights',
          path: '',
          meta: {
            feature: 'page.analytics.index',
          },
          redirect: {
            name: 'analytics-dashboard',
            params: {
              category: 'shipments',
            },
          },
        },
        {
          name: 'analytics-dashboard',
          path: ':category',
          meta: {
            feature: 'page.analytics.index',
          },
          props: true,
          component: (): RouteComponent => import('@/views/Analytics/Analytics.vue'),
          beforeEnter: async (to, from, next) => {
            const {activeAnalyticsDashboards, getAnalyticsDashboardCredentials} = useAnalyticsDashboards();
            await getAnalyticsDashboardCredentials();
            const hasCategory = activeAnalyticsDashboards.value.some(
              (dashboard) => dashboard.category === to.params.category,
            );

            if (hasCategory) {
              next();
              return;
            }

            // TODO - FE-1730 - add notification that the category is not available

            // if no category is found, redirect to the first available category
            if (activeAnalyticsDashboards.value.length) {
              next({
                name: 'analytics-dashboard',
                params: {
                  category: activeAnalyticsDashboards.value[0].category,
                },
              });
              return;
            }

            // if there is no category redirect to the dashboard
            next({
              name: 'dashboard',
            });
          },
        },
      ],
    },
    {
      path: '/addresses',
      meta: {
        feature: 'page.addresses.overview',
        permissions: ['selectAddress'],
        title: (t) => t('addressBook.title'),
      },
      children: [
        {
          name: 'addresses',
          path: '',
          component: (): RouteComponent => import('@/views/AddressBook/AddressBookOverview.vue'),
          meta: {
            feature: 'page.addresses.overview',
            title: (t) => t('addressBook.title'),
          },
        },
        {
          name: 'addresses-edit',
          path: ':id',
          props: true,
          component: (): RouteComponent => import('@/views/AddressBook/Edit/AddressBookEdit.vue'),
          meta: {
            feature: 'page.addresses.edit',
            title: (t) => t('addressBook.editAddress'),
          },
        },
        {
          name: 'addresses-create',
          path: 'create',
          component: (): RouteComponent => import('@/views/AddressBook/Create/AddressBookCreate.vue'),
          meta: {
            feature: 'page.addresses.create',
            title: (t) => t('addressBook.newAddress'),
          },
        },
        {
          name: 'addresses-create-multiple',
          path: 'import',
          component: (): RouteComponent => import('@/views/Empty.vue'),
          meta: {
            feature: 'page.addresses.import',
            title: (t) => t('importAddresses'),
          },
        },
      ],
    },
    {
      name: 'not-found-page',
      path: '/:catchAll(.*)',
      redirect: {
        name: 'dashboard',
      },
      meta: {
        feature: 'page.notFound',
      },
    },
    {
      path: '/products',
      children: [
        {
          name: 'products',
          path: '',
          component: () => import('@/views/Products/ProductOverview.vue'),
          meta: {
            feature: 'page.products.overview',
            title: (t) => t('product', 2),
          },
        },
        {
          name: 'products-new',
          path: 'new',
          component: () => import('@/views/Products/NewProduct.vue'),
          meta: {
            feature: 'page.products.new',
            title: (t) => t('products.new', 2),
          },
        },
      ],
    },
    {
      name: 'orders',
      path: '/orders',
      meta: {
        feature: 'page.orders.overview',
      },
      children: [
        {
          name: 'orders-overview',
          path: '',
          component: (): RouteComponent => import('@/views/Orders/OrdersOverview/OrdersOverview.vue'),
          meta: {
            feature: 'page.orders.overview',
            title: (t) => t('ordersOverview'),
            canGo: [hasOrderModeEnabled],
          },
        },
        {
          name: 'orders-import',
          path: 'import',
          component: (): RouteComponent => import('@/views/Orders/OrdersImport/OrdersImport.vue'),
          meta: {
            feature: 'page.orders.import',
          },
          children: [
            {
              name: 'orders-import-overview',
              path: '',
              components: {
                orderImport: () => import('@/views/Orders/OrdersImport/OrdersImportOverview/OrdersImportOverview.vue'),
              },
              meta: {
                feature: 'page.orders.import',
                title: (t) => t('ordersImport'),
                canGo: [hasOrderModeEnabled],
              },
            },
          ],
        },
      ],
    },
    {
      name: 'shipments',
      path: '/shipments',
      meta: {
        feature: 'page.shipments',
        permissions: ['selectShipment'],
        title: (t) => t('shipment', 2),
      },
      component: (): RouteComponent => import('@/views/Empty.vue'),
      children: [
        {
          name: 'shipments-create',
          path: 'create',
          component: (): RouteComponent => import('@/views/Empty.vue'),
          meta: {
            feature: 'page.shipments',
            permissions: ['insertShipment'],
            title: (t) => t('newShipment'),
          },
        },
        {
          name: 'shipments-import',
          path: 'import',
          component: (): RouteComponent => import('@/views/Empty.vue'),
          meta: {
            feature: 'page.shipments',
            permissions: ['insertShipment'],
            title: (t) => t('importShipments'),
          },
        },
      ],
    },
    {
      name: 'location-finder',
      path: '/shipments/retail-overview',
      component: (): RouteComponent => import('@/views/LocationFinder/LocationFinder.vue'),
      meta: {
        feature: 'page.locationFinder',
        title: (t) => t('locationFinder.title'),
      },
    },
    {
      name: 'haalservice',
      path: '/collect-trip',
      component: (): RouteComponent => import('@/views/Empty.vue'),
      meta: {
        feature: 'page.collectTrip',
        permissions: ['accessCollectTrip'],
        title: (t) => t('collectTrip'),
      },
    },
    {
      name: 'packagings',
      path: '/webshop/orders',
      component: (): RouteComponent => import('@/views/PackagingOverview/PackagingOverview.vue'),
      meta: {
        feature: 'page.webshop.orders.overview',
        permissions: ['accountSelectOrder'],
        title: (t) => t('packagings.title', 2),
      },
      children: [
        {
          name: 'packaging-overview-detail',
          path: ':id',
          props: true,
          components: {
            packagingOverview: () => import('@/views/PackagingOverview/PackagingOverviewDetail.vue'),
          },
          meta: {
            feature: 'page.webshop.orders.detail',
            permissions: ['accountSelectOrder'],
          },
        },
      ],
    },
    {
      name: 'invoices',
      path: '/invoices',
      component: (): RouteComponent => import('@/views/Invoices/InvoicesIndex.vue'),
      meta: {
        feature: 'page.invoices.overview',
        permissions: ['selectInvoice', 'getProformaInvoice'],
        title: (t) => t('invoice.title', 2),
      },
    },
    {
      name: 'invoice-upcoming',
      path: '/invoices/upcoming',
      component: (): RouteComponent => import('@/views/Financial/UpcomingInvoices/UpcomingInvoiceIndex.vue'),
      meta: {
        feature: 'page.invoices.upcoming',
        permissions: ['selectInvoiceItem'],
        title: (t) => t('invoice.upcomingInvoice'),
      },
    },
    {
      name: 'credit-debit',
      path: '/credit-debit',
      component: (): RouteComponent => import('@/views/Financial/ExceptionalPayments/ExceptionalPaymentsIndex.vue'),
      meta: {
        feature: 'page.creditDebit',
        permissions: ['selectInvoiceItem'],
        title: (t) => t('exceptionalPayments.title'),
      },
    },
    {
      path: '/prices',
      component: (): RouteComponent => import('@/views/ShippingRates/ShippingRatesIndex.vue'),
      meta: {
        feature: 'page.prices.shippingRates',
        permissions: ['readPriceAmounts'],
      },
      children: [
        {
          name: 'prices',
          path: '',
          components: {
            shippingRates: async () => import('@/views/ShippingRates/ShippingRates.vue'),
          },
          meta: {
            feature: 'page.prices.shippingRates',
            permissions: ['readPriceAmounts'],
            title: (t) => t('tariff', 2),
          },
        },
        {
          path: 'scale',
          meta: {
            feature: 'page.prices.scale.index',
          },
          children: [
            {
              path: '',
              name: 'prices-scale',
              redirect: {
                name: 'prices-scale-postnl',
              },
              meta: {
                feature: 'page.prices.scale.postnl',
              },
            },
            {
              name: 'prices-scale-postnl',
              path: 'postnl',
              components: {
                shippingRates: async () => import('@/views/ShippingRates/ScalesPostNl/ScalesPostNl.vue'),
              },
              meta: {
                feature: 'page.prices.scale.postnl',
                title: (t) => t('shippingRates.postnlRates'),
              },
            },
            {
              name: 'prices-scale-dhl',
              path: 'dhl',
              components: {
                shippingRates: async () => import('@/views/ShippingRates/ScalesDhl/ScalesDhl.vue'),
              },
              meta: {
                feature: 'page.prices.scale.dhl',
                title: (t) => t('shippingRates.dhlRates'),
              },
            },
            {
              name: 'prices-scale-dpd',
              path: 'dpd',
              components: {
                shippingRates: async () => import('@/views/ShippingRates/ScalesDpd/ScalesDpd.vue'),
              },
              meta: {
                feature: 'page.prices.scale.dpd',
                title: (t) => t('shippingRates.dpdRates'),
              },
            },
          ],
        },
      ],
    },
    {
      name: 'contact',
      path: '/contact',
      component: (): RouteComponent => import('@/views/Contact/Contact.vue'),
      meta: {
        feature: 'page.contact',
        permissions: ['ticketCreate'],
        title: (t) => t('contact.title'),
      },
    },
    {
      path: '/cases',
      meta: {
        feature: 'page.cases.active',
        permissions: ['selectSupportCase'],
      },
      children: [
        {
          name: 'cases-index',
          path: '',
          component: (): RouteComponent => import('@/views/SupportCases/Index/SupportCaseIndex.vue'),
          meta: {
            feature: 'page.cases.index',
            systemMessageType: SystemMessageType.CASES_OVERVIEW,
          },
        },
        {
          name: 'cases-detail',
          path: ':id',
          props: true,
          component: (): RouteComponent => import('@/views/SupportCases/SupportCaseDetail.vue'),
          meta: {
            feature: 'page.cases.detail',
            permissions: ['selectSupportCase'],
            title: (t) => t('contact.caseSummary'),
          },
        },
      ],
    },
    {
      name: 'faq',
      path: '/support',
      component: (): RouteComponent => import('@/views/Empty.vue'),
      meta: {
        feature: 'page.faq',
        title: (t) => t('faq'),
      },
    },
    {
      path: '/tell-a-friend',
      meta: {
        feature: 'page.tellAFriend.index',
        permissions: ['selectAffiliate'],
      },
      children: [
        {
          path: '',
          name: 'tellafriend',
          component: (): RouteComponent => import('@/views/TellAFriend/TellAFriend.vue'),
          meta: {
            feature: 'page.tellAFriend.index',
            permissions: ['selectAffiliate'],
            title: (t) => t('tellAFriend.tellAFriend'),
          },
        },
        {
          name: 'tellafriend-invite',
          path: 'invite',
          component: (): RouteComponent => import('@/views/TellAFriend/TellAFriendInvite.vue'),
          meta: {
            feature: 'page.tellAFriend.invite',
            permissions: ['selectAffiliate'],
            title: (t) => t('tellAFriend.tellAFriend'),
          },
        },
        {
          name: 'tellafriend-edit',
          path: 'edit',
          component: (): RouteComponent => import('@/views/TellAFriend/TellAFriendDefault.vue'),
          meta: {
            feature: 'page.tellAFriend.edit',
            permissions: ['selectAffiliate'],
            title: (t) => t('tellAFriend.tellAFriend'),
          },
        },
      ],
    },
    {
      name: 'subscriptions.index',
      path: '/subscriptions',
      component: (): RouteComponent => import('@/views/Subscriptions/SubscriptionsIndex.vue'),
      meta: {
        permissions: ['selectSubscription'],
        title: (t) => t('subscriptions.title', 2),
        feature: 'page.subscriptions.overview',
      },
      children: [
        {
          path: '',
          name: 'subscription-overview',
          components: {
            subscriptions: async () => import('@/views/Subscriptions/Overview/Overview.vue'),
          },
          meta: {
            feature: 'page.subscriptions.overview',
          },
        },
        {
          path: 'packages',
          name: 'subscriptions-bundles',
          components: {
            subscriptions: async () => import('@/views/Subscriptions/Bundles/Bundles.vue'),
          },
          meta: {
            feature: 'page.subscriptions.bundles',
          },
        },
        {
          path: 'my-contracts',
          name: 'subscriptions-my-contracts',
          components: {
            subscriptions: async () => import('@/views/Subscriptions/MyContracts/MyContracts.vue'),
          },
          meta: {
            feature: 'page.subscriptions.myContracts',
          },
        },
        {
          path: 'my-analytics',
          name: 'subscriptions-my-analytics',
          components: {
            subscriptions: async () => import('@/views/Subscriptions/MyAnalytics/MyAnalytics.vue'),
          },
          meta: {
            feature: 'page.subscriptions.myAnalytics',
          },
        },
        {
          path: 'my-orders',
          name: 'subscriptions-my-orders',
          components: {
            subscriptions: async () => import('@/views/Subscriptions/MyOrders/MyOrders.vue'),
          },
          meta: {
            feature: 'page.subscriptions.myOrders',
          },
        },
      ],
    },
    {
      path: '/settings/:shopId',
      name: 'shop-settings',
      meta: {
        feature: 'page.settings.shop.general',
        permissions: ['selectApiKey', 'selectWebhookSubscription', 'updateShopGeneralSettings'],
        title: (t) => t('generalSettings'),
        titleBarHeader: 'shopSettings.shipments',
      },
      children: [
        {
          path: '',
          name: 'shop-settings-general',
          component: (): RouteComponent => import('@/views/Empty.vue'),
          meta: {
            feature: 'page.settings.shop.general',
            permissions: ['selectApiKey', 'selectWebhookSubscription', 'updateShopGeneralSettings'],
            title: (t) => t('generalSettings'),
          },
        },
        {
          path: 'orders',
          name: 'shop-settings-orders',
          component: (): RouteComponent => import('@/views/Settings/Shop/Order/OrderSettings.vue'),
          meta: {
            feature: 'page.settings.shop.orders',
            permissions: ['selectApiKey', 'selectWebhookSubscription', 'updateShopGeneralSettings'],
            title: (t) => t('shopSettings.orders'),
            titleBarHeader: 'shopSettings.orders',
          },
        },
        {
          path: 'shipment',
          name: 'shop-settings-shipment',
          component: (): RouteComponent => import('@/views/Settings/Shop/Shipment/ShipmentSettingsIndex.vue'),
          meta: {
            feature: 'page.settings.shop.shipment.index',
            permissions: ['selectApiKey', 'selectWebhookSubscription', 'updateShopGeneralSettings'],
            title: (t) => t('shipment', 2),
            titleBarHeader: 'shopSettings.shipments',
          },
          children: [
            {
              path: 'rules',
              meta: {
                feature: 'page.settings.shop.shipment.shippingRules.overview',
              },
              children: [
                {
                  path: '',
                  name: 'shop-settings-shipping-rules',
                  components: {
                    shipmentSettings: async () =>
                      import('@/views/Settings/Shop/Shipment/ShippingRules/ShippingRules.vue'),
                  },
                  meta: {
                    feature: 'page.settings.shop.shipment.shippingRules.overview',
                    title: (t) => t('shopSettings.shippingRules'),
                  },
                },
                {
                  path: 'create',
                  name: 'shop-settings-shipping-rules-create',
                  components: {
                    shipmentSettings: async () =>
                      import('@/views/Settings/Shop/Shipment/ShippingRules/ShippingRuleCreate/ShippingRuleCreate.vue'),
                  },
                  meta: {
                    feature: 'page.settings.shop.shipment.shippingRules.create',
                    title: (t) => t('shippingRules.create'),
                    titleBarHeader: 'shippingRules.create',
                    hasTabMenu: false,
                  },
                },
                {
                  path: ':ruleId',
                  name: 'shop-settings-shipping-rules-edit',
                  props: true,
                  components: {
                    shipmentSettings: async () =>
                      import('@/views/Settings/Shop/Shipment/ShippingRules/ShippingRuleEdit/ShippingRuleEdit.vue'),
                  },
                  meta: {
                    feature: 'page.settings.shop.shipment.shippingRules.edit',
                    title: (t) => t('shippingRules.edit'),
                    titleBarHeader: 'shippingRules.edit',
                    hasTabMenu: false,
                  },
                },
              ],
            },
            {
              path: 'carriers',
              name: 'shop-settings-carriers',
              components: {
                shipmentSettings: async () => import('@/views/Settings/Shop/Shipment/Carriers/Carriers.vue'),
              },
              meta: {
                feature: 'page.settings.shop.shipment.carriers',
              },
            },
            {
              path: 'dimensions-presets',
              name: 'shop-settings-dimensions-presets',
              components: {
                shipmentSettings: async () =>
                  import('@/views/Settings/Shop/Shipment/DimensionsPresets/DimensionsPresetsOverview.vue'),
              },
              meta: {
                feature: 'page.settings.shop.shipment.dimensionsPresets',
                title: (t) => t('shopSettings.dimensionsPresets.title'),
                hasTabMenu: true,
              },
            },
            {
              path: '',
              name: 'shop-settings-labels',
              components: {
                shipmentSettings: async () => import('@/views/Empty.vue'),
              },
              meta: {
                feature: 'page.settings.shop.shipment.labels',
              },
            },
          ],
        },
        {
          name: 'shop-settings-track-trace',
          path: 'track-and-trace',
          component: (): RouteComponent => import('@/views/Empty.vue'),
          meta: {
            feature: 'page.settings.shop.trackTrace',
            permissions: ['updateShopTrackTraceSettings'],
            title: (t) => t('trackTrace'),
          },
        },
        {
          name: 'shop-settings-returns-index',
          path: 'retour',
          component: (): RouteComponent => import('@/views/Settings/Shop/Returns/ReturnSettingsIndex.vue'),
          meta: {
            feature: 'page.settings.shop.returns.index',
            permissions: ['updateShopReturnSettings'],
            title: (t) => t('return', 2),
          },
          children: [
            {
              name: 'shop-settings-returns-portal',
              path: 'portal',
              component: (): RouteComponent => import('@/views/Settings/Shop/Returns/Portal/ReturnPortal.vue'),
              meta: {
                feature: 'page.settings.shop.returns.portal',
                permissions: ['updateShopReturnSettings'],
                titleBarHeader: 'shopSettings.title',
                title: (t) => t('return', 2),
              },
            },
            {
              name: 'shop-settings-returns-rules',
              path: 'rules',
              component: (): RouteComponent => import('@/views/Settings/Shop/Returns/Rules/Rules.vue'),
              meta: {
                feature: 'page.settings.shop.returns.rules',
                permissions: ['updateShopReturnSettings'],
                titleBarHeader: 'shopSettings.title',
                title: (t) => t('return', 2),
              },
            },
            {
              name: 'shop-settings-returns-rules-create',
              path: 'rules/create',
              props: true,
              component: (): RouteComponent => import('@/views/Settings/Shop/Returns/RulesCreate/RulesCreate.vue'),
              meta: {
                feature: 'page.settings.shop.returns.rules',
                permissions: ['updateShopReturnSettings'],
                titleBarHeader: 'returnRules.create',
                title: (t) => t('returnRules.create'),
                hasTabMenu: false,
              },
            },
            {
              name: 'shop-settings-returns-rules-edit',
              path: 'rules/:ruleId',
              props: true,
              component: (): RouteComponent => import('@/views/Settings/Shop/Returns/RulesEdit/RulesEdit.vue'),
              meta: {
                feature: 'page.settings.shop.returns.rules',
                permissions: ['updateShopReturnSettings'],
                titleBarHeader: 'returnRules.edit',
                title: (t) => t('returnRules.edit'),
                hasTabMenu: false,
              },
            },
          ],
        },
        {
          name: 'shop-settings-portal',
          path: 'portal-settings',
          component: (): RouteComponent => import('@/views/Settings/Shop/TrackAndTrace/PortalIndex.vue'),
          meta: {
            feature: 'page.settings.shop.portal',
            permissions: [
              'selectConsumerPortalFields',
              'insertConsumerPortalFields',
              'updateConsumerPortalFields',
              'checkConsumerPortalSubdomain',
            ],
            title: (t) => t('shopSettings.trackAndTracePage.title'),
          },
        },
        {
          name: 'shop-settings-company',
          path: 'contact',
          component: (): RouteComponent => import('@/views/Settings/Shop/Company/CompanyIndex.vue'),
          meta: {
            feature: 'page.settings.shop.company.index',
            permissions: ['updateShopBillingSettings', 'updateShopReturnAddress', 'updateShopDeliveryAddress'],
            title: (t) => t('companySettings'),
          },
          children: [
            {
              name: 'shop-settings-company-billing',
              path: 'contact-billing',
              component: (): RouteComponent => import('@/views/Empty.vue'),
              meta: {
                feature: 'page.settings.shop.company.billing',
                permissions: ['updateShopBillingSettings'],
              },
            },
            {
              name: 'shop-settings-company-return',
              path: 'return-address',
              component: (): RouteComponent => import('@/views/Empty.vue'),
              meta: {
                feature: 'page.settings.shop.company.return',
                permissions: ['updateShopReturnAddress'],
              },
            },
            {
              name: 'shop-settings-company-delivery',
              path: 'delivery-address',
              component: (): RouteComponent => import('@/views/Empty.vue'),
              meta: {
                feature: 'page.settings.shop.company.delivery',
                permissions: ['updateShopDeliveryAddress'],
              },
            },
            {
              name: 'shop-settings-company-bank',
              path: 'bank-details',
              component: (): RouteComponent => import('@/views/Empty.vue'),
              meta: {
                feature: 'page.settings.shop.company.bank',
                permissions: ['updateShopBillingSettings'],
              },
            },
          ],
        },
        {
          name: 'shop-settings-integration',
          path: 'integration',
          component: (): RouteComponent => import('@/views/Integrations/Integrations.vue'),
          meta: {
            feature: 'page.settings.shop.integrations',
            permissions: [
              'selectApiKey',
              'selectWebhookSubscription',
              'updateShopGeneralSettings',
              'updateExternalIntegrationProviderCredentials',
            ],
            provider: null,
            title: (t) => t('integration'),
          },
        },
        {
          path: 'sales-channels',
          children: [
            {
              name: 'sales-channels',
              props: true,
              path: '',
              component: (): RouteComponent =>
                import('@/views/SalesChannels/SalesChannelsIndex/SalesChannelsIndex.vue'),
              meta: {
                feature: 'page.salesChannels',
                title: (t) => t('salesChannels.title'),
              },
            },
            {
              name: 'sales-channels-create',
              path: 'create',
              props: true,
              component: (): RouteComponent =>
                import('@/views/SalesChannels/SalesChannelsCreate/SalesChannelsCreate.vue'),
              meta: {
                feature: 'page.salesChannels',
                title: (t) => t('salesChannels.create.title'),
              },
            },
            {
              name: 'sales-channels-edit',
              path: ':salesChannelId',
              props: true,
              component: (): RouteComponent => import('@/views/SalesChannels/SalesChannelsEdit/SalesChannelsEdit.vue'),
              meta: {
                feature: 'page.salesChannels',
                title: (t) => t('salesChannels.edit.title'),
              },
              children: [
                {
                  name: 'sales-channels-edit-settings',
                  path: '',
                  props: true,
                  component: (): RouteComponent =>
                    import(
                      '@/views/SalesChannels/SalesChannelsEdit/SalesChannelsEditSettings/SalesChannelsEditSettings.vue'
                    ),
                  meta: {
                    feature: 'page.salesChannels',
                    title: (t) => t('salesChannels.edit.title'),
                  },
                },
                {
                  name: 'sales-channels-edit-status',
                  path: 'status',
                  props: true,
                  component: (): RouteComponent =>
                    import(
                      '@/views/SalesChannels/SalesChannelsEdit/SalesChannelsEditStatus/SalesChannelsEditStatus.vue'
                    ),
                  meta: {
                    feature: 'page.salesChannels',
                    title: (t) => t('salesChannels.edit.title'),
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'account-settings',
      path: '/settings/account',
      meta: {
        feature: 'page.settings.account.index',
        title: (t) => t('accountSetting', 2),
      },
      component: (): RouteComponent => import('@/views/Settings/SettingsIndex.vue'),
      children: [
        {
          path: 'contact',
          name: 'account-settings-contact',
          component: (): RouteComponent => import('@/views/Empty.vue'),
          meta: {
            feature: 'page.settings.account.contact',
            permissions: ['selectApiKey', 'selectWebhookSubscription', 'updateShopGeneralSettings'],
            title: (t) => t('generalSettings'),
          },
        },
        {
          path: 'subscriptions',
          name: 'account-settings-subscriptions',
          redirect: {
            name: 'account-settings-my-contracts',
          },
          meta: {
            feature: 'page.settings.account.myContracts',
          },
        },
        {
          path: 'my-contracts',
          name: 'account-settings-my-contracts',
          components: {
            settings: async () => import('@/views/Settings/MyContractsSettings/MyContractsSettings.vue'),
          },
          meta: {
            feature: 'page.settings.account.myContracts',
            permissions: ['selectSubscription'],
            title: (t) => t('myContracts'),
          },
        },
        {
          path: 'orders-shipping',
          name: 'account-settings-orders-shipping',
          components: {
            settings: async () => import('@/views/Settings/Account/OrdersShipping/OrdersShipping.vue'),
          },
          meta: {
            feature: 'page.settings.account.ordersShipping',
            title: (t) => t('ordersShipping'),
          },
        },
        {
          path: 'shops',
          name: 'account-settings-shops',
          components: {
            settings: async () => import('@/views/Settings/Account/Shops/Shops.vue'),
          },
          meta: {
            feature: 'page.settings.account.shops',
            title: (t) => t('shops'),
          },
        },
        {
          path: 'users-security',
          meta: {
            feature: 'page.settings.account.usersSecurity.index',
          },
          children: [
            {
              path: '',
              name: 'account-settings-users-security',
              components: {
                settings: async () => import('@/views/Settings/Account/UsersSecurity/UsersSecurity.vue'),
              },
              meta: {
                feature: 'page.settings.account.usersSecurity.index',
                title: (t) => t('accountSettings.usersSecurity'),
              },
            },
            {
              path: 'create',
              name: 'account-settings-users-security-create-user',
              component: (): RouteComponent =>
                import('@/views/Settings/Account/UsersSecurity/CreateUser/CreateUser.vue'),
              meta: {
                feature: 'page.settings.account.usersSecurity.create',
                permissions: ['selectApiKey', 'selectWebhookSubscription', 'updateShopGeneralSettings'],
                title: (t) => t('accountSettings.createUser'),
              },
            },
            {
              path: ':id',
              props: true,
              name: 'account-settings-users-security-edit-user',
              component: () => import('@/views/Settings/Account/UsersSecurity/EditUser/EditUser.vue'),
              meta: {
                feature: 'page.settings.account.usersSecurity.edit',
                permissions: ['selectApiKey', 'selectWebhookSubscription', 'updateShopGeneralSettings'],
                title: (t) => t('accountSettings.editUser'),
              },
            },
          ],
        },
        {
          path: 'my-contact-info',
          name: 'account-settings-my-contact-info',
          components: {
            settings: async () => import('@/views/Settings/Account/MyContactInfo/MyContactInfo.vue'),
          },
          meta: {
            feature: 'page.settings.account.myContactInfo',
            title: (t) => t('accountSettings.myContactInfo'),
          },
        },
        {
          path: 'my-login-info',
          name: 'account-settings-my-login-info',
          components: {
            settings: async () => import('@/views/Settings/Account/MyLoginInfo/MyLoginInfo.vue'),
          },
          meta: {
            feature: 'page.settings.account.myLoginInfo',
            title: (t) => t('accountSettings.myLoginInfo'),
          },
        },
      ],
    },
    {
      name: 'users',
      path: '/users',
      component: (): RouteComponent => import('@/views/Empty.vue'),
      meta: {
        feature: 'page.users',
        permissions: ['insertUser', 'deleteUser', 'manageUsers'],
        title: (t) => t('user', 2),
      },
    },
    {
      name: 'redirect',
      path: '/redirect',
      component: (): RouteComponent => import('@/views/Empty.vue'),
      meta: {
        feature: 'page.redirect',
        auth: false,
        title: (t) => t('redirect'),
      },
    },
  ];
};
