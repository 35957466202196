import {SubscriptionStatus} from '@/types/subscriptions';

export const isSubscriptionPending = (status: SubscriptionStatus): boolean => status === SubscriptionStatus.Pending;

export const isSubscriptionStartingSoon = (status: SubscriptionStatus): boolean =>
  status === SubscriptionStatus.StartingSoon;

export const isSubscriptionTrialActive = (status: SubscriptionStatus): boolean =>
  status === SubscriptionStatus.TrialActive;

export const isSubscriptionTrialEnded = (status: SubscriptionStatus): boolean =>
  status === SubscriptionStatus.TrialEnded;

export const isSubscriptionEnded = (status: SubscriptionStatus): boolean => status === SubscriptionStatus.Ended;

export const isSubscriptionEndingSoon = (status: SubscriptionStatus): boolean =>
  status === SubscriptionStatus.EndingSoon;

export const isSubscriptionActive = (status: SubscriptionStatus): boolean => status === SubscriptionStatus.Active;
