import type {Ref} from 'vue';
import {ref, watch} from 'vue';
import {useModal} from './useModal';
import {isHtmlElement} from '@/types/typeGuards';

interface UseModalFocus {
  modalRef: Ref<HTMLElement | null>;
}

export function useModalFocus(name: string): UseModalFocus {
  const modalRef = ref<HTMLElement | null>(null);
  const restoreFocusTo = ref();

  const {isModalOpen} = useModal(name);

  const getFocusableElements = (): HTMLElement[] => {
    // This should never happen, this method will only fire if the modal is open, however we need to check for it for type safety
    /* c8 ignore start */
    if (!modalRef.value) {
      return [];
    }
    /* c8 ignore stop */

    return Array.from(
      modalRef.value.querySelectorAll(
        'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select',
      ),
    );
  };

  const focusFirstElement = () => {
    if (!modalRef.value) {
      return;
    }

    const focusableElements = modalRef.value.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select',
    );

    // Get the currently focused element
    const focusedElement = document.activeElement;

    // Store it so that we can return focus to it when the modal closes
    if (focusedElement && focusedElement !== focusableElements[0]) {
      restoreFocusTo.value = focusedElement;
    }

    // Focus the first focusable element in the modal
    if (focusableElements.length > 0 && isHtmlElement(focusableElements[0])) {
      focusableElements[0].focus();
    }
  };

  // A function that ensures focus stays within the modal
  // when the user tabs through it.
  const trapFocus = (event: KeyboardEvent) => {
    const focusableElements = getFocusableElements();

    if (focusableElements.length === 0) {
      return;
    }

    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement = focusableElements[focusableElements.length - 1];

    if (event.key === 'Tab') {
      if (event.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          event.preventDefault();
          lastFocusableElement.focus();
        }
      } else if (document.activeElement === lastFocusableElement) {
        event.preventDefault();
        firstFocusableElement.focus();
      }
    }
  };

  watch([isModalOpen, modalRef], () => {
    if (isModalOpen.value) {
      focusFirstElement();
    }
  });

  watch([isModalOpen, restoreFocusTo], () => {
    if (!isModalOpen.value && restoreFocusTo.value) {
      restoreFocusTo.value.focus();
    }
  });

  watch([modalRef, isModalOpen], () => {
    if (modalRef.value) {
      if (isModalOpen.value) {
        // We first remove the event listener to make sure we don't add multiple event listeners
        document.removeEventListener('keydown', trapFocus);
        document.addEventListener('keydown', trapFocus);
      }
    } else {
      document.removeEventListener('keydown', trapFocus);
    }
  });

  return {
    modalRef,
  };
}
