import type {CarrierOption, UpdateCarrierOption} from '@/types/CarrierOption';
import type {CarrierId, CarrierName} from '@myparcel/constants';
import type {Ref} from 'vue';
import {useSdk, Status} from '@myparcel-frontend/sdk';
import {storeToRefs} from 'pinia';
import {computed, ref} from 'vue';
import useAccount from '@/composables/useAccounts';
import {useCarrierStore} from '@/store/carrierStore/carrierStore';
import {ContractType} from '@/types/CarrierOption';
import {hasMainContract} from '@/utils/carriers';

export interface UseCarriers {
  allContractsInStore: (shopId?: number) => CarrierOption[];
  getAccountCarriers: () => Promise<Readonly<Ref<CarrierOption[]>>>;
  updateAccountCarrierOptions: () => Promise<void>;
  postAccountCarrierOptions: (carrierOption: UpdateCarrierOption) => Promise<void>;
  deleteAccountCarrierOption: (contractId: number) => Promise<void>;
  isCustomContract: (contractId: number) => boolean;
  isMainContract: (contractId: number) => boolean;
  getAccountContractIdForCarrierId: (carrierId: CarrierId) => number | undefined;
  getCarrierIdForContractId: (contractId: number) => CarrierId | undefined;
  getCarrierName: (contractId: number) => CarrierName | undefined;
  customAccountCarrierOptions: Readonly<Ref<CarrierOption[]>>;
  mainAccountCarrierOptions: Readonly<Ref<CarrierOption[]>>;
  updateAccountCarrierOption: (oldValue: CarrierOption, newValue: UpdateCarrierOption) => Promise<void>;
  fetchShopCarrierOptions: () => Promise<void>;
  getShopCarrierOptions: () => Promise<Ref<CarrierOption[]>>;
  postShopCarrierOption: (carrierOption: UpdateCarrierOption) => Promise<void>;
  updateShopCarrierOption: (oldValue: CarrierOption, newValue: UpdateCarrierOption) => Promise<void>;
  setShopCarrierOptionEnabledState: (carrierOption: Partial<CarrierOption>) => Promise<void>;
  deleteShopCarrierOption: (contractId: number) => Promise<void>;
  customShopCarrierOptions: Readonly<Ref<CarrierOption[]>>;
  uniqueShopContracts: Readonly<Ref<CarrierOption[]>>;
  shopAccountContracts: Readonly<Ref<CarrierOption[]>>;
  accountCarriers: Ref<CarrierOption[]>;
}

interface UseCarriersPrivate {
  status: Ref<typeof Status>;
  fetchAccountCarriers: () => Promise<CarrierOption[]>;
}

export default function useCarriers(): UseCarriers {
  const status = ref(Status.IDLE);
  const {currentShop, getAccount} = useAccount();
  const carrierStore = useCarrierStore();
  const {accountCarriers} = storeToRefs(carrierStore);
  const {sdk} = useSdk();

  const fetchAccountCarriers: UseCarriersPrivate['fetchAccountCarriers'] = async () => {
    const {sdk} = useSdk();
    const mainAccount = await getAccount();
    return sdk.getCarrierOptions({path: {account_id: mainAccount.account.value.id}});
  };

  const getAccountCarriers: UseCarriers['getAccountCarriers'] = async () => {
    if (!carrierStore.getAccountCarriers().value.length) {
      await updateAccountCarrierOptions();
    }

    return carrierStore.getAccountCarriers();
  };

  const updateAccountCarrierOptions: UseCarriers['updateAccountCarrierOptions'] = async () => {
    const carrierOptions = await fetchAccountCarriers();
    carrierStore.setAccountCarriers(carrierOptions);
  };

  const postAccountCarrierOptions: UseCarriers['postAccountCarrierOptions'] = async (carrierOption) => {
    const {sdk} = useSdk();
    const mainAccount = await getAccount();

    await sdk.postCarrierOptions({
      path: {
        account_id: mainAccount.account.value.id,
      },
      body: [carrierOption],
    });

    await updateAccountCarrierOptions();
  };

  const deleteAccountCarrierOption: UseCarriers['deleteAccountCarrierOption'] = async (contractId) => {
    const {sdk} = useSdk();
    const mainAccount = await getAccount();

    try {
      await sdk.deleteCarrierOption({
        path: {
          contract_id: contractId,
          account_id: mainAccount.account.value.id,
        },
      });
    } catch (error) {
      throw new Error('Could not delete carrier option');
    }

    await updateAccountCarrierOptions();
  };

  const allContractsInStore: UseCarriers['allContractsInStore'] = (shopId = currentShop.value.id) => {
    const accountContracts = carrierStore.getAccountCarriers().value;
    const shopContracts = carrierStore.getShopCarriers(shopId);
    return [...accountContracts, ...shopContracts];
  };

  const isCustomContract: UseCarriers['isCustomContract'] = (contractId) => {
    const carrier = allContractsInStore().find((carrier) => carrier.id === contractId);

    return carrier?.type === ContractType.CUSTOM;
  };

  const isMainContract: UseCarriers['isMainContract'] = (contractId) => {
    const carrier = allContractsInStore().find((carrier) => carrier.id === contractId);
    return hasMainContract(carrier);
  };

  const getAccountContractIdForCarrierId: UseCarriers['getAccountContractIdForCarrierId'] = (carrierId) => {
    const carrier = carrierStore.getAccountCarriers().value.find((carrier) => carrier.carrier_id === carrierId);
    return carrier?.id;
  };

  const getCarrierIdForContractId: UseCarriers['getCarrierIdForContractId'] = (contractId) => {
    const carrier = allContractsInStore().find((carrier) => carrier.id === contractId);
    return carrier?.carrier_id;
  };

  const getCarrierName: UseCarriers['getCarrierName'] = (contractId) => {
    const carrier = allContractsInStore().find((carrier) => carrier.id === contractId);
    return carrier?.carrier.name;
  };

  const customAccountCarrierOptions: UseCarriers['customAccountCarrierOptions'] = computed(() => {
    return carrierStore.getAccountCarriers().value.filter((carrier) => carrier.type === ContractType.CUSTOM);
  });

  const mainAccountCarrierOptions: UseCarriers['mainAccountCarrierOptions'] = computed(() => {
    return carrierStore.getAccountCarriers().value.filter((carrier) => carrier.type === ContractType.MAIN);
  });

  const updateAccountCarrierOption: UseCarriers['updateAccountCarrierOption'] = async (oldValue, newValue) => {
    await deleteAccountCarrierOption(oldValue.id);

    let updateSuccess = true;

    try {
      await postAccountCarrierOptions(newValue);
    } catch (error) {
      updateSuccess = false;
    }

    if (updateSuccess) {
      return;
    }

    await postAccountCarrierOptions({
      carrier_id: oldValue.carrier_id,
      username: oldValue.username,
      password: oldValue.password,
      options: oldValue.options,
    });

    throw new Error('Could not update carrier option');
  };

  const fetchShopCarrierOptions: UseCarriers['fetchShopCarrierOptions'] = async () => {
    status.value = Status.PENDING;

    let response;

    try {
      response = await sdk.getShopCarrierOptions({
        path: {
          shop_id: currentShop.value.id,
        },
      });
      status.value = Status.RESOLVED;
    } catch (err) {
      status.value = Status.REJECTED;
      throw err;
    }

    carrierStore.setShopCarriers(currentShop.value.id, response);
  };

  const getShopCarrierOptions: UseCarriers['getShopCarrierOptions'] = async () => {
    const shopCarriers = carrierStore.getShopCarriers(currentShop.value.id);

    if (!shopCarriers || shopCarriers.length === 0) {
      await fetchShopCarrierOptions();
    }

    return computed(() => carrierStore.getShopCarriers(currentShop.value.id));
  };

  const postShopCarrierOption: UseCarriers['postShopCarrierOption'] = async (carrierOption) => {
    try {
      await sdk.postShopCarrierOptions({
        path: {
          shop_id: currentShop.value.id,
        },
        body: [carrierOption],
      });
    } catch (err) {
      status.value = Status.REJECTED;
      throw err;
    }

    await fetchShopCarrierOptions();
  };

  const updateShopCarrierOption: UseCarriers['updateShopCarrierOption'] = async (oldValue, newValue) => {
    try {
      await deleteShopCarrierOption(oldValue.id);
    } catch (error) {
      throw new Error('Could not delete carrier option');
    }

    let updateSuccess = true;

    try {
      await postShopCarrierOption(newValue);
    } catch (error) {
      updateSuccess = false;
    }

    if (updateSuccess) {
      return;
    }

    // Revert to old values
    await postShopCarrierOption({
      carrier_id: oldValue.carrier_id,
      username: oldValue.username,
      password: oldValue.password,
      options: oldValue.options,
    });

    throw new Error('Could not update carrier option');
  };

  const setShopCarrierOptionEnabledState: UseCarriers['setShopCarrierOptionEnabledState'] = async (carrierOption) => {
    try {
      await sdk.putShopCarrierOptions({
        path: {
          shop_id: currentShop.value.id,
        },
        body: [carrierOption],
      });

      carrierStore.updateShopCarrier(currentShop.value.id, carrierOption);
    } catch (err) {
      status.value = Status.REJECTED;
      throw err;
    }
  };

  const deleteShopCarrierOption: UseCarriers['deleteShopCarrierOption'] = async (contractId) => {
    try {
      await sdk.deleteShopCarrierOption({
        path: {
          shop_id: currentShop.value.id,
          id: contractId,
        },
      });
    } catch (err) {
      status.value = Status.REJECTED;
      throw err;
    }

    carrierStore.deleteShopCarrier(currentShop.value.id, contractId);
  };

  const customShopCarrierOptions: UseCarriers['customShopCarrierOptions'] = computed(() => {
    return carrierStore.getShopCarriers(currentShop.value.id).filter((carrier) => carrier.type === ContractType.CUSTOM);
  });

  /**
   * All custom shop contracts that are not also account contracts.
   * The user can have a custom contract for the same carrier on shop and account level.
   * So we filter out the account contracts.
   */
  const uniqueShopContracts: UseCarriers['uniqueShopContracts'] = computed(() =>
    customShopCarrierOptions.value.filter(
      (shopCarrierOption: CarrierOption) =>
        !customAccountCarrierOptions.value.find(
          (accountCarrierOption) => accountCarrierOption.id === shopCarrierOption.id,
        ),
    ),
  );

  /**
   * All custom account contracts of the shop.
   * The user can have a custom contract for the same carrier on shop and account level.
   * So we filter out the shop contracts.
   */
  const shopAccountContracts: UseCarriers['shopAccountContracts'] = computed(() =>
    customShopCarrierOptions.value.filter(
      (accountCarrierOption: CarrierOption) =>
        !uniqueShopContracts.value.find((shopCarrierOption) => shopCarrierOption.id === accountCarrierOption.id),
    ),
  );

  return {
    allContractsInStore,
    accountCarriers,
    getAccountCarriers,
    updateAccountCarrierOptions,
    postAccountCarrierOptions,
    deleteAccountCarrierOption,
    isCustomContract,
    isMainContract,
    getCarrierName,
    getAccountContractIdForCarrierId,
    getCarrierIdForContractId,
    customAccountCarrierOptions,
    mainAccountCarrierOptions,
    updateAccountCarrierOption,
    fetchShopCarrierOptions,
    getShopCarrierOptions,
    postShopCarrierOption,
    updateShopCarrierOption,
    setShopCarrierOptionEnabledState,
    deleteShopCarrierOption,
    customShopCarrierOptions,
    uniqueShopContracts,
    shopAccountContracts,
  };
}
