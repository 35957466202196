import env from '@/utils/env';

export const cookieBannerIsSet = (): boolean => {
  return !!document.querySelector('#ot-sdk-cookie-policy');
};

export const setCookieBanner = (id: string): void => {
  if (!id || cookieBannerIsSet() || env.devMode) {
    return;
  }

  const bannerScript = document.createElement('script');
  bannerScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
  bannerScript.id = 'ot-sdk-cookie-policy';
  bannerScript.type = 'text/javascript';
  bannerScript.charset = 'UTF-8';
  bannerScript.dataset.domainScript = id;

  const wrapperScript = document.createElement('script');
  wrapperScript.type = 'text/javascript';
  wrapperScript.innerHTML = 'function OptanonWrapper() { }';

  const fragment = document.createDocumentFragment();
  fragment.appendChild(bannerScript);
  fragment.appendChild(wrapperScript);

  document.body.appendChild(fragment);
};
