/* eslint-disable @typescript-eslint/no-magic-numbers */
import {convertEnumValue} from '@myparcel/constants/helpers';
import {TierName} from '@/types/subscriptions/tiers';

export const MyOrdersProductId = {
  Start: 4615,
  Plus: 4616,
  Premium: 4617,
  Max: 4618,
} as const;

export const MyOrdersTierName = {
  Start: TierName.Start,
  Plus: TierName.Plus,
  Premium: TierName.Premium,
  Max: TierName.Max,
} as const;

export const MY_ORDERS_IDS_TO_NAMES = {
  [MyOrdersProductId.Start]: MyOrdersTierName.Start,
  [MyOrdersProductId.Plus]: MyOrdersTierName.Plus,
  [MyOrdersProductId.Premium]: MyOrdersTierName.Premium,
  [MyOrdersProductId.Max]: MyOrdersTierName.Max,
};

export const MY_ORDERS_NAMES_TO_IDS = {
  [MyOrdersTierName.Start]: MyOrdersProductId.Start,
  [MyOrdersTierName.Plus]: MyOrdersProductId.Plus,
  [MyOrdersTierName.Premium]: MyOrdersProductId.Premium,
  [MyOrdersTierName.Max]: MyOrdersProductId.Max,
};

export const toMyOrdersTierName = (id: typeof MyOrdersProductId): typeof MyOrdersTierName => {
  return convertEnumValue(MY_ORDERS_IDS_TO_NAMES, id);
};

export const toMyOrdersProductId = (name: typeof MyOrdersTierName): typeof MyOrdersProductId => {
  return convertEnumValue(MY_ORDERS_NAMES_TO_IDS, name);
};
