interface Language {
  code: string;
  name: string;
  countryCode: string;
}

// sort languages based on property 'code', but always put defaultLanguage first.
const sortLanguage = (defaultLanguage: string) => {
  return (languageA: Language, languageB: Language): number => {
    if (languageA.code === defaultLanguage) {
      return -1;
    }

    if (languageB.code === defaultLanguage) {
      return 1;
    }

    return languageA.code.localeCompare(languageB.code);
  };
};

/**
 * Matches the available languages to the supported languages.
 *
 * @param translate - The i18n translator function.
 * @param availableLanguages - The list of available languages.
 * @param supportedLanguages - The list of languages that are supported by the configuration.
 */
export const getLanguages = (
  translate: (...args: any[]) => string,
  availableLanguages: string[],
  supportedLanguages: string[],
  defaultLanguage = 'nl',
): Language[] =>
  availableLanguages
    .filter((language) => supportedLanguages.includes(language))
    .map((language) => ({
      code: language,
      name: translate('meta.name', null, {locale: language}),
      countryCode: translate('meta.flag', null, {locale: language}),
    }))
    .sort(sortLanguage(defaultLanguage));
