<template>
  <HotjarFeedbackButton @click="showModal">
    <span class="hidden md:block md:mr-6">
      {{ $t('feedback') }}
    </span>
  </HotjarFeedbackButton>

  <HotjarFeedbackModal />
</template>

<script setup lang="ts">
import HotjarFeedbackModal from '@/components/modals/HotjarFeedbackModal/HotjarFeedbackModal.vue';
import HotjarFeedbackButton from '@/components/ui/HotjarFeedbackButton/HotjarFeedbackButton.vue';
import {NAME} from '@/components/ui/Modal';
import useHotjar from '@/composables/useHotjar';
import {useModal} from '@/composables/useModal';

const {openModal} = useModal(NAME.HOTJAR_FEEDBACK);

const {triggerEvent} = useHotjar();

const showModal = () => {
  openModal();
  triggerEvent('start-feedback');
};
</script>
