export type AnalyticsDashboardCredentials = {
  id: string;
  token: string;
};

export type AnalyticsDashboard = {
  luzmo: string;
  dashboard: AnalyticsDashboardSlug;
};

export interface AnalyticsDashboardWithCategory extends AnalyticsDashboard {
  category: AnalyticsDashboardCategory;
}

export enum AnalyticsDashboardSlug {
  Shipments = 'shipments',
  ShipmentsWeekly = 'shipments-weekly',
  ShipmentsT2 = 'shipments-t2',
  ShipmentsT3 = 'shipments-t3',
  ShipmentsT4 = 'shipments-t4',
  Returns = 'returns',
  ReturnsWeekly = 'returns-weekly',
  ReturnsT2 = 'returns-t2',
  ReturnsT3 = 'returns-t3',
  ReturnsT4 = 'returns-t4',
  Webshops = 'webshops',
  WebshopsWeekly = 'webshops-weekly',
  WebshopsT2 = 'webshops-t2',
  WebshopsT3 = 'webshops-t3',
  WebshopsT4 = 'webshops-t4',
  TransitTime = 'transit-time',
  TransitTimeT2 = 'transit-time-t2',
  TransitTimeT3 = 'transit-time-t3',
  TransitTimeT4 = 'transit-time-t4',
  Surcharges = 'surcharges',
  SurchargesT2 = 'surcharges-t2',
  SurchargesT3 = 'surcharges-t3',
  SurchargesT4 = 'surcharges-t4',
  Cases = 'cases',
  CasesT2 = 'cases-t2',
  CasesT3 = 'cases-t3',
  CasesT4 = 'cases-t4',
  FinanceT2 = 'finance-t2',
  FinanceT3 = 'finance-t3',
  FinanceT4 = 'finance-t4',
  ShortcomingT2 = 'shortcoming-t2',
  ShortcomingT3 = 'shortcoming-t3',
  ShortcomingT4 = 'shortcoming-t4',
}

export enum AnalyticsDashboardCategory {
  Shipments = 'shipments',
  Returns = 'returns',
  Webshops = 'webshops',
  TransitTime = 'transit-time',
  Surcharges = 'surcharges',
  Cases = 'cases',
  Finance = 'finance',
  Shortcoming = 'shortcoming',
}
