import type {MenuItem} from '@/components/ui/layout/sideMenu/menuItem/menuItem.types';
import myparcelnl from './myparcelnl';
import sendmyparcelbe from './sendmyparcelbe';
import {ConfigKey} from '@/providers/platformConfig.types';

type Mapping = Record<ConfigKey, () => MenuItem[]>;

const mapping: Mapping = {
  [ConfigKey.DEFAULT]: myparcelnl,
  [ConfigKey.MYPARCEL_NL]: myparcelnl,
  [ConfigKey.MYPARCEL_BE]: sendmyparcelbe,
};

const menu = (key: keyof typeof mapping): MenuItem[] => {
  return mapping.hasOwnProperty(key) ? mapping[key]() : mapping[ConfigKey.DEFAULT]();
};

export default menu;
