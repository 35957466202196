import type {Ref} from 'vue';
import {readonly, ref} from 'vue';

const isLoading = ref(false);

type UseLoader = {
  isLoading: Readonly<Ref<boolean>>;
  startLoading: () => void;
  stopLoading: () => void;
};

export default function useLoader(): UseLoader {
  const startLoading = () => {
    isLoading.value = true;
  };

  const stopLoading = () => {
    isLoading.value = false;
  };

  return {
    isLoading: readonly(isLoading),
    startLoading,
    stopLoading,
  };
}
