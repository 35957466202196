import type {Ref} from 'vue';
import {readonly, ref} from 'vue';

export const SIDE_MENU_COLLAPSED = 'sideMenuCollapsed';

interface UseSidebarToggle {
  isSidebarCollapsed: Ref<boolean>;
  collapseSidebar: () => void;
  expandSidebar: () => void;
}

const isSidebarCollapsed = ref(false);

export default function UseSidebarToggle(): UseSidebarToggle {
  isSidebarCollapsed.value = Boolean(window.isSidebarCollapsed);

  const collapseSidebar: UseSidebarToggle['collapseSidebar'] = () => {
    isSidebarCollapsed.value = true;
    localStorage.setItem(SIDE_MENU_COLLAPSED, String(isSidebarCollapsed.value));
  };

  const expandSidebar: UseSidebarToggle['expandSidebar'] = () => {
    isSidebarCollapsed.value = false;
    localStorage.setItem(SIDE_MENU_COLLAPSED, String(isSidebarCollapsed.value));
  };

  return {
    isSidebarCollapsed: readonly(isSidebarCollapsed),
    collapseSidebar,
    expandSidebar,
  };
}
