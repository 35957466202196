import type {EcommercePlatforms} from '@myparcel-frontend/mypa-api-services/order-etl';

export const SalesChannels = {
  Bol: 'BOL',
  Woocommerce: 'WOOCOMMERCE',
  Shopify: 'SHOPIFY',
} as const;

export const SalesChannelsStatus = {
  DelegationRequested: 'DELEGATION_REQUESTED',
  DelegationIssued: 'DELEGATION_ISSUED',
  DelegationRequired: 'DELEGATION_REQUIRED',
} as const;

export const SalesChannelsBolExtractionDistributionParty = {
  All: 'ALL',
  Bol: 'BOL',
  Retailer: 'RETAILER',
} as const;

export const SalesChannelsBolExtractionFulfilmentMethod = {
  All: 'ALL',
  FulfilmentByRetailer: 'FBR',
  FulfilmentByBol: 'FBB',
} as const;

export const SalesChannelFrequency = {
  Automatic: 'automatic',
  Manual: 'manual',
} as const;

export type EcommercePlatformNames = EcommercePlatforms['name'];

export type SalesChannelsCreateFormBol = {
  name: string;
  ecommercePlatform: EcommercePlatformNames;
  distributionParty: typeof SalesChannelsBolExtractionDistributionParty;
  fulfilmentMethod: typeof SalesChannelsBolExtractionFulfilmentMethod;
};

export type SalesChannelsCreateFormWoocommerce = {
  name: string;
  ecommercePlatform: EcommercePlatformNames;
  url: string;
};

export type SalesChannelsCreateFormShopify = {
  name: string;
  ecommercePlatform: EcommercePlatformNames;
  storeId: string;
};

export type SalesChannelsCreateForm =
  | SalesChannelsCreateFormBol
  | SalesChannelsCreateFormWoocommerce
  | SalesChannelsCreateFormShopify;
