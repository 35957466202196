import type {CarrierOption} from '@/types/CarrierOption';
import {ContractType} from '@/types/CarrierOption';

export const hasMainContract = (carrier: CarrierOption | undefined): boolean => {
  if (!carrier) {
    return false;
  }

  return carrier.type === ContractType.MAIN;
};
