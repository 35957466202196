import type {AccountMessage} from '@myparcel-frontend/types';
import type {Ref} from 'vue';
import {useSdk, Status} from '@myparcel-frontend/sdk';
import {storeToRefs} from 'pinia';
import {ref} from 'vue';
import useAccount from '@/composables/useAccounts';
import {useAccountMessagesStore} from '@/store/accountMessages';

interface UseAccountMessages {
  getAccountMessages: () => Promise<AccountMessage[]>;
  deleteAccountMessage: (message: AccountMessage) => Promise<void>;
  accountMessages: Ref<AccountMessage[]>;
  expirationDate: Ref<number>;
  status: Ref<Status>;
}

const status = ref(Status.IDLE);

/**
 * Helper hook to manage account messages throughout the app.
 * Methods:
 * - getSystemMessages: Fetch system messages from the API.
 * - deleteSystemMessage: Delete a system message from the API.
 */
export default function useAccountMessages(): UseAccountMessages {
  const accountMessagesStore = useAccountMessagesStore();
  const {account} = useAccount();
  const {sdk} = useSdk();
  const {accountMessages, expirationDate} = storeToRefs(accountMessagesStore);

  const getAccountMessages = async () => {
    if (accountMessagesStore.isExpired) {
      let result;
      status.value = Status.PENDING;

      try {
        result = await sdk.getAccountMessages({
          parameters: {
            account_id: account.value.id,
          },
        });
        status.value = Status.RESOLVED;
      } catch (error) {
        status.value = Status.REJECTED;
        throw error;
      }

      accountMessagesStore.setAccountMessages(result);
    }

    return accountMessagesStore.accountMessages;
  };

  const deleteAccountMessage = async (message: AccountMessage) => {
    const {id} = message;
    status.value = Status.PENDING;

    try {
      await sdk.deleteAccountMessage({
        path: {id},
      });
      accountMessagesStore.deleteAccountMessage(message);
      status.value = Status.RESOLVED;
    } catch (err) {
      status.value = Status.REJECTED;
      throw err;
    }
  };

  return {
    getAccountMessages,
    deleteAccountMessage,
    accountMessages,
    expirationDate,
    status,
  };
}
