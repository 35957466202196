<template>
  <template v-if="envWarning">
    <EnvWarning>
      {{ envWarning }}
    </EnvWarning>
  </template>
  <template v-else-if="redirectComponent">
    <component :is="redirectComponent" />
  </template>
  <template v-else>
    <slot />
  </template>
</template>

<script setup lang="ts">
import type {VueElement} from 'vue';
import EnvWarning from './components/EnvWarning.vue';
import useAuth from '@/composables/useAuth';
import env, {envCheck} from '@/utils/env';
import Auth0Callback from '@/views/auth/Auth0Callback.vue';
import Impersonate from '@/views/auth/Impersonate.vue';

let envWarning: unknown;
let redirectComponent: VueElement;
const envOk = envCheck();

const checkRedirects = (): void => {
  // check for the current route if it's one of the protected ones, immediately redirect
  // ultimately, it is not possible to handle this with vue-router, so we do it here.
  const path = window.location.pathname;

  switch (path) {
    case '/auth/login/auth0/':
      redirectComponent = Auth0Callback;
      break;
    case '/impersonate':
      redirectComponent = Impersonate;
      break;
  }
};

const setupAuth = async (): Promise<void> => {
  if (redirectComponent) {
    return;
  }

  if (envOk) {
    const {setAuth} = useAuth();
    const impersonatedUserId = window.localStorage.getItem(
      'auth0_impersonated_account_id',
    );
    const scope = impersonatedUserId
      ? 'profile email impersonation'
      : env.auth0Scope;
    await setAuth(scope);
  } else {
    envWarning = 'Environment configuration not set up.';
  }
};

const isLoggedIn = async () => {
  if (redirectComponent) {
    return;
  }

  const {getAuth} = useAuth();
  const auth = getAuth();
  const isAuthenticated = await auth.isAuthenticated();

  if (!isAuthenticated) {
    localStorage.setItem('returnUrl', window.location.pathname);
    const url = await auth.buildAuthorizeUrl();
    window.location.replace(url);
  }
};

try {
  checkRedirects();
  await setupAuth();
  await isLoggedIn();

  /* c8 ignore start */
} catch (error: unknown) {
  envWarning = error;
}

/* c8 ignore stop */
</script>
