import type {Acl} from '@/providers/acl.types';
import {defineStore} from 'pinia';
import {ref} from 'vue';

export const useAclStore = defineStore('acl', () => {
  const acl = ref<Acl>({});

  const setAcl = (newAcl: Acl) => {
    acl.value = newAcl;
  };

  return {
    acl,
    setAcl,
  };
});
