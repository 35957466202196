import { ref as c, readonly as v } from "vue";
var d = {
  Info: "info",
  Success: "success",
  Warning: "warning",
  Error: "alert"
}, O = {
  Toaster: "toaster"
};
const o = c([]), r = c(0);
function F() {
  const s = ({
    id: t,
    variant: n = d.Info,
    canClose: T,
    withShadow: u,
    body: l,
    title: I,
    timeout: a = 6e3,
    location: N = O.Toaster
  }) => {
    let i = t;
    i || (r.value++, i = r.value.toString()), o.value.unshift({
      id: i,
      body: l,
      variant: n,
      title: I,
      timeout: a,
      location: N,
      canClose: T,
      withShadow: u
    }), a && setTimeout(() => {
      e(i);
    }, a);
  }, e = (t) => {
    o.value = o.value.filter((n) => n.id !== t);
  }, f = () => {
    o.value = [];
  };
  return {
    notifications: v(o),
    addNotification: s,
    removeNotification: e,
    clearNotifications: f
  };
}
export {
  F as useNotification
};
