<!-- Verbose debug message, only to be used in development -->
<template>
  <div class="m-16">
    <h1 class="text-4xl">
      <slot name="title" />
    </h1>
    <div class="my-4">
      <slot />
    </div>
  </div>
</template>
