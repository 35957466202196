<template>
  <a
    :class="variantClasses()"
    :href="href"
    :target="target"
    :rel="addRelAttribute() ? 'noopener noreferrer' : false"
    :title="title"
    role="link">
    <slot />
  </a>
</template>

<script setup lang="ts">
import type {PropType} from 'vue';
import {isEnumValue} from '@myparcel/ts-utils';
import {ExternalLinkVariant, Target} from './ExternalLink.types';

const VARIANT_DEFINITION: Record<ExternalLinkVariant, string[]> = {
  [ExternalLinkVariant.HEADER_MENU]: ['h-full', 'block', 'text-white', 'font-extralight', 'hover:text-secondary'],
  [ExternalLinkVariant.HEADER_MENU_ITEM]: ['text-white', 'block', 'font-extralight', 'py-2'],
  [ExternalLinkVariant.FOOTER_LEGAL]: [
    'opacity-40',
    'text-sm',
    'hover:underline',
    'whitespace-nowrap',
    'font-extralight',
  ],
  [ExternalLinkVariant.FOOTER_NAVIGATION]: ['text-white', 'hover:text-secondary', 'block', 'font-extralight', 'mb-6'],
  [ExternalLinkVariant.DEFAULT]: ['text-secondary', 'hover:text-secondary-dark'],
  [ExternalLinkVariant.OUTLINE]: [
    'text-secondary',
    'border',
    'py-2',
    'px-3',
    'rounded-[20px]',
    'border-secondary',
    'hover:text-white',
    'hover:border-secondary-dark',
    'hover:bg-secondary',
  ],
  [ExternalLinkVariant.CLEAN]: [],
};

const props = withDefaults(
  defineProps<{
    href: string;
    title?: string;
    target?: Target;
    variant?: ExternalLinkVariant;
  }>(),
  {
    target: Target.SELF,
    variant: ExternalLinkVariant.DEFAULT,
    title: undefined,
  },
);

const addRelAttribute = (): boolean => props.target === Target.BLANK;

const variantClasses = (): string => {
  const baseStyle: string[] = ['duration-300', 'ease-in-out', 'transition-colors'];

  const variantName: ExternalLinkVariant = props.variant;
  return [...baseStyle, ...VARIANT_DEFINITION[variantName]].join(' ');
};
</script>
