import type {ExternalProvider} from '@myparcel-frontend/types';
import {dayjs} from '@myparcel-frontend/utils';
import log from '@/utils/logging';

const secondsToMillisecondsMultiplier = 1000;

/**
 * Get the expiration date of the credentials.
 * Do not use `credentials.tokens.expires`. This is the expiration day of the access_token, NOT the refresh_token. The access_token expires between 5 minutes and 1 day.
 * We decode the refresh_token to get the expiration date out of it.
 *
 * Log an error if the credentials are invalid.
 */
export const getExpireDate = (credential: ExternalProvider): number => {
  try {
    const {refresh_token} = credential.credentials.tokens;
    const payload = refresh_token.replace('OAUTH2.', '').split('.')[1];

    const data = JSON.parse(atob(payload));
    return data.exp * secondsToMillisecondsMultiplier;
  } catch (error) {
    log(error, {
      credential,
    });
    return 0;
  }
};

/**
 * Get the expiration date of the Amazon credentials.
 * Amazon's refresh_token is not a JWT token, so we can't decode it.
 * For now, we assume that if there are credentials, they are valid.
 */
export const getAmazonExpireDate = (credential: ExternalProvider): number => {
  if (!Array.isArray(credential.credentials)) {
    // We add 1 year so the integration shows as active.
    return dayjs().add(1, 'year').unix() * secondsToMillisecondsMultiplier;
  }

  return 0;
};
