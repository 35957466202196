import {convertEnumValue} from '@myparcel/constants/helpers';

export enum TierName {
  Light = 'light',
  Start = 'start',
  Plus = 'plus',
  Premium = 'premium',
  Max = 'max',
}

export enum TierId {
  Light = 0,
  Start = 1,
  Plus = 2,
  Premium = 3,
  Max = 4,
}

export const SubscriptionTierIdToName = {
  [TierId.Light]: TierName.Light,
  [TierId.Start]: TierName.Start,
  [TierId.Plus]: TierName.Plus,
  [TierId.Premium]: TierName.Premium,
  [TierId.Max]: TierName.Max,
} as const;

export const SubscriptionTierNameToId = {
  [TierName.Light]: TierId.Light,
  [TierName.Start]: TierId.Start,
  [TierName.Plus]: TierId.Plus,
  [TierName.Premium]: TierId.Premium,
  [TierName.Max]: TierId.Max,
} as const;

export const convertTierIdToName = (id: TierId): TierName => {
  return convertEnumValue(SubscriptionTierIdToName, id);
};

export const convertTierNameToId = (name: TierName): TierId => {
  return convertEnumValue(SubscriptionTierNameToId, name);
};
