import type {ExternalProvider} from '@myparcel-frontend/types';
import {defineStore} from 'pinia';
import {ref} from 'vue';

export const useExternalProviderCredentialsStore = defineStore('externalProviderCredentials', () => {
  const externalProviderCredentials = ref<ExternalProvider[]>([]);

  const getValidExternalProviderCredentials = () => {
    return externalProviderCredentials.value.filter((externalProviderCredential) => {
      // When the credentials are filled it is an object, so we can deduct that if it is an array, it is an empty array without credentials.
      return !Array.isArray(externalProviderCredential.credentials);
    });
  };

  const addExternalProviderCredentials = (externalProviderCredentialsData: ExternalProvider | ExternalProvider[]) => {
    if (Array.isArray(externalProviderCredentialsData)) {
      externalProviderCredentials.value.push(...externalProviderCredentialsData);
      return;
    }

    externalProviderCredentials.value.push(externalProviderCredentialsData);
  };

  const removeExternalProviderCredentials = (
    externalProviderCredentialsData: ExternalProvider | ExternalProvider[],
  ) => {
    if (Array.isArray(externalProviderCredentialsData)) {
      externalProviderCredentials.value = externalProviderCredentials.value.filter((externalProviderCredential) => {
        return !externalProviderCredentialsData.some((externalProviderCredentialData) => {
          return externalProviderCredentialData.id === externalProviderCredential.id;
        });
      });
      return;
    }

    externalProviderCredentials.value = externalProviderCredentials.value.filter((externalProviderCredential) => {
      return externalProviderCredential.id !== externalProviderCredentialsData.id;
    });
  };

  const updateExternalProviderCredentials = (externalProviderCredentialsData: ExternalProvider) => {
    const index = externalProviderCredentials.value.findIndex((externalProviderCredential) => {
      return externalProviderCredential.id === externalProviderCredentialsData.id;
    });

    if (index === -1) {
      return;
    }

    externalProviderCredentials.value[index] = externalProviderCredentialsData;
  };

  return {
    externalProviderCredentials,
    getValidExternalProviderCredentials,
    addExternalProviderCredentials,
    removeExternalProviderCredentials,
    updateExternalProviderCredentials,
  };
});
