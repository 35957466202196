<template>
  <li class="flex flex-row-reverse px-3 py-4">
    <IconBase
      width="24"
      height="24"
      viewbox="0 0 24 24"
      class="cursor-pointer">
      <Times24 />
    </IconBase>
  </li>
</template>

<script lang="ts">
import {IconBase, Times24} from '@myparcel-frontend/ui';
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'CloseButton',
  components: {
    IconBase,
    Times24,
  },
});
</script>
