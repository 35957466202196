import type {Acl} from '@/providers/acl.types';
import type {ConfigInterface, ServicesInterface} from '@/providers/platformConfig.types';
import type {UiSettings} from '@/types/uiSettings';
import type {Shop} from '@myparcel-frontend/types';
import type {Account} from '@myparcel-frontend/types';
import type {ComputedRef, Ref} from 'vue';
import {defineStore} from 'pinia';
import {computed, ref} from 'vue';
import env from '@/utils/env';

export interface ConfigStore {
  permissions: Ref<string[]>;
  config: Ref<ConfigInterface>;
  acl: Ref<Acl>;
  account: Ref<Account>;
  uiSettings: Ref<UiSettings>;
  services: Ref<ServicesInterface>;
  fallbackLanguage: ComputedRef<string>;
  getPlatformKey: ComputedRef<string>;
  frontofficeUrl: ComputedRef<string>;
  warnForMissingTranslations: ComputedRef<boolean>;
  hasOrderMode: ComputedRef<boolean>;
  currentShop: ComputedRef<Shop>;
  currentUser: ComputedRef;
  shops: ComputedRef<Shop[]>;
  accountSettings: ComputedRef;
  appcuesIsReady: ComputedRef<boolean>;
  activeShops: ComputedRef<Shop[]>;
  setConfig: (config: ConfigInterface) => void;
  setAcl: (acl: Acl) => void;
  setAccount: (account: Account) => void;
  setUiSettings: (uiSettings: UiSettings) => void;
  setAppcuesIsReady: (isReady: boolean) => void;
  updateShop: (shop: Shop) => void;
  insertShop: (shop: Shop) => void;
}

export const useConfigStore = defineStore('config', () => {
  const permissions = ref([]);
  const config = ref<ConfigInterface>({services: {}});
  const acl = ref<Acl>({});
  const account = ref<Account>({});
  const uiSettings = ref<UiSettings>({});
  const services = ref<ServicesInterface>({appcuesIsReady: false});

  /**
   * Return the first value from the supported_languages.
   */
  const fallbackLanguage = computed(() => config.value.supported_languages?.find(Boolean));
  /**
   * Create the translation key as a prefix.
   */
  const getPlatformKey = computed(() => config.value.translation_key);

  /**
   * Return the missing translations as a console warn.
   */
  const warnForMissingTranslations = computed(() => config.value.warnForMissingTranslations);

  const currentShop = computed(
    () => account.value.shops.find((shop: Shop) => shop.id === uiSettings.value.preferred_shop_id) as Shop,
  );

  const currentUser = computed(() => account.value.users.find(({id}: {id: number}) => id === acl.value.user_id));

  const shops = computed(() => account.value.shops);

  const activeShops = computed(() => {
    return account.value.shops.filter((shop) => {
      return !shop.hidden;
    });
  });

  const accountSettings = computed(() => account.value.general_settings);

  const appcuesIsReady = computed(() => services.value.appcuesIsReady);

  const frontofficeUrl = computed(() => {
    const baseUrl = env.frontofficeUrl[getPlatformKey.value] ?? env.frontofficeUrl.default;

    if (!currentShop.value.branding?.subdomain) {
      return baseUrl.replace(':subdomain.', '');
    }

    return baseUrl.replace(':subdomain', currentShop.value.branding?.subdomain);
  });

  const setConfig = (newConfig: ConfigInterface) => {
    config.value = newConfig;
  };

  const setAcl = (newAcl: Acl) => {
    acl.value = newAcl;
  };

  const setAccount = (newAccount: Account) => {
    account.value = newAccount;
  };

  const hasOrderMode = computed(() => !!accountSettings.value.order_mode || false);

  const setUiSettings = (newUiSettings: UiSettings) => {
    uiSettings.value = newUiSettings;
  };

  const setAppcuesIsReady = () => {
    services.value.appcuesIsReady = true;
  };

  const updateShop = (shop: Partial<Shop>) => {
    const index = account.value.shops.findIndex(({id}: {id: number}) => id === shop.id);

    if (index !== -1) {
      account.value.shops[index] = shop;
    }
  };

  const insertShop = (shop: Partial<Shop>) => {
    account.value.shops.push(shop);
  };

  return {
    permissions,
    config,
    acl,
    account,
    uiSettings,
    services,
    fallbackLanguage,
    getPlatformKey,
    warnForMissingTranslations,
    currentShop,
    currentUser,
    shops,
    accountSettings,
    appcuesIsReady,
    activeShops,
    hasOrderMode,
    frontofficeUrl,
    setConfig,
    setAcl,
    setAccount,
    setUiSettings,
    setAppcuesIsReady,
    updateShop,
    insertShop,
  };
});
