<template>
  <div :data-test="`notifications ${location}`">
    <Toast
      v-for="notification in locationNotifications"
      :id="notification.id"
      :key="notification.id"
      :size="NotificationSize.Full"
      :timeout="notification.timeout"
      :variant="notification.variant"
      :can-close="notification.canClose"
      :with-shadow="notification.withShadow"
      class="flex-none"
      @remove="removeNotification">
      <template
        v-if="notification.title"
        #title>
        {{ notification.title }}
      </template>
      {{ notification.body }}
    </Toast>
  </div>
</template>

<script setup lang="ts">
import type {NotificationLocation} from '@/composables/useNotifications';
import {computed} from 'vue';
import Toast from './Toast.vue';
import useNotifications, {NotificationSize} from '@/composables/useNotifications';

const {notifications, removeNotification} = useNotifications();

const props = defineProps<{
  location: NotificationLocation | string;
}>();

const locationNotifications = computed(() => {
  return notifications.value.filter((notification) => notification.location === props.location);
});
</script>

<style lang="scss" scoped>
.list-enter-active {
  transition: all 0.5s ease;
}
.list-leave-active {
  transition: all 0.2s ease;
}
.list-enter-from {
  opacity: 0;
  transform: translateX(30px);
}
.list-leave-to {
  opacity: 0;
}
</style>
