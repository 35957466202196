import type {Ref} from 'vue';
import {computed, reactive, readonly} from 'vue';
import {isEvent} from '@/types/typeGuards';

interface UseModal {
  isModalOpen: Readonly<Ref<boolean>>;
  openModal: () => void;
  closeModal: (returnToModalName?: string | Event) => void;
  isTopModal: Readonly<Ref<boolean>>;
}

const topModal = reactive<{top: string | null}>({top: null});
const modals = reactive({} as Record<string, boolean>);

/**
 * @param {string} name
 *
 * @returns {UseModal}
 *
 * Get the methods to manipulate a modal by the name of the modal.
 *
 * ```vue
 * <template>
 * <Modal :name="modalName">
 * </Modal>
 * </template>
 *
 * <script setup lang="ts">
 * const modalName = "example";
 * const {openModal} = useModal(name);
 *
 * onMounted(() => {
 *  openModal();
 * });
 * </script>
 * ```
 */
export const useModal = (name: string): UseModal => {
  // initialize the value for the modal name if the modalName does not exist yet
  if (!modals[name]) {
    modals[name] = false;
  }

  const openModal = (): void => {
    modals[name] = true;
    topModal.top = name;
  };

  const closeModal: UseModal['closeModal'] = (returnToModalName): void => {
    modals[name] = false;

    const modalNames = Object.keys(modals);
    const isAnyModalOpen = modalNames.some((modalName) => modals[modalName]);

    let newTop = null;

    if (returnToModalName && !isEvent(returnToModalName)) {
      newTop = returnToModalName;
    } else if (isAnyModalOpen) {
      // Make an open modal the top modal
      newTop = modalNames.find((modalName) => modals[modalName]);
    }

    topModal.top = newTop as typeof topModal.top;
  };

  const isModalOpen = computed(() => modals[name]);
  const isTopModal = computed(() => topModal.top === name);

  return {
    isModalOpen: readonly(isModalOpen),
    openModal,
    closeModal,
    isTopModal: readonly(isTopModal),
  };
};
