<template>
  <slot />
</template>

<script setup lang="ts">
import {useDelegationService} from '@myparcel-frontend/mypa-api-services/delegation';
import {useOrderService} from '@myparcel-frontend/mypa-api-services/order';
import {useOrderEtlService} from '@myparcel-frontend/mypa-api-services/order-etl';
import {setToken as productSetToken} from '@myparcel-frontend/mypa-api-services/product';
import {useSdk} from '@myparcel-frontend/sdk';
import useAccount from '@/composables/useAccounts';
import useAuth from '@/composables/useAuth';
import useCarriers from '@/composables/useCarriers/useCarriers';
import useSubscription from '@/composables/useSubscription';
import useSubscriptionCapabilities from '@/composables/useSubscriptionCapabilities';
import useUiSettings from '@/composables/useUiSettings';
import environment from '@/utils/env';

const {initSdk} = useSdk();
const {getAcl} = useAuth();

const initApiServiceClients = (token: string) => {
  const delegationService = useDelegationService();
  const orderService = useOrderService();
  const orderEtlService = useOrderEtlService();

  delegationService.setClient(token);

  if (environment.useMockingApiClients) {
    orderService.setMockingClient();
    orderEtlService.setMockingClient();
    return;
  }

  orderService.setClient(token);
  orderEtlService.setClient(token);
  productSetToken(token);
};

await getAcl(false);

const {getAuth} = useAuth();
const auth0 = getAuth();
const token = await auth0.getTokenSilently();

initSdk(token, environment.apiUrl);
initApiServiceClients(token);

const {getUiSettings} = useUiSettings();
const {getAccount} = useAccount();

const {getAccountCarriers} = useCarriers();

await Promise.all([getUiSettings(), getAccount()]);

const {hasPermission} = useAuth();

if (hasPermission('selectSubscription')) {
  const {getSubscriptions} = useSubscription();
  const {getSubscriptionCapabilities} = useSubscriptionCapabilities();

  await Promise.all([getSubscriptions(), getSubscriptionCapabilities()]);
}

void getAccountCarriers();
</script>
