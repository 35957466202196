<template>
  <div>
    <div
      id="main-container-overlay"
      data-test="main-container-overlay"
      :class="{
        'opacity-50 lg:opacity-0 pointer-events-auto': mobileMenuOpen,
        'opacity-0 pointer-events-none': !mobileMenuOpen,
      }"
      @click="toggleSidebar" />
    <nav
      v-if="menu.length"
      id="aria-side-menu"
      class="block side-menu"
      :class="{
        'xs:-left-72 -left-72 ease-in': !mobileMenuOpen,
        'xs:block left-0 ease-out': mobileMenuOpen,
      }"
      :aria-label="$t('navigation.main')">
      <div class="flex flex-col h-full justify-between">
        <ul class="side-menu-list">
          <CloseButton
            class="lg:hidden xs:flex"
            role="button"
            data-test="side-menu open"
            :aria-label="$t('navigation.open')"
            @click="toggleSidebar" />

          <template v-for="(item, index) in menu">
            <Divider
              v-if="item.key === 'divider'"
              :key="`${item.key}[${index}]`" />
            <template v-else>
              <MenuItem
                :key="item.key"
                :item="item"
                class="parent">
                {{ item.key($t) }}
                <template #children>
                  <template v-for="subItem in item.children">
                    <Divider
                      v-if="subItem.key === 'divider'"
                      :key="`${subItem.key}[${index}]`" />
                    <template v-else>
                      <MenuItem
                        :key="`${item.key}-${subItem.key}`"
                        :item="subItem">
                        {{ subItem.key($t) }}
                      </MenuItem>
                    </template>
                  </template>
                </template>
              </MenuItem>
            </template>
          </template>
        </ul>

        <div>
          <div class="border-t flex flex-col mt-1 px-4 py-4">
            <ul id="navigation--extra--list">
              <li>
                <a
                  v-if="$featureEnabled('settings.accountSettingsLegacy')"
                  href="/settings/account/contact"
                  class="hover:opacity-80 whitespace-nowrap"
                  target="_self">
                  {{ $t('accountSetting', 2) }}
                </a>
                <RouterLink
                  v-else
                  :to="{name: 'account-settings-orders-shipping'}">
                  {{ $t('accountSetting', 2) }}
                </RouterLink>
              </li>
              <li>
                <a
                  v-if="hasPermissions(['insertUser', 'deleteUser', 'manageUsers'])"
                  href="/users"
                  class="hover:opacity-80 whitespace-nowrap"
                  target="_self">
                  {{ $t('user', 2) }}
                </a>
              </li>
              <li>
                <Button
                  id="logout-button"
                  :variant="ButtonVariant.None"
                  :size="ButtonSizes.NONE"
                  class="cursor-pointer text-red-600 whitespace-nowrap"
                  @click="() => logout()">
                  {{ $t('logout') }}
                </Button>
              </li>
            </ul>
          </div>
          <div class="border-t flex flex-col px-4 py-2">
            <div class="flex justify-between py-2">
              <div class="cursor-pointer flex my-auto">
                <CollapseMenuButton class="cursor-pointer flex" />
              </div>
              <LanguageToggle />
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script setup lang="ts">
import {RouterLink} from 'vue-router';
import MenuItem from './menuItem/MenuItem.vue';
import Button, {ButtonSizes, ButtonVariant} from '@/components/ui/Button';
import LanguageToggle from '@/components/ui/LanguageToggle/LanguageToggle.vue';
import CollapseMenuButton from '@/components/ui/layout/sideMenu/CollapseMenuButton/CollapseMenuButton.vue';
import Divider from '@/components/ui/layout/sideMenu/Divider.vue';
import CloseButton from '@/components/ui/layout/sideMenu/closeButton/CloseButton.vue';
import useAuth from '@/composables/useAuth';
import useMenu from '@/composables/useMenu';
import useMobileMenu from '@/composables/useMobileMenu';

const {logout, hasPermissions} = useAuth();
const {mobileMenuOpen, toggleSidebar} = useMobileMenu();
const {getMenu} = useMenu();

const menu = getMenu();
</script>
