import type {
  SalesChannel,
  SalesChannelRequiringDelegation,
  SalesChannelWithIssuedDelegation,
  SalesChannelWithRequestedDelegation,
} from '@myparcel-frontend/mypa-api-services/order-etl';
import type {Ref} from 'vue';
import {
  isSalesChannelWithRequestedDelegation,
  isSalesChannelWithRequiringDelegation,
  isSalesChannelWoocommerce,
} from '@myparcel-frontend/mypa-api-services/order-etl';
import {defineStore} from 'pinia';
import {ref} from 'vue';
import {SalesChannelsStatus} from '@/types/salesChannels';

export interface UseSalesChannelStore {
  salesChannelList: Ref<SalesChannel[]>;
  addSalesChannel: (salesChannel: SalesChannel) => SalesChannel;
  updateSalesChannel: (salesChannel: SalesChannel) => SalesChannel | undefined;
  getSalesChannelById: (id: SalesChannel['id']) => SalesChannel | undefined;
  removeSalesChannel: (id: SalesChannel['id']) => boolean;
  updateSalesChannelToDelegationIssued: (salesChannel: SalesChannel) => SalesChannel | undefined;
  updateSalesChannelToDelegationRequested: (salesChannel: SalesChannel) => SalesChannel | undefined;
  updateSalesChannelToDelegationRequired: (salesChannel: SalesChannel) => SalesChannel | undefined;
}

export const useSalesChannelStore = defineStore('salesChannels', () => {
  const salesChannelList: UseSalesChannelStore['salesChannelList'] = ref<SalesChannel[]>([]);

  const addSalesChannel: UseSalesChannelStore['addSalesChannel'] = (salesChannel: SalesChannel) => {
    salesChannelList.value.push(salesChannel);
    return salesChannel;
  };

  const getSalesChannelById: UseSalesChannelStore['getSalesChannelById'] = (id: SalesChannel['id']) => {
    const res = salesChannelList.value.find((channel) => channel.id === id);
    return res;
  };

  const updateSalesChannel: UseSalesChannelStore['updateSalesChannel'] = (salesChannel: SalesChannel) => {
    salesChannelList.value = salesChannelList.value.map((channel) =>
      channel.id === salesChannel.id ? salesChannel : channel,
    );

    return getSalesChannelById(salesChannel.id);
  };

  const removeSalesChannel = (id: SalesChannel['id']): boolean => {
    const existingChannel = getSalesChannelById(id);

    if (!existingChannel) {
      return false;
    }

    salesChannelList.value = salesChannelList.value.filter((salesChannel) => salesChannel.id !== id);

    return true;
  };

  const updateSalesChannelToDelegationIssued: UseSalesChannelStore['updateSalesChannelToDelegationIssued'] = (
    salesChannel: SalesChannel,
  ) => {
    const {authorizeUrl, delegationError, ...restOfSalesChannel} = salesChannel;

    const updatedSalesChannel: SalesChannelWithIssuedDelegation = {
      ...restOfSalesChannel,
      status: SalesChannelsStatus.DelegationIssued,
      ecommercePlatform: {
        ...restOfSalesChannel.ecommercePlatform,
      },
    };

    return updateSalesChannel(updatedSalesChannel);
  };

  const updateSalesChannelToDelegationRequested: UseSalesChannelStore['updateSalesChannelToDelegationRequested'] = (
    salesChannel: SalesChannel,
  ) => {
    if (isSalesChannelWithRequiringDelegation(salesChannel) && salesChannel?.delegationError) {
      // eslint-disable-next-line
      const { delegationError, ...restOfSalesChannel } = salesChannel as SalesChannelRequiringDelegation;
      salesChannel = restOfSalesChannel;
    }

    if (isSalesChannelWoocommerce(salesChannel) && salesChannel.ecommercePlatform?.apiKeyLastNTokens) {
      const {apiKeyLastNTokens, apiSecretLastNTokens, ...ecommercePlatformRest} = salesChannel.ecommercePlatform;
      salesChannel.ecommercePlatform = ecommercePlatformRest;
    }

    const updatedSalesChannel = {
      ...salesChannel,
      status: SalesChannelsStatus.DelegationRequested,
      // TODO: make this value dynamic when the API return this value.
      authorizeUrl: 'https://authorize.url',
    } as SalesChannelWithRequestedDelegation;

    return updateSalesChannel(updatedSalesChannel);
  };

  const updateSalesChannelToDelegationRequired: UseSalesChannelStore['updateSalesChannelToDelegationRequired'] = (
    salesChannel: SalesChannel,
  ) => {
    if (isSalesChannelWithRequestedDelegation(salesChannel) && salesChannel?.authorizeUrl) {
      // eslint-disable-next-line
      const {authorizeUrl, ...restOfSalesChannel} = salesChannel as SalesChannelWithRequestedDelegation;
      salesChannel = restOfSalesChannel;
    }

    const updatedSalesChannel = {
      ...salesChannel,
      status: SalesChannelsStatus.DelegationRequired,
      ecommercePlatform: {
        ...salesChannel.ecommercePlatform,
        // TODO: make these values dynamic when the API returns these values.
        apiKeyLastNTokens: '1234',
        apiSecretLastNTokens: '5678',
      },
      // TODO: make these values dynamic when the API returns these values.
      delegationError: {
        details:
          '{"message":"Request failed with status code 404","response":{"code":"rest_no_route","message":"No route was found matching the URL and request method.","data":{"status":404}}}',
        reason: 'TestCallFailed',
      },
      extraction: {
        lastCycle: {
          extractedAt: '2024-05-07T12:00:00Z',
          successfullyExtractedAt: '2024-05-07T12:00:00Z',
          successfullyTransformedAndLoadedAt: '2024-05-07T12:00:00Z',
          transformedAndLoadedAt: '2024-05-07T12:00:00Z',
        },
        schedule: {
          transformAndLoad: false,
          unit: 'MINUTES',
          value: 5,
        },
      },
    } as SalesChannelRequiringDelegation;

    return updateSalesChannel(updatedSalesChannel);
  };

  return {
    salesChannelList,
    addSalesChannel,
    updateSalesChannel,
    getSalesChannelById,
    removeSalesChannel,
    updateSalesChannelToDelegationIssued,
    updateSalesChannelToDelegationRequested,
    updateSalesChannelToDelegationRequired,
  };
});
