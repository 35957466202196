<template>
  <div
    class="bg-transparent border-0 drop-down__item flex font-normal items-center justify-center text-black-light text-center w-full whitespace-nowrap">
    <slot />
  </div>
</template>

<style lang="scss">
.drop-down__item {
  &:hover,
  &:focus {
    @apply text-[#16181b] no-underline bg-[#f8f9fa];
  }

  & > * {
    @apply text-[#212529] w-full mx-0 py-2 px-6;
  }
}
</style>
