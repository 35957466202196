import type {Acl} from '@/providers/acl.types';
import type {Auth0Client} from '@auth0/auth0-spa-js';
import type {RouteLocationNormalized} from 'vue-router';
import createAuth0Client from '@auth0/auth0-spa-js';
import {SIDE_MENU_COLLAPSED} from '@/composables/useSidebarToggle';
import {useAclStore} from '@/store/aclStore/aclStore';
import {useConfigStore} from '@/store/config';
import {fetchFromCache, removeFromCache, setInCache} from '@/utils/cache';
import env from '@/utils/env';

const PERSISTENT_LOCALSTORAGE_KEYS = ['defaultCouponCode', 'returnUrl', 'campaign-id', SIDE_MENU_COLLAPSED];

let auth0: Auth0Client;

interface UseAuth {
  hasPermission: (permission: string) => boolean;
  hasPermissions: (permissions: string[]) => boolean;
  hasRoutePermission: (route: RouteLocationNormalized) => Promise<boolean>;
  getAcl: (refresh?: boolean) => Promise<void>;
  getAuth: () => Auth0Client;
  getToken: () => Promise<string>;
  setAuth: (scope: string) => Promise<Auth0Client>;
  logout: (returnTo?: string) => Promise<void>;
}

/**
 * Helper hook to manage login and logout functions.
 */

const useAuth = (): UseAuth => {
  const configStore = useConfigStore();
  const aclStore = useAclStore();

  /**
   * Fetches the ACL either from the cache or from the API.
   */
  const fetchAcl = async (): Promise<Acl> => {
    const token = await auth0.getTokenSilently();

    const response = await fetch(`${env.apiUrl}/acl`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json());
    setInCache('api/acl', response);
    return response;
  };

  const getToken = async (): Promise<string> => {
    const token = await auth0.getTokenSilently({
      scope: 'openid',
    });

    return token;
  };

  const hasPermission = (permission: string): boolean => {
    return configStore.acl.permissions.includes(permission);
  };

  const hasPermissions = (permissions: string[]): boolean => {
    return permissions.every((permission) => hasPermission(permission));
  };

  const getAcl = async (refresh = false): Promise<void> => {
    if (refresh) {
      removeFromCache('api/acl');
    }

    const acl = await fetchFromCache('api/acl', fetchAcl);
    configStore.setAcl(acl);
    aclStore.setAcl(acl);
  };

  /*
   * Checks if the given vue-router route has the permissions as defined in the ACL.
   * @param route The route to check, with meta.permissions array
   */
  const hasRoutePermission = async (route: RouteLocationNormalized): Promise<boolean> => {
    if (!Object.keys(configStore.acl).length) {
      await getAcl();
    }

    const routePermissions = (route.meta?.permissions as string[]) || [];
    return routePermissions.reduce((previous: boolean, current: any) => {
      return previous && hasPermission(current);
    }, true);
  };

  /*
   * Returns the auth0 instance.
   */
  const getAuth = (): Auth0Client => {
    return auth0;
  };

  /*
   * Initialize a new Auth0 client
   * @param scope - the scopes to use, primarily used for impersonation.
   */
  const setAuth = async (scope: string): Promise<Auth0Client> => {
    const configStore = useConfigStore();
    const redirectUri = `${window.location.origin}/auth/login/auth0/`;
    const clientId = env.auth0ClientId[configStore.config.platform_key] ?? env.auth0ClientId.default;
    auth0 = await createAuth0Client({
      domain: env.auth0Domain,
      client_id: clientId,
      redirect_uri: redirectUri,
      audience: env.auth0Audience,
      scope,
      cacheLocation: 'localstorage',
    });

    return auth0;
  };

  /*
   * Logs an user out of the app and auth0.
   * Also removes localstorage items, such as impersonated user.
   */
  const logout = async (returnTo?: string): Promise<void> => {
    const storageKeys: string[] = [];

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key) {
        storageKeys.push(key);
      }
    }

    storageKeys
      .filter((key: string) => {
        return !key.startsWith('features.') && !PERSISTENT_LOCALSTORAGE_KEYS.includes(key);
      })
      .forEach((key: string) => {
        localStorage.removeItem(key);
      });

    // forcibly remove the impersonated user, if there.
    window.localStorage.removeItem('impersonatedUserId');

    await auth0.logout({
      returnTo: returnTo ?? window.location.origin,
    });
  };

  return {
    hasPermission,
    hasPermissions,
    hasRoutePermission,
    getAcl,
    getAuth,
    getToken,
    setAuth,
    logout,
  };
};

export default useAuth;
