import type {SubscriptionCapability, SubscriptionProductIds, SubscriptionType} from '@/types/subscriptions';
import type {TierName} from '@/types/subscriptions/tiers';
import type {ComputedRef, Ref} from 'vue';
import {useSdk, Status} from '@myparcel-frontend/sdk';
import {storeToRefs} from 'pinia';
import {computed, ref} from 'vue';
import useSubscription from '@/composables/useSubscription/useSubscription';
import {useSubscriptionCapabilitiesStore} from '@/store/subscriptionCapabilitiesStore';
import {capitalize} from '@/utils/formatters/capitalize/capitalize';

interface UseSubscriptionCapabilities {
  subscriptionCapabilities: Ref<Record<SubscriptionType, SubscriptionCapability[]>>;
  getSubscriptionCapabilities: () => Promise<Ref<SubscriptionCapability[]>>;
  getSubscriptionTypeCapabilities: (
    subscriptionType: SubscriptionType,
  ) => Promise<ComputedRef<SubscriptionCapability[]>>;
  getSubscriptionCapabilityByProductId: (productId: SubscriptionProductIds) => Promise<SubscriptionCapability>;
  getSubscriptionCapabilityByName: (
    type: SubscriptionType,
    name: TierName,
  ) => Promise<SubscriptionCapability | undefined>;
  getActiveBundleCapability: () => Promise<SubscriptionCapability | undefined>;
  getHighestCapabilityForType: (type: SubscriptionType) => SubscriptionCapability;
  status: Ref<Status>;
}

const status = ref(Status.IDLE);

export default function useSubscriptionCapabilities(): UseSubscriptionCapabilities {
  const subscriptionCapabilitiesStore = useSubscriptionCapabilitiesStore();
  const {subscriptionCapabilities} = storeToRefs(subscriptionCapabilitiesStore);

  const fetchSubscriptionCapabilities = async () => {
    const {sdk} = useSdk();
    let result;

    status.value = Status.PENDING;

    try {
      result = await sdk.getSubscriptionsCapabilities();
      status.value = Status.RESOLVED;
    } catch (error) {
      status.value = Status.REJECTED;
      throw error;
    }

    return result;
  };

  const getSubscriptionCapabilities = async (): Promise<Ref<SubscriptionCapability[]>> => {
    if (Object.keys(subscriptionCapabilities.value).length) {
      return subscriptionCapabilities;
    }

    const newSubscriptionCapabilities = await fetchSubscriptionCapabilities();
    subscriptionCapabilitiesStore.setSubscriptionCapabilities(newSubscriptionCapabilities);

    return subscriptionCapabilities;
  };

  const getSubscriptionTypeCapabilities = async (subscriptionType: SubscriptionType) => {
    await getSubscriptionCapabilities();
    return computed(() => subscriptionCapabilities.value[subscriptionType]);
  };

  const getSubscriptionCapabilityByProductId: UseSubscriptionCapabilities['getSubscriptionCapabilityByProductId'] =
    async (productId: SubscriptionProductIds) => {
      await getSubscriptionCapabilities();

      const types = Object.values(subscriptionCapabilities.value);

      for (const type of types) {
        const found = type.find((capability: SubscriptionCapability) => capability.product_id === productId);

        if (found) {
          return found;
        }
      }

      return null;
    };

  const getSubscriptionCapabilityByName: UseSubscriptionCapabilities['getSubscriptionCapabilityByName'] = (
    type: SubscriptionType,
    name: TierName,
  ) => {
    const capabilities = subscriptionCapabilities.value[type];

    return capabilities.find((capability) => capability.tier_name === capitalize(name));
  };

  const getActiveBundleCapability: UseSubscriptionCapabilities['getActiveBundleCapability'] = async () => {
    const {activeBundlesSubscription, endingSoonBundlesSubscription} = useSubscription();

    if (!activeBundlesSubscription.value && !endingSoonBundlesSubscription.value) {
      return;
    }

    const activeBundlesSubscriptionProductId = await getSubscriptionCapabilityByProductId(
      activeBundlesSubscription.value?.product_id,
    );

    const endingSoonBundlesSubscriptionProductId = await getSubscriptionCapabilityByProductId(
      endingSoonBundlesSubscription.value?.product_id,
    );

    return activeBundlesSubscriptionProductId || endingSoonBundlesSubscriptionProductId;
  };

  const getHighestCapabilityForType = (type: SubscriptionType) => {
    if (!subscriptionCapabilities.value[type]) {
      return null;
    }

    return subscriptionCapabilities.value[type].reduce((highestCapability, capability) => {
      if (highestCapability?.tier > capability.tier) {
        return highestCapability;
      }

      return capability;
    }, null);
  };

  return {
    subscriptionCapabilities,
    getSubscriptionCapabilities,
    getSubscriptionTypeCapabilities,
    getSubscriptionCapabilityByProductId,
    getSubscriptionCapabilityByName,
    getActiveBundleCapability,
    getHighestCapabilityForType,
    status,
  };
}
