import type {CustomerRole, Role} from '@/providers/acl.types';
import {useSdk} from '@myparcel-frontend/sdk';
import useAccount from '@/composables/useAccounts';

interface UseAclRoles {
  getCustomerRoles: () => Promise<Role[]>;
  getConvertedCustomerRoles: () => Promise<CustomerRole>;
}

/**
 * Returns an object with the format {"has_<ROLE_NAME_WITHOUT_CUSTOMER>_role": boolean}. This is because appcues does
 * not accept arrays or nested objects, and since a customer can have multiple roles we have to give app cues it in
 * this manner.
 *
 * @param possibleUserRoles - All possible customer roles for this user.
 * @param currentUserRoles - The roles the user currently has.
 * @returns {CustomerRole} Object with roles the user possesses.
 * @todo februari 2023 - this is not great but it works, moved over from v1
 */
const convertRoles = (possibleUserRoles: Role[], currentUserRoles: string[]): CustomerRole => {
  return possibleUserRoles
    .map(({name}) => name)
    .concat('customer_main')
    .reduce(
      (accumulator: Record<string, boolean>, name: string) => ({
        ...accumulator,
        [`has_${name.replace('customer_', '')}_role`]: currentUserRoles.some((role: string) => role === name),
      }),
      {},
    );
};

export default function useAclRoles(): UseAclRoles {
  const getCustomerRoles = () => {
    const {sdk} = useSdk();

    return sdk.getAclRolesCustomer();
  };

  const getConvertedCustomerRoles = async () => {
    const {acl} = useAccount();

    const possibleUserRoles = await getCustomerRoles();

    return convertRoles(possibleUserRoles, acl.value.roles);
  };

  return {
    getCustomerRoles,
    getConvertedCustomerRoles,
  };
}
