<template>
  <slot />
</template>

<script setup lang="ts">
import {onMounted, unref, watch} from 'vue';
import {useI18n} from 'vue-i18n';
import {useRoute} from 'vue-router';
import useAccount from '@/composables/useAccounts';
import useAppcues from '@/composables/useAppcues';
import useHotjar from '@/composables/useHotjar';
import {debounce} from '@/utils';

const MINIMUM_WAITING_TIME = 500;

const {acl, account, uiSettings} = useAccount();
const {isInitialized, updateUserIdentity, initAppcues} = useAppcues();
const {initHotjar} = useHotjar();
const route = useRoute();
const {locale} = useI18n();

/**
 * Watcher to update the appcues identifier.
 * We will debounce all changes and wait a X minimum amount of time to handle all changes.
 */
/* c8 ignore start */
watch([uiSettings, acl, account, route, locale], () => {
  if (isInitialized()) {
    debounce(() => updateUserIdentity(unref(locale)), MINIMUM_WAITING_TIME);
  }
});
/* c8 ignore stop */

/* c8 ignore start */
onMounted(async () => {
  await Promise.all([initHotjar(), initAppcues(unref(locale))]);
});
/* c8 ignore stop */
</script>
