/* c8 ignore start */
import type {HttpHandler} from 'msw';
import {handlers as delegationHandlers} from '@myparcel-frontend/mypa-api-services/delegation';
import {handlers as orderEtlHandlers} from '@myparcel-frontend/mypa-api-services/order-etl';
import {mockHandlers as productHandlers} from '@myparcel-frontend/mypa-api-services/product';
import {http, passthrough} from 'msw';

const ignoreAllRoutes = [http.all('*', () => passthrough())];

export const handlers: HttpHandler[] = [
  ...delegationHandlers,
  ...orderEtlHandlers,
  ...productHandlers,
  ...ignoreAllRoutes,
];
