<!-- eslint-disable vue/no-bare-strings-in-template -->
<!--
  Root Error message. Similar to a kernel panic, displayed when things go wrong
  before the application router is loaded.
-->
<template>
  <DebugError v-if="error && showError">
    <template #title>Root Error</template>
    <p>An error has occurred:</p>
    <p>
      <code>{{ error }}</code>
    </p>
  </DebugError>
  <RootError v-else-if="error" />
  <div v-else-if="empty" />
  <slot v-else />
</template>

<script setup lang="ts">
import {computed, onErrorCaptured, ref} from 'vue';
import DebugError from '@/components/ui/DebugError/DebugError.vue';
import RootError from '@/components/ui/RootError/RootError.vue';
import environment from '@/utils/env';
import {featureEnabledInLocalStorage} from '@/utils/features';
import log from '@/utils/logging';

const error = ref();
const empty = ref(false);

onErrorCaptured((err: Error) => {
  error.value = err;

  // Exception for Data Provider / Auth to not display the thrown error when redirecting:
  if (err.error === 'login_required') {
    error.value = null;
    empty.value = true;
  }

  if (error.value) {
    log(err, {boundary: 'Root'});
  }

  // throw error to console in dev mode, otherwise not.
  return environment.devMode;
});

const showError = computed(() => {
  return environment.devMode || featureEnabledInLocalStorage('features.errors');
});
</script>
