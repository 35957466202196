import type {ConfigInterface} from '@/providers/platformConfig.types';
import {CarrierId} from '@myparcel/constants';
import {BELGIUM, NETHERLANDS} from '@myparcel/constants/countries';
import {ContractId} from '@/constants/contracts';
import {ConfigKey} from '@/providers/platformConfig.types';
import {useConfigStore} from '@/store/config';

interface UsePlatform extends ConfigInterface {
  isMyParcelNl: () => boolean;
  isMyParcelBe: () => boolean;
  getHomeCountryCode: () => BELGIUM | NETHERLANDS;
  isHomeCountry: (countryCode: string) => boolean;
  getDefaultCarrierId: (options?: {isReturn?: boolean; cc?: BELGIUM | NETHERLANDS}) => CarrierId;
  getDefaultContractId: () => ContractId;
}

/**
 * Helper hook to manage the current Platform Configuration.
 */
export default function usePlatform(): UsePlatform {
  const configStore = useConfigStore();

  const isMyParcelNl: UsePlatform['isMyParcelNl'] = () => {
    return configStore.config.translation_key === ConfigKey.MYPARCEL_NL;
  };

  const isMyParcelBe: UsePlatform['isMyParcelBe'] = () => {
    return configStore.config.translation_key === ConfigKey.MYPARCEL_BE;
  };

  const getHomeCountryCode: UsePlatform['getHomeCountryCode'] = () => {
    if (isMyParcelBe()) {
      return BELGIUM;
    }

    return NETHERLANDS;
  };

  const isHomeCountry: UsePlatform['isHomeCountry'] = (countryCode) => {
    return countryCode === getHomeCountryCode();
  };

  const getDefaultCarrierId: UsePlatform['getDefaultCarrierId'] = ({
    isReturn = false,
    cc = getHomeCountryCode(),
  } = {}) => {
    if (isMyParcelBe()) {
      if (isReturn && cc === NETHERLANDS) {
        return CarrierId.PostNl;
      }

      return CarrierId.Bpost;
    }

    return CarrierId.PostNl;
  };

  const getDefaultContractId: UsePlatform['getDefaultContractId'] = () => {
    if (isMyParcelBe()) {
      return ContractId.Bpost;
    }

    return ContractId.PostNl;
  };

  return {
    isMyParcelNl,
    isMyParcelBe,
    getHomeCountryCode,
    isHomeCountry,
    getDefaultCarrierId,
    getDefaultContractId,
    ...configStore.config,
  };
}
