export const SubscriptionFeature = {
  AllowCustomContracts: 'allow_custom_contracts',
  AllowBolDotComVvbShipments: 'allow_bol_dot_com_vvb_shipments',

  AllowAutoOrderImport: 'allow_auto_order_import',
  AllowOrderNotes: 'allow_order_notes',
  AllowUnlimitedShipmentsPerOrderLine: 'allow_unlimited_shipments_per_order_line',
  AllowOrderInvoice: 'allow_order_invoice',
  AllowOrderPicklist: 'allow_order_picklist',
  AllowNestedShipmentLabelOnPackingSlip: 'allow_nested_shipment_label_on_packing_slip',
  AllowShopLogoOnPackingSlip: 'allow_shop_logo_on_packing_slip',
  SaveOrderSortingSetting: 'save_order_sorting_setting',

  AllowTier1Analytics: 'allow_tier1_analytics',
  AllowTier2Analytics: 'allow_tier2_analytics',
  AllowTier3Analytics: 'allow_tier3_analytics',
  AllowTier4Analytics: 'allow_tier4_analytics',

  AllowTrackTraceEmailSecondaryRecipients: 'allow_secondary_recipients_track_trace_email',
  AllowBulkShipmentEdits: 'allow_bulk_shipment_edits',
  AllowBulkShipmentsInAddressBook: 'allow_bulk_shipments_in_address_book',

  ExtendedShippingRules: 'extended_shipping_rules',
  UnlimitedShippingRules: 'unlimited_shipping_rules',

  ShipmentDiscount: 'shipment_discount',
} as const;
