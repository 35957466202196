<!-- eslint-disable vue/no-bare-strings-in-template -->
<template>
  <DebugError v-if="showError">
    <template #title>Environment error</template>
    <p>
      The application is not running in the correct environment. Please ensure the application is run or built with
      valid environment variables.
    </p>
    <p>Warning:</p>
    <pre><slot /></pre>
  </DebugError>
  <RootError v-else />
</template>

<script setup lang="ts">
import {computed} from 'vue';
import DebugError from '@/components/ui/DebugError/DebugError.vue';
import RootError from '@/components/ui/RootError/RootError.vue';
import env from '@/utils/env';
import {featureEnabledInLocalStorage} from '@/utils/features';

const showError = computed(() => {
  return env.devMode || featureEnabledInLocalStorage('features.errors');
});
</script>
