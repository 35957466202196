import type {ToRefs} from 'vue';
import {useSdk} from '@myparcel-frontend/sdk';
import {storeToRefs} from 'pinia';
import {useConfigStore} from '@/store/config';

/**
 * Helper hook to manage UI settings from the API to the store.
 * Additional methods:
 * - getUiSettings: get the UI settings from the API, and put it in the store.
 * - putUiSettings: put the UI settings in the API and in the store.
 */
export default function useUiSettings(): ToRefs<typeof useConfigStore.state.uiSettings.uiSettings> {
  const configStore = useConfigStore();
  const refs = storeToRefs(configStore);

  const getUiSettings = async (): Promise<void> => {
    if (Object.keys(configStore.uiSettings).length) {
      return;
    }

    const {sdk} = useSdk();
    const uiSettings = await sdk.getUiSettings({path: {account_ids: configStore.acl.account_id}});
    configStore.setUiSettings(uiSettings[0]);
  };

  const putUiSettings = async (body: Record<string, unknown>): Promise<void> => {
    const {sdk} = useSdk();
    const result = await sdk.putUiSettings(body);
    configStore.setUiSettings(result[0]);
  };

  return {putUiSettings, getUiSettings, ...refs};
}
