import type {ComponentInternalInstance} from 'vue';
import type {Router} from 'vue-router';
import {createGtm} from '@gtm-support/vue-gtm';
import useGoogleTagManager from '@/composables/useGoogleTagManager';
import usePlatform from '@/composables/usePlatform';
import env from '@/utils/env';

export const setGoogleTagManager = (instance: ComponentInternalInstance, router: Router): boolean => {
  if (instance.appContext.provides.gtm) {
    return false;
  }

  const {googleTagManagerId} = usePlatform();
  const {loadGtm} = useGoogleTagManager();

  if (env.devMode) {
    return false;
  }

  if (!googleTagManagerId) {
    throw new Error('"googleTagManagerId" is not defined');
  }

  // See https://github.com/gtm-support/vue-gtm for more information.
  const gtm = createGtm({
    id: googleTagManagerId,
    defer: false,
    compatibility: false,
    enabled: true,
    debug: true,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
  });

  instance.appContext.app.use(gtm);
  loadGtm();
  return true;
};
