<template>
  <li
    class="active:bg-primary active:text-white hover:bg-gray-200 px-5 py-2 text-black text-center"
    @click="close">
    <slot />
  </li>
</template>

<script setup lang="ts">
import {inject} from 'vue';

const close = inject<() => {}>('closeDropDownMenu');
</script>
