import type {ConfigKey} from '@/providers/platformConfig.types';
import {Hosts} from '@/providers/platformConfig.types';

/**
 * Get the config for the current domain.
 * If there are multiple chained subdomains, try to match the longest one.
 * If no config is found, return the default config.
 *
 * @param hostMapping - The mapping fetched by fetchMapping() above.
 * @param hostName - The hostname of the current page.
 * @returns The config key for the current page.
 */
export const hostNameMatcher = (hostMapping: Record<string, ConfigKey>, hostName: string): ConfigKey => {
  if (hostMapping[hostName]) {
    return hostMapping[hostName];
  }

  const slices = hostName.split('.');

  if (slices.length === 1) {
    throw new Error('No config found for hostname.');
  }

  const subHost = slices.splice((slices.length - 1) * -1);
  return hostNameMatcher(hostMapping, subHost.join('.'));
};

/**
 * Get the hostname of the page. Overriden by environment variable if set.
 *
 * @returns The hostname of the page.
 */
export const getHostName = (): string => {
  // do NOT change to env.hostname or something, as this is only for development.
  if (import.meta.env.VITE_APP_HOSTNAME) {
    return import.meta.env.VITE_APP_HOSTNAME;
  }

  if (window?.location?.host) {
    return window.location.host;
  }

  return Hosts.DEFAULT;
};
