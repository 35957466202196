import type {ExternalProvider} from '@myparcel-frontend/types';
import {useSdk, Status} from '@myparcel-frontend/sdk';
import {ExternalProviderId} from '@myparcel-frontend/types';
import {computed, ref} from 'vue';
import {useConfigStore} from '@/store/config';
import {useExternalProviderCredentialsStore} from '@/store/externalProviderCredentialsStore/externalProviderCredentialsStore';
import {getExpireDate} from '@/utils/credentials/getExpireDate';
import {dayjs} from '@myparcel-frontend/utils';

const status = ref(Status.IDLE);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useBolCredentials() {
  const configStore = useConfigStore();
  const {
    getValidExternalProviderCredentials,
    addExternalProviderCredentials,
    removeExternalProviderCredentials,
    updateExternalProviderCredentials,
  } = useExternalProviderCredentialsStore();

  const bolCredentials = computed(() => {
    const externalProviderCredentials = getValidExternalProviderCredentials();
    return externalProviderCredentials.filter((externalProviderCredential) => {
      return externalProviderCredential.external_integration_provider_id === ExternalProviderId.Bol;
    });
  });

  const activeShopBolCredentials = computed(() => {
    return bolCredentials.value.filter((credential) => {
      return credential.shop_id === configStore.currentShop.id;
    });
  });

  const isActiveShopBolCredentialsExpired = computed(() => {
    if (activeShopBolCredentials.value.length === 0) {
      return false;
    }

    const expirationDate = getExpireDate(activeShopBolCredentials.value[0]);
    return dayjs(expirationDate).isBefore(dayjs());
  });

  const isActiveShopBolCredentialsExpiringSoon = computed(() => {
    if (activeShopBolCredentials.value.length === 0) {
      return false;
    }

    const expirationDate = getExpireDate(activeShopBolCredentials.value[0]);
    return dayjs(expirationDate).isBefore(dayjs().add(2, 'week')) && !isActiveShopBolCredentialsExpired.value;
  });

  /**
   * @TODO MY-37959 - Update this function to fetch credentials for multiple shops at once.
   */
  const fetchBolCredentials = async () => {
    const {sdk} = useSdk();

    const shopIds = configStore.account.shops.map((shop) => shop.id);
    const shopCredentials: Record<number, ExternalProvider[]> = {};

    try {
      const credentials = await Promise.all(
        shopIds.map((shopId) => sdk.getExternalIntegrationsProviderCredentialsBol({parameters: {shop_id: shopId}})),
      );
      credentials.forEach((credential, index) => {
        shopCredentials[shopIds[index]] = credential;
      });

      status.value = Status.RESOLVED;
    } catch (error) {
      status.value = Status.REJECTED;
      throw error;
    }

    for (const [shopId, credentials] of Object.entries(shopCredentials)) {
      credentials.forEach((credential) => {
        credential.shop_id = Number(shopId);
      });
    }

    return Object.values(shopCredentials).flat();
  };

  const fetchBolCredentialsForShop = async (shopId: number) => {
    const {sdk} = useSdk();

    let credentials;

    try {
      credentials = await sdk.getExternalIntegrationsProviderCredentialsBol({parameters: {shop_id: shopId}});
      credentials.forEach((credential) => {
        credential.shop_id = shopId;
      });
      status.value = Status.RESOLVED;
    } catch (error) {
      status.value = Status.REJECTED;
      throw error;
    }

    return credentials;
  };

  const getBolCredentials = async () => {
    if (!bolCredentials.value.length) {
      const credentials = await fetchBolCredentials();
      addExternalProviderCredentials(credentials);
    }

    return bolCredentials;
  };

  const connectBol = async (shopId, returnUrl) => {
    const {sdk} = useSdk();

    const response = await sdk.postExternalIntegrationsProviderCredentialsBol({
      body: [
        {
          shop_id: shopId,
          credentials: {},
        },
      ],
    });

    localStorage.setItem('returnUrl', returnUrl);
    window.location.assign(new URL(response));
  };

  const deleteBolCredentials = async (externalProvider: ExternalProvider) => {
    const {sdk} = useSdk();

    try {
      await sdk.deleteExternalIntegrationsProviderCredentialsBol({
        path: {
          id: externalProvider.id,
        },
      });
      removeExternalProviderCredentials(externalProvider);
      status.value = Status.RESOLVED;
    } catch (error) {
      status.value = Status.REJECTED;
      throw error;
    }
  };

  const getBolCredentialsForShop = async (shopId: number) => {
    const credentials = bolCredentials.value.filter((credential) => credential.shop_id === shopId);

    if (credentials.length) {
      return credentials;
    }

    const fetchedCredentials = await fetchBolCredentialsForShop(shopId);
    addExternalProviderCredentials(fetchedCredentials);
    return bolCredentials.value.filter((credential) => credential.shop_id === shopId);
  };

  const updateBolCredentials = async (credentials: ExternalProvider) => {
    const {sdk} = useSdk();

    try {
      await sdk.putExternalIntegrationsProviderCredentialsBol({
        body: [
          {
            auto_import: credentials.auto_import,
            credentials: credentials.credentials,
            id: credentials.id,
            shop_id: credentials.shop_id,
          },
        ],
      });
      updateExternalProviderCredentials(credentials);
      status.value = Status.RESOLVED;
    } catch (error) {
      status.value = Status.REJECTED;
      throw error;
    }
  };

  return {
    bolCredentials,
    activeShopBolCredentials,
    isActiveShopBolCredentialsExpired,
    isActiveShopBolCredentialsExpiringSoon,
    connectBol,
    deleteBolCredentials,
    getBolCredentials,
    getBolCredentialsForShop,
    updateBolCredentials,
  };
}
