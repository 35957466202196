<!-- Here no providers are assumed, so no translations or platform is available. -->
<!-- eslint-disable vue/no-bare-strings-in-template -->
<template>
  <div class="flex h-screen items-center justify-center">
    <div class="flex flex-col gap-7 w-[600px]">
      <div class="bg-primary flex h-[70px] items-center px-4">
        <BaseImage
          src="https://assets.myparcel.nl/skin/images/logo.svg"
          alt="Myparcel" />
      </div>
      <div class="px-4">
        <p>
          Er is een probleem opgetreden bij het laden van de pagina. Probeer het later opnieuw, of neem contact op met
          de klantenservice. Onze excuses voor het ongemak.
        </p>
      </div>
      <div class="px-4">
        <p>
          A problem occurred while trying to load the page. Please try again later or contact customer service. We are
          sorry for the inconvenience.
        </p>
      </div>
      <div class="px-4">
        <p>
          Un problème est survenu lors de la tentative de chargement de la page. Veuillez réessayer plus tard ou
          contacter le service client. Nous sommes désolés du dérangement.
        </p>
      </div>
      <div class="flex gap-4 px-4">
        <Button
          variant="primary"
          data-test="button home"
          @click="homepage">
          MyParcel Homepage
        </Button>
        <Button
          variant="primary"
          data-test="button support"
          @click="support">
          MyParcel Support
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {BaseImage} from '@myparcel-frontend/ui';
import Button from '@/components/ui/Button';

const homepage = (): void => window.location.assign('https://www.myparcel.nl/');
const support = (): void => window.location.assign('https://www.myparcel.nl/support/');
</script>
