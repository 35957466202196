<template>
  <Teleport
    v-if="isModalOpen"
    to="#global-modals-container">
    <div
      v-show="isTopModal"
      tabindex="-1"
      data-test="modal-wrapper"
      class="fixed flex h-full justify-center left-0 max-h-[100%] top-0 w-screen z-50">
      <div
        v-show="isTopModal"
        data-test="modal-backdrop"
        class="bg-black fixed flex h-screen justify-center left-0 opacity-50 top-0 w-screen"
        @click="handleClose" />
      <div
        v-show="isTopModal"
        ref="modalRef"
        data-test="modal-container"
        :class="containerClass"
        class="fixed grow h-full max-w-2xl md:h-auto p-4 w-full"
        aria-modal="true"
        :aria-labelledby="ariaLabelledBy"
        :aria-describedby="ariaDescribedBy"
        role="dialog">
        <div
          class="bg-white modal-content p-3 px-3.5 rounded-lg shadow"
          :class="contentClass"
          :data-test="dataTest">
          <slot />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import {watch} from 'vue';
import {useModal} from '@/composables/useModal';
import {useModalFocus} from '@/composables/useModalFocus';

// TODO: defineProps destructuring FE-1574
const props = defineProps<{
  name: string;
  containerClass?: string;
  contentClass?: string;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
  dataTest?: string;
  onClose?: () => void;
}>();

const {isModalOpen, isTopModal} = useModal(props.name);
const {modalRef} = useModalFocus(props.name);

const handleClose = (): void => {
  if (props.onClose) {
    props.onClose();
  }
};

const closeOnEscape = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    handleClose();
  }
};

watch([isModalOpen, isTopModal], ([isOpen, isTop]) => {
  if (isOpen && isTop) {
    document.addEventListener('keyup', closeOnEscape);
  } else {
    document.removeEventListener('keyup', closeOnEscape);
  }
});
</script>
