<template>
  <slot />
</template>

<script setup lang="ts">
import useFeatures from '@/composables/useFeatures';

const {loadFeatureToggles} = useFeatures();
loadFeatureToggles();
</script>
