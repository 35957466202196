import {ref} from 'vue';
import useAccount from '@/composables/useAccounts';
import useSubscription from '@/composables/useSubscription';
import {useConfigStore} from '@/store/config';
import env from '@/utils/env';

interface UseHotjar {
  triggerEvent: (value: string) => boolean;
  triggerHotjarIdentify: () => boolean;
  initHotjar: (hotjarId?: string, snippetVersion?: number) => boolean;
  setInitialized: (status: boolean) => boolean;
}

declare global {
  interface Window {
    hj: (event: string, value: string, data?: object) => unknown;
    _hjSettings: {
      hjid: string;
      hjsv: number;
    };
  }
}

const scriptInitialized = ref(false);
const defaultSnippetVersion = 7;

const useHotjar = (): UseHotjar => {
  const configStore = useConfigStore();

  const initHotjar = (hotjarId?: string, snippetVersion?: number): boolean => {
    const hotjarIdToInit = hotjarId ?? env.hotjar[configStore.getPlatformKey] ?? env.hotjar.default;
    snippetVersion = snippetVersion ?? defaultSnippetVersion;

    if (env.devMode) {
      // eslint-disable-next-line no-console
      console.warn('Hotjar was not initialized because of devMode');
      return false;
    }

    if (!hotjarIdToInit) {
      return false;
    }

    /**
     * Initialize the Hotjar script.
     * @link https://help.hotjar.com/hc/en-us/articles/4412561401111#h_01HHGX8RT0Y0QWJP8GSE2XPZS9
     */
    window.hj =
      window.hj ||
      /* c8 ignore start */
      function () {
        (window.hj.q = window.hj.q || []).push(arguments);
      };
    /* c8 ignore stop */

    // eslint-disable-next-line no-underscore-dangle
    window._hjSettings = {
      hjid: hotjarIdToInit,
      hjsv: snippetVersion,
    };

    const script = document.createElement('script');
    script.async = true;
    script.src = `https://static.hotjar.com/c/hotjar-${hotjarIdToInit}.js?sv=${snippetVersion}`;

    const headElement = document.getElementsByTagName('head')[0];
    headElement.appendChild(script);

    scriptInitialized.value = true;

    return true;
  };

  /**
   * Trigger events in Hotjar
   */
  const triggerEvent = (value: string): boolean => {
    if (!scriptInitialized.value) {
      if (env.devMode) {
        // eslint-disable-next-line no-console
        console.warn(`Hotjar event: ${value} is not triggered. The script is not initiliazed.`);
      }

      return false;
    }

    if (typeof window.hj === 'undefined') {
      return false;
    }

    window.hj('event', value);
    return true;
  };

  const setInitialized = (status: boolean): boolean => {
    scriptInitialized.value = status;

    return scriptInitialized.value;
  };

  /**
   * Gets the identification data for the Hotjar identify API.
   *
   * @returns {Object}
   */
  const getIdentificationData = () => {
    const {account, acl} = useAccount();
    const {activeBundlesSubscription} = useSubscription();

    // @see https://help.hotjar.com/hc/en-us/articles/360033640653-Identify-API-Reference#user_attribute_values
    return {
      created: `${account.value.created.split(' ')[0]}Z`,
      role: acl.value.roles[0],
      subscription_type: activeBundlesSubscription.value ?? null,
      account_id: account.value.id,
    };
  };

  /**
   * Triggers the Hotjar identify API which pushes the event to Hotjar.
   *
   * @see https://help.hotjar.com/hc/en-us/articles/360038394053-How-to-Set-Up-User-Attributes
   */
  const triggerHotjarIdentify = () => {
    if (!scriptInitialized.value) {
      if (env.devMode) {
        // eslint-disable-next-line no-console
        console.warn(`Hotjar identify API is not triggered. The script is not initialized in devMode.`);
      }

      return false;
    }

    if (typeof window.hj === 'undefined') {
      return false;
    }

    const {currentUser} = useAccount();
    const userId = currentUser.value.id;
    const data = getIdentificationData();

    window.hj('identify', userId, {
      ...data,
    });

    return true;
  };

  return {
    triggerEvent,
    triggerHotjarIdentify,
    initHotjar,
    setInitialized,
  };
};

export default useHotjar;
