import type {CarrierOption, ShopCarrierOptions} from '@/types/CarrierOption';
import {defineStore} from 'pinia';
import {readonly, ref} from 'vue';

export const useCarrierStore = defineStore('carriers', () => {
  const accountCarriers = ref<CarrierOption[]>([]);
  const shopsCarriers = ref<ShopCarrierOptions>();

  const getAccountCarriers = () => {
    return readonly(accountCarriers);
  };

  const setAccountCarriers = (newCarriers: CarrierOption[]) => {
    accountCarriers.value = newCarriers;
  };

  const getShopCarriers = (shopId: number) => {
    if (!shopsCarriers.value) {
      return [];
    }

    return shopsCarriers.value[shopId] ?? [];
  };

  const setShopCarriers = (shopId: number, newCarriers: CarrierOption[]) => {
    if (!shopsCarriers.value) {
      shopsCarriers.value = {[shopId]: newCarriers};
      return;
    }

    shopsCarriers.value[shopId] = newCarriers;
  };

  const updateShopCarrier = (shopId: number, carrierOption: Partial<CarrierOption>) => {
    if (!shopsCarriers.value?.[shopId]) {
      return;
    }

    shopsCarriers.value[shopId] = shopsCarriers.value[shopId].map((contract) => {
      if (contract.id === carrierOption.id) {
        return {...contract, ...carrierOption};
      }

      return contract;
    });
  };

  const deleteShopCarrier = (shopId: number, contractId: number) => {
    if (!shopsCarriers.value?.[shopId]) {
      return;
    }

    shopsCarriers.value[shopId] = shopsCarriers.value[shopId].filter((contract) => contract.id !== contractId);
  };

  return {
    getAccountCarriers,
    setAccountCarriers,
    getShopCarriers,
    setShopCarriers,
    updateShopCarrier,
    deleteShopCarrier,
    accountCarriers,
  };
});
