import type {SubscriptionCapabilities, SubscriptionType} from '@/types/subscriptions';
import {set} from '@vueuse/core';
import {defineStore} from 'pinia';
import {ref} from 'vue';

export const useSubscriptionCapabilitiesStore = defineStore('subscriptionCapabilities', () => {
  const subscriptionCapabilities = ref<Record<SubscriptionType, SubscriptionCapabilities[]>>({});

  const setSubscriptionCapabilities = (
    newSubscriptionCapabilities: Record<SubscriptionType, SubscriptionCapabilities[]>,
  ) => {
    set(subscriptionCapabilities, newSubscriptionCapabilities);
  };

  return {
    subscriptionCapabilities,
    setSubscriptionCapabilities,
  };
});
