import type {Ref} from 'vue';
import {ref} from 'vue';

interface UsePageTitle {
  pageTitleStatus: Ref<boolean>;
  setPageTitle: (title: string) => void;
  resetPageTitle: () => void;
}

const pageTitleSet = ref(false);

/**
 * Helper function to set the Page Title. See Contributing.md for details.
 */
export default function usePageTitle(): UsePageTitle {
  const pageTitleStatus = pageTitleSet;

  const setPageTitle: UsePageTitle['setPageTitle'] = (title) => {
    document.title = title;
    pageTitleSet.value = true;
  };

  const resetPageTitle: UsePageTitle['resetPageTitle'] = () => {
    pageTitleSet.value = false;
  };

  return {
    pageTitleStatus,
    setPageTitle,
    resetPageTitle,
  };
}
