/* eslint-disable @typescript-eslint/no-magic-numbers */
import {convertEnumValue} from '@myparcel/constants/helpers';
import {TierName} from '@/types/subscriptions/tiers';

export const BundlesProductId = {
  Start: 4800,
  Plus: 4801,
  Premium: 4802,
  Max: 4803,
} as const;

export const BundlesTierName = {
  Start: TierName.Start,
  Plus: TierName.Plus,
  Premium: TierName.Premium,
  Max: TierName.Max,
} as const;

export const BUNDLES_IDS_TO_NAMES = {
  [BundlesProductId.Start]: BundlesTierName.Start,
  [BundlesProductId.Plus]: BundlesTierName.Plus,
  [BundlesProductId.Premium]: BundlesTierName.Premium,
  [BundlesProductId.Max]: BundlesTierName.Max,
};

export const BUNDLES_NAMES_TO_IDS = {
  [BundlesTierName.Start]: BundlesProductId.Start,
  [BundlesTierName.Plus]: BundlesProductId.Plus,
  [BundlesTierName.Premium]: BundlesProductId.Premium,
  [BundlesTierName.Max]: BundlesProductId.Max,
};

export const toBundlesTierName = (id: typeof BundlesProductId): typeof BundlesTierName => {
  return convertEnumValue(BUNDLES_IDS_TO_NAMES, id);
};

export const toBundlesProductId = (name: typeof BundlesTierName): typeof BundlesProductId => {
  return convertEnumValue(BUNDLES_NAMES_TO_IDS, name);
};
