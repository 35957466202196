import type {Ref} from 'vue';
import {readonly, ref} from 'vue';

export enum NotificationVariant {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'alert',
}

export enum NotificationSize {
  Full = 'full',
  Half = 'half',
}

export enum NotificationLocation {
  Toaster = 'toaster',
}

export interface NotificationItem {
  id?: string;
  body: string;
  title?: string;
  variant: NotificationVariant | string;
  canClose?: boolean;
  withShadow?: boolean;
  timeout?: number | null;
  location?: NotificationLocation | string;
}

interface UseNotifications {
  notifications: Readonly<Ref<NotificationItem[]>>;
  addNotification: (notification: NotificationItem) => void;
  removeNotification: (id: string) => void;
  clearNotifications: () => void;
}

const notifications = ref([]) as Ref<NotificationItem[]>;
const count = ref(0);

/**
 * Helper hook to manage toaster notifications throughout the app.
 * Currently does not use the store.
 * Methods:
 * - addNotification: Show a new notification
 * - removeNotification: Remove a notification.
 */
export default function useNotifications(): UseNotifications {
  const DEFAULT_NOTIFICATION_TIMEOUT = 6000;

  const addNotification = ({
    id,
    variant = NotificationVariant.Info,
    canClose,
    withShadow,
    body,
    title,
    timeout = DEFAULT_NOTIFICATION_TIMEOUT,
    location = NotificationLocation.Toaster,
  }: {
    id?: string;
    variant: NotificationVariant | string;
    body: string;
    title?: string;
    timeout?: number | null;
    location?: NotificationLocation | string;
    canClose?: boolean;
    withShadow?: boolean;
  }): void => {
    let newId = id;

    if (!newId) {
      count.value++;
      newId = count.value.toString();
    }

    notifications.value.unshift({
      id: newId,
      body,
      variant,
      title,
      timeout,
      location,
      canClose,
      withShadow,
    });

    if (timeout) {
      setTimeout(() => {
        removeNotification(newId);
      }, timeout);
    }
  };

  const removeNotification = (id: string): void => {
    notifications.value = notifications.value.filter((notification) => notification.id !== id);
  };

  const clearNotifications = (): void => {
    notifications.value = [];
  };

  return {
    notifications: readonly(notifications),
    addNotification,
    removeNotification,
    clearNotifications,
  };
}
