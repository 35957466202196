<script lang="ts">
import {get} from '@vueuse/core';
import {defineComponent, watch} from 'vue';
import {useI18n} from 'vue-i18n';
import {useRoute} from 'vue-router';
import usePageTitle from '@/composables/usePageTitle';

export default defineComponent({
  name: 'PageTitle',
  setup() {
    const {setPageTitle, pageTitleStatus} = usePageTitle();
    const {t} = useI18n();
    const route = useRoute();

    const updateTitle = () => {
      if (!get(pageTitleStatus)) {
        const title = route.meta.title ? `MyParcel - ${route.meta.title(t)}` : 'MyParcel';
        setPageTitle(title);
      }
    };

    watch(
      route,
      () => {
        updateTitle();
      },
      {
        immediate: true,
      },
    );
  },

  render() {
    return null;
  },
});
</script>
