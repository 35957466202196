import type {ExternalProviderName} from '@myparcel-frontend/types';

export enum Hosts {
  MYPARCEL_NL = 'myparcel.nl',
  MYPARCEL_BE = 'sendmyparcel.be',
  LOCALHOST = 'localhost:5050',
  DEFAULT = 'default',
}

export enum ConfigKey {
  DEFAULT = 'default',
  MYPARCEL_NL = 'myparcel-nederland',
  MYPARCEL_BE = 'myparcel-belgie',
}

export type ConfigFile = Record<ConfigKey, unknown>;

export interface DefaultConfigInterface {
  warnForMissingTranslations: boolean;
}

export interface ServicesInterface {
  appcuesIsReady: boolean;
}

interface Authenticators {
  [key: string]: {
    name: string;
    url: string;
  };
}

export interface PlatformConfigInterface {
  apiHeaders: {
    userAgent: string;
    useStrictSSL: boolean;
  };
  appCuesAccountId: string;
  assetsUrl: string;
  address: {
    street: string;
    postalCode: string;
  };
  centerOfCountry: {
    latitude: number;
    longitude: number;
    zoom: number;
  };
  chromeExtensionUrl: string;
  coc: string;
  default_language: string;
  defaultCarrierId: number;
  defaultDestinationCountry: string;
  googleTagManagerId: string;
  hereMapsCssUrl: string;
  hereMapsCoreScript: string;
  hereMapsScripts: string[];
  hereMapsPostalCodeUrl: string;
  logoPath: string;
  logoSmallPath: string;
  name: string;
  platform_id: number;
  maxFreeShippingRules: number;
  manuals: {
    contracts: {
      dpd: string;
      dhl: string;
    };
  };
  newInvoiceDate: {
    weekly: string;
    monthly: string;
  };
  platform_key: string;
  paymentProvider: string;
  priceScalesPolicy: string;
  short_name: string;
  supported_languages: string[];
  supportedFrontofficeLanguages: string[];
  translation_key: ConfigKey;
  warnForMissingTranslations: boolean;
  shippingRulesDefaults: {
    country?: string;
    region?: string;
  }[];
  supportUrl: string;
  phoneNumber: {
    text: string;
    plain: string;
  };
  whatsappUrl: string;
  retourInfoUrl: string;
  dhlTodayPointsUrl: string;
  externalIntegrationSupportUrls: Record<ExternalProviderName, string>;
  services: {
    cookieBanner: string;
  };
  authenticators: Authenticators;
}

export type ConfigInterface = DefaultConfigInterface & PlatformConfigInterface;
