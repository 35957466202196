<template>
  <slot />
</template>

<script lang="ts">
import type {EnumKeysAsString} from '@/types';
import type {AvailableLanguages, SetupPluginOptions} from '@/types/i18n.types';
import type {I18n, I18nOptions} from 'vue-i18n';
import {getCurrentInstance} from 'vue';
import {Translation, createI18n} from 'vue-i18n';
import enTranslations from '@/assets/translations/en.json';
import frTranslations from '@/assets/translations/fr.json';
import nlTranslations from '@/assets/translations/nl.json';
import {useConfigStore} from '@/store/config';
import {mergeDeep} from '@/utils/merge';

const messages = {
  en: enTranslations,
  nl: nlTranslations,
  fr: frTranslations,
};

type Language = EnumKeysAsString<typeof AvailableLanguages>;

type PlatformMessages = Record<Language, Record<string, string>>;

const createOptions = (options: SetupPluginOptions): I18nOptions => {
  const parsedPlatformMessages = (): Partial<PlatformMessages> => {
    const parsedPlatformMessages: Partial<PlatformMessages> = {};

    if (!options.supportedLanguages) {
      return parsedPlatformMessages;
    }

    options.supportedLanguages.forEach((languageKey: Language) => {
      const translations = messages[languageKey];

      const defaultTranslations = translations?.default ?? {};
      const platformTranslations = translations?.[options.platformPrefix] ?? {};

      const parsedMessages = mergeDeep(defaultTranslations, platformTranslations);

      parsedPlatformMessages[languageKey] = parsedMessages;
    });

    return parsedPlatformMessages;
  };

  return {
    legacy: false,
    locale: options.defaultLanguage,
    messages: parsedPlatformMessages(),
    globalInjection: true,
    missingWarn: true,
  };
};

const setupPlugin = ({
  supportedLanguages,
  defaultLanguage,
  fallbackLanguage,
  platformPrefix,
  warnForMissingTranslations,
}: SetupPluginOptions): I18n<unknown, unknown, unknown> => {
  const options = createOptions({
    supportedLanguages,
    defaultLanguage,
    fallbackLanguage,
    platformPrefix,
    warnForMissingTranslations,
  });

  Translation.props.scope.default = 'global';

  return createI18n(options);
};

const I18NProvider = {
  name: 'I18N Provider',
  setup(): void {
    const configStore = useConfigStore();
    const instance = getCurrentInstance();
    const {config} = configStore;
    const platformPrefix = config.translation_key;
    const supportedLanguages: Language[] = config.supported_languages;
    let navigatorLanguage = navigator?.language ?? 'nl-NL';

    // ONLY use the first two letters of the language.
    // We currently do not support regional languages.
    navigatorLanguage = navigatorLanguage.substring(0, 2);

    const localStorageLanguage = localStorage.getItem('language');

    if (localStorageLanguage) {
      navigatorLanguage = localStorageLanguage;
    }

    const [fallbackLanguage] = supportedLanguages;
    const {warnForMissingTranslations} = config;
    const defaultLanguage = supportedLanguages.includes(navigatorLanguage) ? navigatorLanguage : fallbackLanguage;

    const i18n = setupPlugin({
      supportedLanguages,
      defaultLanguage,
      fallbackLanguage,
      platformPrefix,
      warnForMissingTranslations,
    });

    i18n.install(instance.appContext.app);
  },
};

export {I18NProvider as default, setupPlugin as i18nplugin};
</script>
