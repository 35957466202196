import type {Account} from '@myparcel-frontend/types';
import {defineStore} from 'pinia';

export const useAccountStore = defineStore({
  id: 'account',
  state: () => ({
    accounts: [] as Account[],
  }),
  getters: {
    account(state): Account {
      return state.accounts[0];
    },
  },
  actions: {
    setAccounts(accounts: Account[]) {
      this.accounts = accounts;
    },
  },
});
