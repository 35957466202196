<template>
  <slot />
</template>

<script setup lang="ts">
import type {EventSocketMessage} from '@myparcel-frontend/mypa-api-services/event';
import type {SalesChannel} from '@myparcel-frontend/mypa-api-services/order-etl';
import type {ValueOf} from '@myparcel-frontend/types';
import {
  useEventService,
  EventCaseName,
  salesChannelStatusChangedEvent,
} from '@myparcel-frontend/mypa-api-services/event';
import {NotificationVariant} from '@myparcel-frontend/types';
import {ref, watch} from 'vue';
import {useI18n} from 'vue-i18n';
import {useRoute} from 'vue-router';
import useAuth from '@/composables/useAuth';
import useFeatures from '@/composables/useFeatures';
import useNotifications from '@/composables/useNotifications';
import useSalesChannels from '@/composables/useSalesChannels';
import {SalesChannelsStatus} from '@/types/salesChannels';
import log from '@/utils/logging';

const eventService = useEventService();
const {updateSalesChannelStatus} = useSalesChannels();
const route = useRoute();
const {addNotification} = useNotifications();
const {t} = useI18n();
const {isFeatureActive} = useFeatures();

const socket = ref();

/* c8 ignore start */
const getSocketFromEventService = (token: string) => {
  const client = isFeatureActive('microservices.event')
    ? eventService.setMockingClient()
    : eventService.setClient(token);

  client.setToken(token);
  const connectedSocket = client.connect();

  return connectedSocket;
};

const sendNotifications = (salesChannel: SalesChannel) => {
  let variant: ValueOf<typeof NotificationVariant> = NotificationVariant.Success;
  const body = t(`salesChannels.notifications.salesChannelStatusChanged.${salesChannel.status}`, {
    name: salesChannel.name,
  });

  if (salesChannel.status === SalesChannelsStatus.DelegationRequested) {
    variant = NotificationVariant.Warning;
  }

  if (salesChannel.status === SalesChannelsStatus.DelegationRequired) {
    variant = NotificationVariant.Error;
  }

  addNotification({
    variant,
    body,
  });
};

// TODO: MST-526 - implement the Event API tests.
const handleMessage = (message: EventSocketMessage): void => {
  let updatedSalesChannel: SalesChannel | undefined;

  switch (message.event.case) {
    case EventCaseName.SalesChannelStatusChanged:
      updatedSalesChannel = updateSalesChannelStatus(message.event.value.salesChannelId, message.event.value.status);
      break;
    default:
      break;
  }

  if (!updatedSalesChannel) {
    return;
  }

  sendNotifications(updatedSalesChannel);
};

// TODO: MST-526 - implement the Event API tests.
const initEventSocketListeners = () => {
  if (!socket.value) {
    return;
  }

  socket.value.onopen = () => {
    // Expected console log to validate the websockets state.
    // eslint-disable-next-line no-console
    console.log('WebSockets active');
  };

  socket.value.onmessage = (event: MessageEvent) => {
    // Expected console log to validate the websockets state.
    // eslint-disable-next-line no-console
    console.log('onMessage: ', event.data);
    const data = JSON.parse(event.data);
    handleMessage(data);
  };

  socket.value.onerror = (event: ErrorEvent) => {
    log(event.error);
    socket.value?.close();
  };

  socket.value.onclose = () => {
    socket.value = undefined;
  };
};

const {getToken} = useAuth();
const token = await getToken();

const routesWithEventClient = [
  'orders',
  'orders-overview',
  'orders-import',
  'orders-import-overview',
  'sales-channels',
  'sales-channels-create',
  'sales-channels-edit',
  'sales-channels-edit-settings',
  'sales-channels-edit-status',
];

if (isFeatureActive('microservices.event')) {
  window.salesChannelStatusChanged = (salesChannelId: string, status: ValueOf<typeof SalesChannelsStatus>) => {
    if (!salesChannelId) {
      throw new Error('salesChannelId is required');
    }

    if (!status) {
      throw new Error('status is required');
    }

    salesChannelStatusChangedEvent(salesChannelId, status);
  };
}

const handleSockets = (newRouteName: string) => {
  /* c8 ignore next 3 - TODO: MST-526 - implement the Event API tests. */
  if (socket.value && !routesWithEventClient.includes(newRouteName)) {
    socket.value?.close();
  }

  socket.value = getSocketFromEventService(token);
  initEventSocketListeners();
};

watch(
  () => route.name,
  (newRouteName) => {
    if (!isFeatureActive('microservices.event')) {
      return;
    }

    handleSockets(newRouteName as string);
  },
  {immediate: true},
);
/* c8 ignore stop */
</script>
