<template>
  <div
    class="toast__notification"
    :class="[
      variant,
      size,
      {
        'drop-shadow-md': withShadow,
        '!pb-5': !!timeout,
      },
    ]"
    role="alert">
    <div v-if="timeout">
      <div class="absolute bottom-0 h-1 left-0 toast__timer w-full"></div>
      <div class="absolute bottom-0 h-1 left-0 opacity-20 toast__horizontal-bar w-full"></div>
    </div>
    <div
      v-else
      class="absolute h-full left-0 toast__vertical-bar top-0 w-1" />
    <div class="flex-none icon pr-4">
      <IconBase
        width="16"
        height="16"
        viewbox="0 0 16 16">
        <Info v-if="variant === NotificationVariant.Info" />
        <Error v-if="variant === NotificationVariant.Error" />
        <Error v-if="variant === NotificationVariant.Warning" />
        <CheckRounded v-if="variant === NotificationVariant.Success" />
      </IconBase>
    </div>
    <div class="content flex flex-col grow">
      <div class="empty:hidden font-bold title">
        <slot name="title" />
      </div>
      <div class="body">
        <slot />
      </div>
    </div>
    <button
      v-if="canClose"
      type="button"
      role="button"
      class="button close flex-none pl-4"
      @click="$emit('remove', id)">
      <IconBase
        width="16"
        height="16"
        viewBox="0 0 16 16"
        class="cursor-pointer">
        <Times />
      </IconBase>
    </button>
  </div>
</template>

<script setup lang="ts">
import {IconBase, CheckRounded, Error, Info, Times} from '@myparcel-frontend/ui';
import {NotificationSize, NotificationVariant} from '@/composables/useNotifications';

const props = withDefaults(
  defineProps<{
    id: string;
    variant: NotificationVariant;
    timeout?: number | null;
    size?: NotificationSize;
    canClose?: boolean;
    withShadow?: boolean;
  }>(),
  {
    size: NotificationSize.Full,
    timeout: 6000,
  },
);

const emit = defineEmits(['remove']);

if (props.timeout) {
  setTimeout(() => {
    emit('remove', props.id);
  }, props.timeout);
}

const cssAnimationTime = `${props.timeout}ms`;
</script>

<style lang="scss" scoped>
.toast__notification {
  @apply flex items-center rounded-lg p-4 mb-4 pointer-events-auto border border-gray-400 transform-gpu;
  @apply md:text-base overflow-hidden relative;

  .body :deep(p) {
    @apply mt-2 first:mt-0;
  }

  &.half {
    @apply md:min-w-[400px] md:max-w-3xl md:min-h-[56px];
  }

  .toast__timer {
    @apply origin-left;
    animation: shrink linear v-bind(cssAnimationTime) forwards;
  }

  @keyframes shrink {
    from {
      transform: scale(1, 1);
    }

    to {
      transform: scale(0, 1);
    }
  }

  &.info {
    background-color: #f2fafe;

    .icon {
      color: #009cf3;
    }

    .toast__timer,
    .toast__horizontal-bar,
    .toast__vertical-bar {
      background-color: #009cf3;
    }
  }

  &.success {
    background-color: #f3f8f6;

    .toast__timer,
    .toast__horizontal-bar,
    .toast__vertical-bar {
      background: #0f7859;
    }

    .icon {
      color: #0f7859;
    }
  }

  &.warning {
    background-color: #fffcf4;

    .toast__timer,
    .toast__horizontal-bar,
    .toast__vertical-bar {
      background: #f5b800;
    }

    .icon {
      color: #f5b800;
    }
  }

  &.alert {
    background-color: #fcf5f6;

    .toast__timer,
    .toast__horizontal-bar,
    .toast__vertical-bar {
      background: #cb444a;
    }

    .icon {
      color: #cb444a;
    }
  }
}
</style>
