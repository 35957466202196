import type {BundlesProductId, BundlesTierName} from './bundles.types';
import type {MyAnalyticsProductId, MyAnalyticsTierName} from './myAnalytics.types';
import type {MyContractsProductId, MyContractsTierName} from './myContracts.types';
import type {MyOrdersProductId, MyOrdersTierName} from './myOrders.types';
import type {MaxShippingRulesByType} from '@/providers/acl.types';
import type {Price} from '@/types/price';
import type {Shop} from '@/types/shop';
import type {Account} from '@myparcel-frontend/types';

export enum TierRange {
  Zero = 0,
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
}

export enum SubscriptionStatus {
  Pending = 'pending',
  StartingSoon = 'starting_soon',
  TrialActive = 'trial_active',
  TrialEnded = 'trial_ended',
  Ended = 'ended',
  EndingSoon = 'ending_soon',
  Active = 'active',
}

export type Subscription = {
  account_id: Account['id'];
  billing_period_end: string;
  billing_shop_id: Shop['id'];
  end: string | null;
  id: number;
  product_id: SubscriptionProductIds;
  start: string;
  status: SubscriptionStatus;
  tier: TierRange;
  trial_end: string | null;
  type: SubscriptionType;
};

export type SubscriptionProductIds =
  | typeof MyContractsProductId
  | typeof MyAnalyticsProductId
  | typeof MyOrdersProductId
  | typeof BundlesProductId;

export type SubscriptionTierName =
  | typeof MyContractsTierName
  | typeof MyAnalyticsTierName
  | typeof MyOrdersTierName
  | typeof BundlesTierName;

export enum SubscriptionType {
  MyContracts = 'my_contracts',
  MyAnalytics = 'my_analytics',
  MyOrders = 'my_orders',
  Bundles = 'bundle',
}

export interface SubscriptionConfiguration {
  [name: string]: unknown;
  dashboard_slugs?: string[];
  free_shipments_per_month?: number;
  max_shipping_rules_by_type?: MaxShippingRulesByType;
  shipment_discount_price?: {
    amount: number;
    currency: string;
  };
  shipment_discount_product?: number;
  shipment_fee_price?: {
    amount: number;
    currency: string;
  };
  shipment_fee_product?: number;
}

export interface SubscriptionCapability {
  configuration: SubscriptionConfiguration;
  features: string[];
  price: Price;
  tier: TierRange;
  product_id: SubscriptionProductIds;
  product_name: string;
  subscription_type_name: string;
  tier_name: SubscriptionTierName;
  type: SubscriptionType;
}

export interface TierSubscriptionCapability extends SubscriptionCapability {
  status: {
    isActive: boolean;
    isHighestCapabilityWithinBundle: boolean;
    isBundle: boolean;
    isDisabled: boolean;
    isEligibleForTrial: boolean;
    isEndingSoon: boolean;
    isStartingSoon: boolean;
  };
  subscription?: Subscription;
}

export type TierAndSubscription = {
  active: {
    bundle?: Subscription;
    subscription?: Subscription;
    highestSubscription?: Subscription;
    highestTier?: SubscriptionCapability;
  };
  capabilities: SubscriptionCapability[];
  tiersWithSubscription: TierSubscriptionCapability[];
  subscriptions: Subscription[];
};

export * from './myAnalytics.types';

export * from './myContracts.types';

export * from './myOrders.types';

export * from './bundles.types';
