<template>
  <template v-if="configKey">
    <slot />
  </template>
  <template v-else>
    <ConfigWarning :host-name="hostName" />
  </template>
</template>

<script setup lang="ts">
import type {ConfigFile, ConfigInterface} from './platformConfig.types';
import ConfigWarning from './components/ConfigWarning.vue';
import {ConfigKey, Hosts} from './platformConfig.types';
import _config from '@/assets/config.json';
import {useConfigStore} from '@/store/config';
import env from '@/utils/env';
import {getHostName, hostNameMatcher} from '@/utils/host';

/**
 * Returns the platform config.
 *
 * @returns The config for the current page.
 */
const fetchConfig = (): Promise<ConfigFile> => {
  // TODO: Get this file from a remote server
  const config: ConfigFile = _config as ConfigFile;
  return Promise.resolve(config);
};

/**
 * Returns the platform config mapping.
 *
 * @returns The config mapping for all platforms.
 */
const fetchMapping = (): Promise<Record<string, ConfigKey>> => {
  return Promise.resolve({
    [Hosts.MYPARCEL_NL]: ConfigKey.MYPARCEL_NL,
    [Hosts.MYPARCEL_BE]: ConfigKey.MYPARCEL_BE,
    [Hosts.LOCALHOST]: ConfigKey.MYPARCEL_NL,
  });
};

const configStore = useConfigStore();
const hostMapping = await fetchMapping();
const config = await fetchConfig();
const hostName = getHostName();
let configKey: ConfigKey | null;

try {
  configKey = hostNameMatcher(hostMapping, hostName);
} catch (error: unknown) {
  configKey = null;
}

if (configKey) {
  const defaultConfig: Record<string, unknown> = config.default;
  const platformConfig: Record<string, unknown> = config[configKey];
  const environmentConfig: Record<string, unknown> = {
    assetsUrl: env.assetsUrls[configKey] || env.assetsUrls.default,
  };

  const combinedConfig: ConfigInterface = {...defaultConfig, ...platformConfig, ...environmentConfig};

  configStore.setConfig(combinedConfig);
}
</script>
