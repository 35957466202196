<!-- eslint-disable vue/no-bare-strings-in-template -->
<template>
  <!-- Auth0 Callback landing page -->
  <div
    v-if="warning"
    class="m-16">
    <h1 class="text-4xl">Authentication Error</h1>
    <p class="my-4">Auth0 has returned an error.</p>
    <p class="my-4">Warning:</p>
    <pre>{{ warning.message }}</pre>
    <p class="my-4">
      <Button
        variant="primary"
        @click="logout">
        Log out
      </Button>
    </p>
  </div>
</template>

<script setup lang="ts">
import {ref} from 'vue';
import Button from '@/components/ui/Button';
import useAuth from '@/composables/useAuth';
import env from '@/utils/env';

const {setAuth, logout} = useAuth();

const warning = ref();
const impersonatedUserId = window.localStorage.getItem('auth0_impersonated_account_id');
const scope = impersonatedUserId ? 'profile email impersonation' : env.auth0Scope;
const auth0 = await setAuth(scope);

try {
  await auth0.handleRedirectCallback();
  const redirectTo = window.localStorage.getItem('redirect_to');

  if (redirectTo) {
    window.localStorage.removeItem('redirect_to');
    window.location.replace(redirectTo);
  } else {
    window.location.replace('/');
  }
} catch (error) {
  if (error.message === 'impersonation_required_for_admins') {
    // do NOT show any error message, as the user will be redirected to auth0:
    warning.value = undefined;
    const authorizeUrl = await auth0.buildAuthorizeUrl({
      scope: env.auth0Scope,
      redirect_uri: window.location.origin,
    });
    await logout(`${authorizeUrl}&error=${error.message}`);
  } else {
    warning.value = error;
  }
}
</script>
